import { DialogClose } from "@radix-ui/react-dialog";
import { CellContext } from "@tanstack/react-table";
import dayjs from "dayjs";

import { TablerEditIcon } from "@/components/icons/tabler-edit";
import { StatusBadge } from "@/components/shared/table/badges/status-badge";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TimePicker } from "@/components/ui/time-picker";
import { BackofficeDialog } from "@/features/backoffice/components/backoffice-dialog";
import { cn } from "@/lib/utils";

import { BackOfficeOrderColumn } from "../constants/table-column";
import { useEditDialog } from "../hooks/use-edit-dialog";

export function EditDialog({
  row,
}: Readonly<CellContext<BackOfficeOrderColumn, unknown>>) {
  const {
    isDisabled,
    methods,
    handleSubmit,
    onSubmit,
    selectedStatus,
    onChange,
    onClick,
    dialogOpen,
    setDialogOpen,
    isPending,
  } = useEditDialog({ row });

  return (
    <BackofficeDialog
      title="Edit Pemesanan"
      button={
        <StatusBadge
          status="EDIT"
          onClick={(e) => {
            if (isDisabled) e.stopPropagation();

            onClick({
              ...row.original,
              departTime: row.original.departTime,
              arriveTime: row.original.arriveTime,
            });
          }}
          icon={TablerEditIcon}
          className={cn(isDisabled && "opacity-50 cursor-not-allowed")}
        />
      }
      triggerClassName="w-full"
      open={dialogOpen}
      onOpenChange={setDialogOpen}
    >
      <div className="space-y-4">
        <Form {...methods}>
          <form
            className="flex flex-col gap-4 max-h-[50vh]"
            onSubmit={handleSubmit((data) =>
              onSubmit({
                ...data,
                departTime: data.departTime,
                arriveTime: data.arriveTime,
              }),
            )}
          >
            <FormField
              control={methods.control}
              name="status"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-bold text-md">Status</FormLabel>
                  <FormControl>
                    <Select
                      {...field}
                      value={selectedStatus === "DEFAULT" ? "" : selectedStatus}
                      onValueChange={(value) => {
                        onChange(value);
                      }}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Pilih Status" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="RESCHEDULED">RESCHEDULED</SelectItem>
                        <SelectItem value="REFUNDED">REFUNDED</SelectItem>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {selectedStatus !== "DEFAULT" && (
              <div className="scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-thumb-slate-500/40 scrollbar-track-slate-200 overflow-auto">
                <div className="flex items-start space-x-2">
                  <FormField
                    control={methods.control}
                    name="departTime"
                    render={({ field }) => {
                      const { watch } = methods;
                      const date = new Date(watch("departTime"));

                      return (
                        <FormItem className="mb-4 flex-1">
                          <FormLabel className="font-bold text-md">
                            Tanggal Berangkat
                          </FormLabel>
                          <FormControl>
                            <Popover>
                              <PopoverTrigger asChild>
                                <Button
                                  variant={"outline"}
                                  className={cn(
                                    "w-full justify-start text-left font-normal",
                                    !date && "text-muted-foreground",
                                  )}
                                >
                                  {date ? (
                                    dayjs(date).format("DD-MM-YYYY")
                                  ) : (
                                    <span>Pilih tanggal</span>
                                  )}
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent
                                className="w-auto p-0"
                                align="start"
                              >
                                <Calendar
                                  {...field}
                                  mode="single"
                                  selected={date}
                                  onSelect={(date?: Date) =>
                                    field.onChange(date)
                                  }
                                />
                              </PopoverContent>
                            </Popover>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={methods.control}
                    name="arriveTime"
                    render={({ field }) => {
                      const { watch } = methods;
                      const date = new Date(watch("arriveTime"));

                      return (
                        <FormItem className="mb-4 flex-1">
                          <FormLabel className="font-bold text-md">
                            Tanggal Sampai
                          </FormLabel>
                          <FormControl>
                            <Popover>
                              <PopoverTrigger asChild>
                                <Button
                                  variant={"outline"}
                                  className={cn(
                                    "w-full justify-start text-left font-normal",
                                    !date && "text-muted-foreground",
                                  )}
                                >
                                  {date ? (
                                    dayjs(date).format("DD-MM-YYYY")
                                  ) : (
                                    <span>Pilih tanggal</span>
                                  )}
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent
                                className="w-auto p-0"
                                align="start"
                              >
                                <Calendar
                                  {...field}
                                  mode="single"
                                  selected={date}
                                  onSelect={(date?: Date) =>
                                    field.onChange(date)
                                  }
                                />
                              </PopoverContent>
                            </Popover>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                </div>

                <FormField
                  control={methods.control}
                  name="code"
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel className="font-bold text-md">
                        Kode Booking
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="Masukkan kode booking..."
                          disabled={true}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={methods.control}
                  name="airline"
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel className="font-bold text-md">
                        Maskapai
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="Masukkan maskapai..."
                          disabled={selectedStatus === "REFUNDED"}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="flex items-start space-x-2">
                  <FormField
                    control={methods.control}
                    name="departTime"
                    render={({ field }) => {
                      const { watch } = methods;
                      const date = watch("departTime");

                      return (
                        <FormItem className="mb-4 flex-1">
                          <FormLabel className="font-bold text-md">
                            Berangkat
                          </FormLabel>
                          <FormControl>
                            <Popover>
                              <PopoverTrigger asChild>
                                <Button
                                  variant={"outline"}
                                  className={cn(
                                    "w-full justify-start text-left font-normal",
                                    !date && "text-muted-foreground",
                                  )}
                                >
                                  {date ? (
                                    dayjs(date).format("HH:mm")
                                  ) : (
                                    <span>Pilih waktu</span>
                                  )}
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent
                                className="w-auto p-0"
                                align="start"
                              >
                                <TimePicker
                                  {...field}
                                  setDate={field.onChange}
                                  date={date}
                                />
                              </PopoverContent>
                            </Popover>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />

                  <FormField
                    control={methods.control}
                    name="arriveTime"
                    render={({ field }) => {
                      const { watch } = methods;
                      const date = watch("arriveTime");

                      return (
                        <FormItem className="mb-4 flex-1">
                          <FormLabel className="font-bold text-md">
                            Sampai
                          </FormLabel>
                          <FormControl>
                            <Popover>
                              <PopoverTrigger asChild>
                                <Button
                                  variant={"outline"}
                                  className={cn(
                                    "w-full justify-start text-left font-normal",
                                    !date && "text-muted-foreground",
                                  )}
                                >
                                  {date ? (
                                    dayjs(date).format("HH:mm")
                                  ) : (
                                    <span>Pilih waktu</span>
                                  )}
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent
                                className="w-auto p-0"
                                align="start"
                              >
                                <TimePicker
                                  {...field}
                                  setDate={field.onChange}
                                  date={date}
                                />
                              </PopoverContent>
                            </Popover>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                </div>

                <div className="flex items-start space-x-2">
                  <FormField
                    control={methods.control}
                    name="origin"
                    render={({ field }) => (
                      <FormItem className="mb-4 flex-1">
                        <FormLabel className="font-bold text-md">
                          Asal
                        </FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder="Masukkan asal..."
                            disabled={selectedStatus === "REFUNDED"}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={methods.control}
                    name="destination"
                    render={({ field }) => (
                      <FormItem className="mb-4 flex-1">
                        <FormLabel className="font-bold text-md">
                          Tujuan
                        </FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder="Masukkan tujuan..."
                            disabled={selectedStatus === "REFUNDED"}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <FormField
                  control={methods.control}
                  name="price"
                  render={({ field: { onChange, ...field } }) => (
                    <FormItem className="mb-4">
                      <FormLabel className="font-bold text-md">
                        Harga Tiket
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          onChange={(e) => onChange(Number(e.target.value))}
                          type="number"
                          min={0}
                          placeholder="Masukkan harga tiket..."
                          disabled={true}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {selectedStatus === "REFUNDED" ? (
                  <FormField
                    control={methods.control}
                    name="refundAmount"
                    render={({ field: { onChange, ...field } }) => (
                      <FormItem className="mb-4">
                        <FormLabel className="font-bold text-md">
                          Jumlah Refund
                        </FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            onChange={(e) => onChange(Number(e.target.value))}
                            type="number"
                            min={0}
                            placeholder="Masukkan jumlah refund..."
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : (
                  <FormField
                    control={methods.control}
                    name="rescheduleCharge"
                    render={({ field: { onChange, ...field } }) => (
                      <FormItem className="mb-4">
                        <FormLabel className="font-bold text-md">
                          Biaya Reschedule
                        </FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            onChange={(e) => onChange(Number(e.target.value))}
                            type="number"
                            min={0}
                            placeholder="Masukkan biaya reschedule..."
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
              </div>
            )}

            <div className="flex justify-between items-center space-x-2 mt-2">
              <DialogClose asChild>
                <Button
                  type="button"
                  variant="destructive"
                  className="w-full rounded-2xl text-white"
                >
                  Kembali
                </Button>
              </DialogClose>
              <Button
                type="submit"
                isLoading={isPending}
                disabled={!methods.formState.isValid || isPending}
                variant="default"
                className="w-full rounded-2xl"
              >
                Ubah
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </BackofficeDialog>
  );
}
