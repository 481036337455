import { RoutesById, useNavigate, useSearch } from "@tanstack/react-router";
import { useEffect, useState } from "react";

import { routeTree } from "@/routeTree.gen";

interface UseSearchQueryProps {
  from: keyof RoutesById<typeof routeTree>;
}

export const useSearchQuery = <T extends Record<string, unknown>>({
  from,
}: UseSearchQueryProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const search = useSearch({ from }) as T;
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState<T | null>(null);

  useEffect(() => {
    setIsLoading(true);
    if (search) {
      setSearchData((prevSearchData) => ({
        ...prevSearchData,
        ...search,
      }));
    }
    setIsLoading(false);
  }, [search]);

  useEffect(() => {
    if (searchData) {
      navigate({
        search: () => {
          const filteredSearchData: Record<string, unknown> = {};
          Object.entries(searchData).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value).forEach(([innerKey, innerValue]) => {
                if (innerValue !== "") {
                  filteredSearchData[innerKey] = innerValue;
                }
              });
            } else {
              if (value !== "") {
                filteredSearchData[key] = value;
              }
            }
          });
          return filteredSearchData;
        },
        params: true,
      });
    }
  }, [from, navigate, searchData]);

  function setSearch(update: Partial<T>) {
    setSearchData((prevSearchData) => ({
      ...(prevSearchData || ({} as T)),
      ...update,
    }));
  }

  return {
    searchData: searchData || ({} as T),
    setSearch,
    isLoading,
    search,
  };
};
