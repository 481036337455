import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect, useMemo, useState } from "react";

import { BaseFooter } from "@/components/layout/footer/base-footer";
import { BaseNavHeader } from "@/components/layout/header/base-nav";
import { Button } from "@/components/ui/button";
import { TicketDetail } from "@/features/flight-booking-ticket/components/ticket-detail";
import { flightSearchSchema } from "@/features/flight-booking-ticket/validators";
import { AddPassengerDialog } from "@/features/flight-trip-detail/components/add-passenger-dialog";
import { EditPassengerDialog } from "@/features/flight-trip-detail/components/edit-passenger-dialog";
import { KnownPassengerCard } from "@/features/flight-trip-detail/components/passengers/known-passenger-card";
import { UnknownPassengerCard } from "@/features/flight-trip-detail/components/passengers/unknown-passenger-card";
import { SelectPaymentMethodDialog } from "@/features/flight-trip-detail/components/select-payment-method-dialog";
import { useDisclose } from "@/hooks/use-disclose";
import { useToast } from "@/hooks/use-toast";
import { FormSchema } from "@/lezzform/_generated/add-passenger";
import { Table as DetailTicket } from "@/lezztable/_generated/detail-ticket";
import { BookingFlightSchema } from "@/services/booking/dto";
import { useBookFlight } from "@/services/booking/hooks/use-book-flight";
import { FlightPassengerEntity } from "@/services/flight-passenger/types";
import { useFlightStore } from "@/stores/use-flight-store";

export const Route = createFileRoute("/book/plane/list/$listId/")({
  component: ListDetail,
  validateSearch: (search) => flightSearchSchema.parse(search),
  parseParams: (params) => {
    return { ...params, listId: decodeURIComponent(params.listId) };
  },
});

function ListDetail() {
  const params = Route.useSearch();
  const navigate = useNavigate();
  const { toast } = useToast();

  const { isOpen, setIsOpen } = useDisclose();
  const { isOpen: isEditOpen, setIsOpen: setIsEditOpen } = useDisclose();
  const { isOpen: isTicketOpen, setIsOpen: setIsTicketOpen } = useDisclose();

  const { mutateAsync } = useBookFlight();
  const { setPassengers, setPaymentType, ticket } = useFlightStore();

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [selectedPassenger, setSelectedPassenger] =
    useState<FlightPassengerEntity | null>(null);

  const [passengerList, setPassengerList] = useState<
    (FlightPassengerEntity | null)[]
  >([]);

  const unknownPassengers = useMemo(
    () => passengerList.filter((item) => !item?.Name),
    [passengerList],
  );

  const handleClosePassengerDialog = () => {
    setSelectedIndex(null);
    setIsOpen(false);
  };

  const handleSelectUnknownPassenger = (index: number) => () => {
    setSelectedIndex(index);
    setIsOpen(true);
  };

  const handleSelectKnownPassenger =
    (index: number, passenger: FlightPassengerEntity) => () => {
      setSelectedIndex(index);
      setSelectedPassenger(passenger);
      setIsEditOpen(true);
    };

  const handleSubmitPassenger = (values: FormSchema | null) => {
    setPassengerList(
      (prev) =>
        prev.map((item, index) => {
          if (index !== selectedIndex) return item;

          return { ...values, Type: item?.Type };
        }) as FlightPassengerEntity[],
    );
  };

  const handleRemovePassenger = (index: number) => () => {
    setPassengerList((prev) =>
      prev.map((item, itemIndex) => {
        if (itemIndex !== index) return item;

        return null;
      }),
    );
  };

  const handleEditPassenger = (values: FormSchema) => {
    setPassengerList(
      (prev) =>
        prev.map((item, index) => {
          if (index !== selectedIndex) return item;

          return { ...values, Type: item?.Type };
        }) as FlightPassengerEntity[],
    );
  };

  const handleCloseEditPassenger = () => {
    setSelectedIndex(null);
    setSelectedPassenger(null);
    setIsEditOpen(false);
  };

  useEffect(() => {
    const adultPassengers = Array.from({ length: params.Adult ?? 0 }).map(
      () => ({ Type: 1 }),
    );

    const childPassengers = Array.from({ length: params.Child ?? 0 }).map(
      () => ({ Type: 2 }),
    );

    const infantPassengers = Array.from({ length: params.Infant ?? 0 }).map(
      () => ({ Type: 3 }),
    );

    const allPassengers = adultPassengers
      .concat(childPassengers)
      .concat(infantPassengers);

    setPassengerList(allPassengers as unknown as FlightPassengerEntity[]);
  }, [params.Adult, params.Child, params.Infant]);

  return (
    <div className="flex flex-col h-screen bg-white">
      <BaseNavHeader title="Isi Detail Perjalanan" />

      <TicketDetail open={isTicketOpen} onOpenChange={setIsTicketOpen} />

      <div className="flex-grow overflow-auto flex flex-col items-center">
        <div className="h-[200px] w-full px-4 py-5">
          <DetailTicket
            data={[ticket as object]}
            onClick={() => setIsTicketOpen(true)}
          />
        </div>

        <AddPassengerDialog
          open={isOpen}
          onOpenChange={(open) => {
            if (open) setIsOpen(open);

            return handleClosePassengerDialog();
          }}
          onSubmit={handleSubmitPassenger}
        />

        <EditPassengerDialog
          open={isEditOpen}
          onOpenChange={(open) => {
            if (open) return setIsOpen(open);

            return handleCloseEditPassenger();
          }}
          onSubmit={handleEditPassenger}
          defaultValues={selectedPassenger as FormSchema}
        />

        <div className="flex-grow w-full pb-4 px-4 flex flex-col gap-4 overflow-auto">
          {Boolean(unknownPassengers.length) && <p>Detail Penumpang</p>}
          {Boolean(passengerList.length) && (
            <>
              {passengerList.map((item, index) => {
                if (!item?.Name) {
                  return (
                    <UnknownPassengerCard
                      key={index}
                      title={`Penumpang ${index + 1}`}
                      onClick={handleSelectUnknownPassenger(index)}
                    />
                  );
                }

                return (
                  <div key={item.IdNumber} className="flex flex-col gap-1">
                    {Boolean(!unknownPassengers.length) && (
                      <p>Detail Penumpang {index + 1}</p>
                    )}
                    <KnownPassengerCard
                      title={item.Name!}
                      nik={item.IdNumber!}
                      onDelete={handleRemovePassenger(index)}
                      onClick={handleSelectKnownPassenger(index, item)}
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
      <BaseFooter className="static h-[8.25rem]">
        <SelectPaymentMethodDialog
          onSubmit={async (values) => {
            setPaymentType(values.paymentMethod as "CORPORATE" | "USER");
            setPassengers(
              passengerList.filter((passenger) =>
                Boolean(passenger),
              ) as FlightPassengerEntity[],
            );

            if (values.paymentMethod === "CORPORATE") {
              navigate({
                params: { listId: encodeURIComponent(ticket?.Id as string) },
                search: true,
                to: "/book/plane/list/$listId/submit",
              });
            } else if (values.paymentMethod === "USER") {
              const cabinClass = ticket?.ClassObjects[0];

              try {
                const { data } = await mutateAsync({
                  Passengers:
                    passengerList as unknown as BookingFlightSchema["Passengers"],
                  flight: ticket as unknown as BookingFlightSchema["flight"],
                  classId: cabinClass?.Id as string,
                  payer: "USER",
                });

                navigate({
                  params: true,
                  search: { bookingId: data.booking.id },
                  to: "/checkout",
                });
              } catch (error) {
                console.log("select payment err", error);
                toast({
                  title: "Penumpang sama!",
                  variant: "destructive",
                });
              }
            }
          }}
        >
          {({ onOpen }) => (
            <Button
              className="w-full text-lg h-12 text-white font-bold"
              disabled={Boolean(unknownPassengers.length)}
              onClick={onOpen}
            >
              Pilih Pembayaran
            </Button>
          )}
        </SelectPaymentMethodDialog>
        <Button
          className="w-full text-lg h-12 text-white font-bold"
          variant="secondary"
          onClick={() => navigate({ to: "/book/plane/list", search: true })}
        >
          Kembali
        </Button>
      </BaseFooter>
    </div>
  );
}
