import { useMutation } from "@lezzcode/query";

import { BaseApiResponse } from "@/services/shared/types";

import { DigiServiceApi } from "../api";
import { VerifySpdSchema } from "../types/dto";

export const useVerifySpd = () => {
  const api = new DigiServiceApi();

  return useMutation<BaseApiResponse<boolean>, Error, VerifySpdSchema>({
    mutationFn: api.verifySpd,
  });
};
