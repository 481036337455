import { CopyIcon } from "lucide-react";
import { HTMLAttributes } from "react";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { useCopy } from "@/hooks/use-copy";
import { cn } from "@/lib/utils";

interface BookingCodeDetailProps extends HTMLAttributes<HTMLDivElement> {
  value: string;
}

export function BookingCodeDetail({
  value,
  className,
  ...rest
}: BookingCodeDetailProps): React.JSX.Element {
  const { onCopy } = useCopy();

  return (
    <div
      className={cn(
        "flex flex-row justify-between items-center px-2.5 py-3.5 border-b",
        className,
      )}
      {...rest}
    >
      <p className="text-xl font-semibold">Kode Booking</p>
      <div className="flex flex-row items-center gap-2">
        <Button
          variant="ghost"
          className="p-1"
          onClick={() => onCopy(value, "Kode Booking tersalin!")}
        >
          <CopyIcon size={24} />
        </Button>
        <Badge
          variant="outline"
          className="text-xl font-bold text-primary px-[10px] py-[5px] border-primary rounded-md"
        >
          {value}
        </Badge>
      </div>
    </div>
  );
}
