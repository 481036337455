import { cn } from "@/lib/utils";

import { Container } from "./container";
import { List } from "./list";

export interface StatusWrapperProps
  extends React.HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean;
  renderEmpty?: React.JSX.Element | string;
  classNames?: {
    renderLoading?: string;
  };
}

export function StatusWrapper({
  className,
  children,
  ...rest
}: StatusWrapperProps) {
  return (
    <div className={cn("flex flex-col", className)} {...rest}>
      {children}
    </div>
  );
}

StatusWrapper.Container = Container;
StatusWrapper.List = List;
