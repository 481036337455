import { api } from "@/lib/api";

import { BaseApiResponse } from "../shared/types";
import { ApprovalEntity } from "./types";

export class ApprovalServiceApi {
  private baseUrl: string = "/approval";

  findApproval = async () => {
    const { data } = await api.get<BaseApiResponse<ApprovalEntity[]>>(
      this.baseUrl,
    );

    return data.data;
  };
}
