import React from "react";

import { PlaneIcon } from "@/components/icons/plane";
import { cn } from "@/lib/utils";

interface SharedNotificationItemProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  icon?: JSX.Element;
  title: JSX.Element | string;
  descriptions?: string[];
}

export function SharedNotificationItem({
  icon = <PlaneIcon size={32} className="fill-primary" />,
  title,
  descriptions,
  className,
  ...rest
}: SharedNotificationItemProps): React.JSX.Element {
  return (
    <div
      className={cn(
        "flex flex-row gap-[15px] items-center px-[30px] py-[25px] border-b cursor-pointer",
        className,
      )}
      {...rest}
    >
      <div className="flex justify-center items-center p-[9px] rounded-lg border">
        {icon}
      </div>
      <div className="flex flex-col gap-[5px]">
        {typeof title === "object" && title}

        {typeof title === "string" && (
          <p className="text-xs text-primary font-semibold">{title}</p>
        )}

        {descriptions?.map((item, index) => (
          <p
            key={index}
            className="text-[10px] text-muted-foreground font-medium"
          >
            {item}
          </p>
        ))}
      </div>
    </div>
  );
}
