import { HTMLAttributes } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { cn } from "@/lib/utils";
import { PassengerEntity } from "@/services/flight-passenger/types";

interface PassengerDetailProps extends HTMLAttributes<HTMLDivElement> {
  passengers?: PassengerEntity[];
}

export function PassengerDetails({
  passengers,
  className,
  ...rest
}: PassengerDetailProps): React.JSX.Element {
  return (
    <div className={cn("py-2.5 pb-0", className)} {...rest}>
      <p className="text-xl font-semibold px-2.5 mb-2.5">Detail Penumpang</p>

      <Table className="border-b">
        <TableHeader>
          <TableRow>
            <TableHead className="h-6 px-2.5 py-2">Nama</TableHead>
            <TableHead className="text-end h-6 px-2.5 py-2">Bagasi</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {passengers?.map((item) => (
            <TableRow key={item.IdNumber}>
              <TableCell className="h-6 px-2.5 py-2">
                {item.Title}. {item.FirstName} {item.LastName}
              </TableCell>
              <TableCell className="text-end h-6 px-2.5 py-2">
                {item.Ssrs[0]?.SsrName}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
