import { useMemo } from "react";

import {
  TimelineItemProps,
  VerticalTimeline,
} from "@/components/ui/vertical-timeline";
import { cn } from "@/lib/utils";
import { FlightTicketEntity } from "@/services/flight-ticket/types";

interface FlightDetailProps extends React.HTMLAttributes<HTMLDivElement> {
  ticket: FlightTicketEntity;
}

export function FlightDetail({
  ticket,
  className,
  ...rest
}: FlightDetailProps): React.JSX.Element {
  const originAirport = useMemo(() => {
    return ticket.AirportV2Details.find(
      (airport) => airport.Iata === ticket.Origin,
    );
  }, [ticket.AirportV2Details, ticket.Origin]);

  const destinationAirport = useMemo(() => {
    return ticket.AirportV2Details.find(
      (airport) => airport.Iata === ticket.Destination,
    );
  }, [ticket.AirportV2Details, ticket.Destination]);

  const timeline = useMemo<TimelineItemProps[]>(() => {
    return [
      {
        date: (
          <div className="flex flex-col items-start">
            <h1 className="text-2xl">{ticket.DepartTime}</h1>
            <p className="text-muted-foreground-secondary text-sm">
              {ticket.DepartDate}
            </p>
          </div>
        ),
        detail: (
          <div className="flex flex-col">
            <div className="flex flex-col items-start">
              <h1 className="text-foreground text-2xl">
                {originAirport?.City} ({originAirport?.Iata})
              </h1>
              <p className="text-muted-foreground-secondary text-sm">
                {originAirport?.Name}
              </p>
              {ticket?.OriginTerminal && (
                <p className="text-muted-foreground-secondary text-sm">
                  {ticket?.OriginTerminal}
                </p>
              )}
            </div>

            {ticket?.ClassObjects ? (
              <div className="rounded-lg p-4 bg-[#EAF5FE] mt-4">
                <div className="flex flex-col">
                  <div className="flex items-center gap-2 mb-1">
                    <div className="py-1 px-2 bg-white rounded">
                      <img
                        className="w-[40px] h-[32px] object-fill"
                        src={ticket?.AirlineImageUrl}
                        alt={ticket?.AirlineName}
                      />
                    </div>
                    <p className="text-xl text-foreground">
                      {ticket?.AirlineName}
                    </p>
                  </div>
                  <p className="text-xs text-muted-foreground-secondary">
                    {ticket?.Number} - {ticket?.ClassObjects?.[0]?.Category}
                  </p>
                </div>
              </div>
            ) : (
              <div className="h-40"></div>
            )}
          </div>
        ),
      },
      {
        date: (
          <div className="flex flex-col items-start">
            <h1 className="text-foreground text-2xl">{ticket?.ArriveTime}</h1>
            <p className="text-muted-foreground-secondary text-sm">
              {ticket?.ArriveDate}
            </p>
          </div>
        ),
        detail: (
          <div className="flex flex-col">
            <div className="flex flex-col items-start">
              <h1 className="text-foreground text-2xl">
                {destinationAirport?.City} ({destinationAirport?.Iata})
              </h1>
              <p className="text-muted-foreground-secondary text-sm">
                {destinationAirport?.Name}
              </p>
              {ticket?.DestinationTerminal && (
                <p className="text-muted-foreground-secondary text-sm">
                  {ticket?.DestinationTerminal}
                </p>
              )}
            </div>
          </div>
        ),
      },
    ];
  }, [
    destinationAirport?.City,
    destinationAirport?.Iata,
    destinationAirport?.Name,
    originAirport?.City,
    originAirport?.Iata,
    originAirport?.Name,
    ticket?.AirlineImageUrl,
    ticket?.AirlineName,
    ticket?.ArriveDate,
    ticket?.ArriveTime,
    ticket?.ClassObjects,
    ticket.DepartDate,
    ticket.DepartTime,
    ticket?.DestinationTerminal,
    ticket?.Number,
    ticket?.OriginTerminal,
  ]);

  return (
    <div className={cn("w-full p-8 h-min overflow-auto", className)} {...rest}>
      <VerticalTimeline items={timeline} />
    </div>
  );
}
