import { z as u } from "zod";
import {
  Lezzform as c,
  FormField as h,
  FormItem as S,
  FormLabel as F,
  FormControl as b,
  FormMessage as y,
  Dropdown as C,
  CustomLayout as R,
  Button as l,
} from "@lezzform/react";
import { ChevronDownCircle as g } from "lucide-react";
import * as r from "react";
import { jsx as o, jsxs as a } from "react/jsx-runtime";
var L = u.object({ paymentMethod: u.string() }),
  s = "upVk7JJqDHk8ezsbPToM",
  v = ({
    onSubmit: n,
    onError: p,
    onSuccess: f,
    defaultValues: z,
    onAction: i,
    formProps: P,
  }) => {
    let m = r.useRef();
    r.useEffect(() => {
      n && (m.current = n);
    }, [n]);
    let d = r.useCallback(async (e, t) => {
      if (m.current) return m.current(e, t);
    }, []);
    return o(
      c,
      {
        id: s,
        defaultValues: z,
        onSubmit: d,
        onError: p,
        onSuccess: f,
        zodSchema: L,
        mode: "onSubmit",
        children: (e) =>
          a(c.Container, {
            children: [
              o(h, {
                control: e.control,
                name: "paymentMethod",
                render: ({ field: t }) =>
                  a(S, {
                    children: [
                      o(F, { isRequired: !0 }),
                      o(b, {
                        children: o(C, {
                          label: "",
                          name: t.name,
                          value: t.value,
                          onChange: t.onChange,
                          placeholder: "Pilih Pembayaran",
                          items: [
                            { label: "Individual", value: "USER" },
                            { label: "Corporate", value: "CORPORATE" },
                          ],
                          isRequired: !0,
                          styles: {
                            root: { height: "62px", fontSize: "20px" },
                          },
                          suffixAdornment: {
                            icon: o(g, { size: 18, color: "#cacaca" }),
                          },
                        }),
                      }),
                      o(y, {}),
                    ],
                  }),
              }),
              a(R, {
                style: {
                  gap: "8px",
                  width: "100%",
                  display: "grid",
                  marginTop: "30px",
                  gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                },
                children: [
                  o(l, {
                    type: "button",
                    className: "w-full",
                    onClick: () => {
                      i?.cancelButton && i?.cancelButton(e);
                    },
                    styles: {
                      root: {
                        height: "47px",
                        fontSize: "18px",
                        borderRadius: "12px",
                        backgroundColor: "#D34C4C",
                      },
                    },
                    children: "Kembali",
                  }),
                  o(l, {
                    type: "submit",
                    className: "w-full",
                    disabled: e.formState.isSubmitting,
                    isLoading: e.formState.isSubmitting,
                    styles: {
                      root: {
                        height: "47px",
                        fontSize: "18px",
                        borderRadius: "12px",
                        backgroundColor: "#0065B3",
                      },
                    },
                    children: "Pilih",
                  }),
                ],
              }),
            ],
          }),
      },
      s,
    );
  };
export { v as Form };
