import axios from "axios";

import { UserAuthServiceApi } from "@/services/user-auth/api";

export const api = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL,
});

export const setToken = (token: string) => {
  if (!token) return delete api.defaults.headers.common.Authorization;
  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

// api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const userAuthService = new UserAuthServiceApi();
//     const originalRequest = error.config;
//     const { refresh_token } = userAuthService.getToken();
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       // eslint-disable-next-line no-useless-catch
//       try {
//         const refreshedTokenResponse = await userAuthService.refreshToken({
//           refreshToken: refresh_token,
//         });
//         const accessToken = refreshedTokenResponse.data.access_token;
//         userAuthService.saveToken(refreshedTokenResponse.data);
//         // Update the original request headers with the new token
//         originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
//         // Retry the original request
//         return api(originalRequest);
//       } catch (refreshError) {
//         // Handle refresh token failure (e.g., redirect to login page)
//         Promise.reject(refreshError);
//       }
//     }
//     // Handle other error cases
//     return Promise.reject(error);
//   }
// );

api.interceptors.request.use(function (config) {
  const userAuthService = new UserAuthServiceApi();
  const tokens = userAuthService.getToken();

  const token = tokens.access_token;
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
