import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { usePatchBackOfficePosition } from "@/services/backoffice-position/hooks/use-patch-backoffice-position";

import { BackOfficePositionColumn } from "../constants/table-column";
import { EditPositionSchema, editPositionSchema } from "../validators";

export const useEditDialog = () => {
  const [selectedValue, setSelectedValue] =
    useState<BackOfficePositionColumn>();
  const [dialogOpen, setDialogOpen] = useState(false);

  const methods = useForm<EditPositionSchema>({
    defaultValues: {},
    mode: "onChange",
    resolver: zodResolver(editPositionSchema),
  });

  const { handleSubmit, reset } = methods;

  const { mutateAsync, isPending } = usePatchBackOfficePosition();

  const onSubmit = useCallback(
    async (data: EditPositionSchema) => {
      await mutateAsync({
        id: selectedValue?.id ?? "",
        isActive: data.isActive,
        name: data.name,
      });

      setDialogOpen(false);
    },
    [mutateAsync, selectedValue?.id],
  );

  const onClick = (data: BackOfficePositionColumn) => {
    setSelectedValue(data);
  };

  useEffect(() => {
    if (!selectedValue) return;
    reset(selectedValue);
  }, [reset, selectedValue]);

  return {
    methods,
    handleSubmit,
    onSubmit,
    onClick,
    dialogOpen,
    setDialogOpen,
    isPending,
  };
};
