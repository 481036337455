import { useQuery, UseQueryOptions } from "@lezzcode/query";

import { BookingServiceApi } from "../api";
import { BookingEntity } from "../types/entity";
import { BookingQueryKeyFactory } from "../utils/query-key.factory";

export const useGetBooking = (
  id: string,
  options?: Omit<UseQueryOptions<BookingEntity, Error>, "queryKey">,
) => {
  const queryKeyFactory = new BookingQueryKeyFactory();
  const api = new BookingServiceApi();

  return useQuery<BookingEntity, Error>({
    queryKey: queryKeyFactory.detail(id),
    queryFn: () => api.getBooking(id),
    enabled: !!id,
    ...options,
  });
};
