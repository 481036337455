import { QueryKey } from "@lezzcode/query";

import { QueryKeyFactory } from "@/services/shared/query-key.factory";

export class UserQueryKeyFactory extends QueryKeyFactory {
  constructor() {
    super("users");
  }

  counter(): QueryKey {
    return [...this.all(), "counter"];
  }
}
