import { PassengerEntity } from "@/services/flight-passenger/types";

import { ApprovalPassenger } from "./approval-passenger";

interface ApprovalPassengerListProps {
  passengers?: PassengerEntity[];
}

export function ApprovalPassengerList({
  passengers,
}: ApprovalPassengerListProps): React.JSX.Element {
  return (
    <div className="flex flex-col">
      {passengers?.map((item) => (
        <ApprovalPassenger key={item.IdNumber} {...item} />
      ))}
    </div>
  );
}
