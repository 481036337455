"use client";

import { Icon, type IconProps } from "../ui/icon";

export function FileSearchOutlineIcon({
  className,
  size,
  ...props
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size} {...props}>
      <g clipPath="url(#clip0_767_24683)">
        <path d="M9.83268 1.33203H4.49935C4.14573 1.33203 3.80659 1.47251 3.55654 1.72256C3.30649 1.9726 3.16602 2.31174 3.16602 2.66536V13.332C3.16602 13.6857 3.30649 14.0248 3.55654 14.2748C3.80659 14.5249 4.14573 14.6654 4.49935 14.6654H9.16602C8.89268 14.4987 8.63268 14.292 8.40602 14.0654C8.18602 13.8454 7.99935 13.5987 7.83268 13.332H4.49935V2.66536H9.16602V5.9987H12.4993V6.78536C12.9727 6.89203 13.426 7.07203 13.8327 7.33203V5.33203L9.83268 1.33203ZM14.0393 12.5987C14.926 11.192 14.4993 9.33203 13.106 8.45203C11.6993 7.56536 9.83268 7.9987 8.95935 9.38536C8.06602 10.792 8.49935 12.6454 9.89268 13.532C10.866 14.152 12.106 14.152 13.086 13.5454L15.166 15.592L16.0927 14.6654L14.0393 12.5987ZM11.4993 12.6654C11.0573 12.6654 10.6334 12.4898 10.3208 12.1772C10.0083 11.8646 9.83268 11.4407 9.83268 10.9987C9.83268 10.5567 10.0083 10.1327 10.3208 9.82019C10.6334 9.50763 11.0573 9.33203 11.4993 9.33203C11.9414 9.33203 12.3653 9.50763 12.6779 9.82019C12.9904 10.1327 13.166 10.5567 13.166 10.9987C13.166 11.4407 12.9904 11.8646 12.6779 12.1772C12.3653 12.4898 11.9414 12.6654 11.4993 12.6654Z" />
      </g>
      <defs>
        <clipPath id="clip0_767_24683">
          <rect width="16" height="16" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </Icon>
  );
}
