import { z } from "zod";

import { backOfficeQueryFiltersSchema } from "@/features/backoffice/validators";

export const backOfficeCompanyQueryFiltersSchema =
  backOfficeQueryFiltersSchema.pick({
    search: true,
  });

export type BackOfficeCompanyQueryFilters = z.infer<
  typeof backOfficeCompanyQueryFiltersSchema
>;
