import { z } from "zod";

export const basePositionSchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1).max(50),
  isActive: z.boolean().default(false),
});

export const editPositionSchema = basePositionSchema;
export const addPositionSchema = editPositionSchema.omit({ id: true });

export type EditPositionSchema = z.infer<typeof editPositionSchema>;
export type AddPositionSchema = z.infer<typeof addPositionSchema>;
