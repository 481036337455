import { z } from "zod";

export const ssrSchema = z.object({
  SsrName: z.string(),
  OriginCode: z.string(),
  DestinationCode: z.string(),
  Tax: z.number(),
});

export type SsrSchema = z.infer<typeof ssrSchema>;
export type SsrEntity = SsrSchema;
