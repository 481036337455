import { PlaneIcon } from "../icons/plane";

export interface TimelineItemProps {
  date: React.ReactNode;
  detail: React.ReactNode;
  isLastItem?: boolean;
}

const TimelineItem: React.FC<TimelineItemProps> = ({
  date,
  detail,
  isLastItem,
}) => {
  return (
    <div className="flex mb-4">
      <div className="w-1/4 pr-4 text-right">{date}</div>
      <div className="w-3/4 relative ml-4">
        {!isLastItem && (
          <>
            <div className="w-3 h-3 bg-primary rounded-full absolute top-0 -left-[5px]" />
            <div className="h-[calc(100%+1rem)] border-r-2 border-dashed border-primary absolute top-0 -bottom-0.5" />
          </>
        )}

        {isLastItem && (
          <PlaneIcon
            size={32}
            className="fill-primary rotate-[135deg] -translate-x-[14px]"
          />
        )}

        <div className="ml-10">{detail}</div>
      </div>
    </div>
  );
};

export interface VerticalTimelineProps {
  items: TimelineItemProps[];
}

export const VerticalTimeline = ({ items }: VerticalTimelineProps) => {
  return (
    <div>
      {items.map((item, index, arr) => (
        <TimelineItem
          key={index}
          date={item.date}
          detail={item.detail}
          isLastItem={arr.length - 1 === index}
        />
      ))}
    </div>
  );
};

VerticalTimeline.Item = TimelineItem;
