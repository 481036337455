import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";

import { cn } from "@/lib/utils";
import { NotificationEntity } from "@/services/notification/types/entity";

import { SharedNotificationItem } from "../shared-notification-item";

type TicketItemProps = NotificationEntity;

export function TicketItem({
  entityId,
  message,
  seen,
  createdAt,
}: TicketItemProps): React.JSX.Element {
  const navigate = useNavigate({ from: "/notification" });

  return (
    <SharedNotificationItem
      title={`${message} (${dayjs(createdAt).format("DD-MM-YYYY[,] HH:mm:ss")})`}
      descriptions={[`No. Pesanan : ${entityId}`]}
      className={cn(!seen && "bg-sky-300/20")}
      onClick={() =>
        navigate({
          to: "/my-tickets/$ticketId",
          params: { ticketId: entityId },
          search: { success: undefined },
        })
      }
    />
  );
}
