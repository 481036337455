import { useMutation, useQueryClient } from "@lezzcode/query";

import { EditModuleSchema } from "@/features/backoffice-module/validators";
import { toast } from "@/hooks/use-toast";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeModuleApi } from "../api";
import { BackOfficeModuleQueryKeyFactory } from "../query-key.factory";

export const usePatchBackOfficeModule = () => {
  const api = new BackOfficeModuleApi();
  const queryClient = useQueryClient();
  const queryKeyFactory = new BackOfficeModuleQueryKeyFactory();

  return useMutation<BaseApiResponse<boolean>, Error, EditModuleSchema>({
    mutationFn: api.update,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeyFactory.lists(),
      });
    },
    onSuccess: async () => {
      toast({
        title: "Update module berhasil!",
        variant: "success",
      });
    },
    onError: async () => {
      await queryClient.cancelQueries({
        queryKey: queryKeyFactory.lists(),
      });
      toast({
        title: `Update module gagal!`,
        variant: "destructive",
      });
    },
  });
};
