import React from "react";
import { Icon, IconProps } from "../ui/icon";

export function CheckboxCircleFillIcon({
  className,
  size,
  ...props
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size} {...props}>
      <path d="M8.72184 14.6663C5.03994 14.6663 2.05518 11.6815 2.05518 7.99967C2.05518 4.31777 5.03994 1.33301 8.72184 1.33301C12.4037 1.33301 15.3885 4.31777 15.3885 7.99967C15.3885 11.6815 12.4037 14.6663 8.72184 14.6663ZM8.05691 10.6663L12.771 5.95229L11.8282 5.00949L8.05691 8.78074L6.17132 6.89507L5.2285 7.83794L8.05691 10.6663Z" />
    </Icon>
  );
}
