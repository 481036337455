import { Clock3Icon } from "lucide-react";
import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";
import { formatDuration } from "@/utils/function";

interface DurationDetailProps extends HTMLAttributes<HTMLDivElement> {
  icon?: JSX.Element;
  origin: string;
  destination: string;
  duration: string;
}

export function DurationDetail({
  icon = <Clock3Icon className="w-[20px]" />,
  origin,
  destination,
  duration,
  className,
  ...rest
}: DurationDetailProps): React.JSX.Element {
  return (
    <div
      className={cn(
        "flex flex-row justify-between items-center bg-muted px-2.5 py-3.5 border-b",
        className,
      )}
      {...rest}
    >
      <p className="text-xl">
        {origin} -&gt; {destination}
      </p>
      <div className="flex flex-row items-center gap-[5px]">
        <p className="text-base">{formatDuration(duration)}</p>
        {icon}
      </div>
    </div>
  );
}
