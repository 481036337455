import type { IconProps } from "../ui/icon";
import { Icon } from "../ui/icon";

export function SeatBoldIcon({
  className,
  size,
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size}>
      <path
        d="M18.0002 16.5002L21.0002 2.00024C21.0002 2.00024 15.7502 3.00024 15.7502 5.00024C15.7502 6.25024 17.7502 7.00024 17.0002 7.75024C16.2502 8.50024 13.5002 9.25024 13.5002 11.2502C13.5002 12.5002 14.3202 13.4302 13.5002 14.2502C12.7502 15.0002 12.0002 14.6072 10.7502 14.2502C9.35024 13.8502 6.50024 13.5002 5.50024 13.7502C4.50024 14.0002 3.50024 14.5002 3.50024 16.0002C3.50024 16.8252 4.00024 17.7502 5.25024 18.0002C6.50024 18.2502 8.00024 17.0002 10.0002 17.0002C12.0002 17.0002 14.5002 18.5002 16.0002 18.5002C17.5002 18.5002 18.0002 16.5002 18.0002 16.5002Z"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M5.50049 18L4.00049 22H20.5005L17.5005 18"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
