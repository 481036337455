import { useQuery } from "@lezzcode/query";
import { AxiosError } from "axios";

import { BackOfficeModuleQueryFilters } from "@/features/backoffice-module/validators/query-filters";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeModuleApi } from "../api";
import { BackOfficeModuleQueryKeyFactory } from "../query-key.factory";
import { BackOfficeModuleDetail } from "../types";

export const useFindBackOfficeModule = (
  filters?: BackOfficeModuleQueryFilters,
) => {
  const api = new BackOfficeModuleApi();
  const queryKeyFactory = new BackOfficeModuleQueryKeyFactory();

  return useQuery<BaseApiResponse<BackOfficeModuleDetail[]>, AxiosError>({
    queryKey: queryKeyFactory.list(filters),
    queryFn: () => {
      return api.list({
        search: filters?.search,
      });
    },
  });
};
