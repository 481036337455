import { useEffect } from "react";

import { cn } from "@/lib/utils";

import { CheckCircleBoldIcon } from "./icons/check-circle-bold";

interface SuccessInfoProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  image?: JSX.Element;
  title: JSX.Element | string;
  description: JSX.Element | string;
  icon?: JSX.Element;
  onRedirect: () => void;
}

export function SuccessInfo({
  image = <img src="/image/success.png" className="w-[12.5rem]" />,
  title,
  description,
  icon = <CheckCircleBoldIcon className="w-[60px] h-[60px] fill-[#8FBA4D]" />,
  onRedirect,
  className,
  ...rest
}: SuccessInfoProps): React.JSX.Element {
  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      onRedirect();
    }, 3000);

    return () => clearTimeout(redirectTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={cn(
        "flex flex-col h-screen justify-center items-center px-12 gap-[50px] bg-white",
        className,
      )}
      {...rest}
    >
      {image}
      <div className="flex flex-col items-center gap-2.5">
        {typeof title === "object" && title}
        {typeof title === "string" && (
          <p className="text-2xl text-muted-foreground">{title}</p>
        )}

        {typeof description === "object" && description}
        {typeof description === "string" && (
          <p className="text-xl text-muted-foreground-secondary text-center">
            {description}
          </p>
        )}
      </div>
      {icon}
    </div>
  );
}
