import { Row } from "@tanstack/react-table";
import { match } from "ts-pattern";

import { CheckboxCircleFillIcon } from "@/components/icons/checkbox-circle-fill";
import { TimeFillIcon } from "@/components/icons/time-fill";
import {
  StatusBadge,
  StatusBadgeProps,
} from "@/components/shared/table/badges/status-badge";

import { BackOfficeInvoiceColumn } from "../constants/table-column";
import { BackOfficeInvoiceStatus } from "../types";

interface StatusBadgeInvoiceProps {
  status?: Row<BackOfficeInvoiceColumn>["original"]["status"];
}

export function StatusBadgeInvoice({ status }: StatusBadgeInvoiceProps) {
  const badgeProps = match(status?.trim().toUpperCase())
    .returnType<{
      status: BackOfficeInvoiceStatus;
      icon?: StatusBadgeProps["icon"];
      text?: string;
    }>()
    .with("SUDAH TERBIT", () => ({
      status: "PUBLISHED",
      icon: CheckboxCircleFillIcon,
      text: "Sudah Terbit",
    }))
    .with("SUDAH TERSEDIA", () => ({
      status: "AVAILABLE",
      icon: CheckboxCircleFillIcon,
      text: "Sudah Tersedia",
    }))
    .with("BELUM TERSEDIA", () => ({
      status: "UNPUBLISHED",
      icon: TimeFillIcon,
      text: "Belum Tersedia",
    }))
    .otherwise(() => ({ status: "DEFAULT" }));

  return (
    <StatusBadge {...badgeProps}>
      <p className="whitespace-nowrap">
        {badgeProps.text ?? badgeProps.status}
      </p>
    </StatusBadge>
  );
}
