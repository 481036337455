import type { IconProps } from "../ui/icon";
import { Icon } from "../ui/icon";

export function WhatsappOutline({
  className,
  size,
}: IconProps): React.JSX.Element {
  return (
    <Icon viewBox="0 0 16 16" className={className} size={size}>
      <path d="M12.6996 3.27329C12.0884 2.65591 11.3604 2.16641 10.558 1.83333C9.7556 1.50025 8.89495 1.33027 8.0262 1.33329C4.38621 1.33329 1.41955 4.29996 1.41955 7.93995C1.41955 9.1066 1.72621 10.24 2.29955 11.24L1.36621 14.6667L4.86621 13.7467C5.8329 14.2733 6.91955 14.5533 8.0262 14.5533C11.6662 14.5533 14.6329 11.5867 14.6329 7.9466C14.6329 6.17995 13.9462 4.51996 12.6996 3.27329ZM8.0262 13.4333C7.03955 13.4333 6.0729 13.1667 5.2262 12.6667L5.0262 12.5467L2.94621 13.0933L3.49955 11.0667L3.36621 10.86C2.81804 9.9846 2.52697 8.9728 2.52621 7.93995C2.52621 4.91329 4.99288 2.44663 8.01955 2.44663C9.4862 2.44663 10.8662 3.01996 11.8996 4.05996C12.4112 4.56927 12.8167 5.17505 13.0925 5.84225C13.3683 6.5094 13.5089 7.2247 13.5062 7.9466C13.5196 10.9733 11.0529 13.4333 8.0262 13.4333ZM11.0396 9.3266C10.8729 9.2466 10.0596 8.84665 9.9129 8.7866C9.75955 8.7333 9.6529 8.7066 9.53955 8.8666C9.4262 9.0333 9.1129 9.4066 9.01955 9.5133C8.9262 9.62665 8.8262 9.63995 8.65955 9.5533C8.4929 9.4733 7.95955 9.2933 7.3329 8.7333C6.83955 8.2933 6.5129 7.7533 6.4129 7.58665C6.31955 7.41995 6.39955 7.3333 6.4862 7.2466C6.55955 7.1733 6.6529 7.0533 6.7329 6.95995C6.8129 6.8666 6.8462 6.7933 6.89955 6.6866C6.9529 6.5733 6.9262 6.47995 6.8862 6.39995C6.8462 6.31995 6.5129 5.50665 6.37955 5.1733C6.2462 4.85329 6.1062 4.89329 6.0062 4.88663H5.6862C5.5729 4.88663 5.39955 4.92663 5.2462 5.0933C5.09955 5.25995 4.67288 5.65995 4.67288 6.4733C4.67288 7.28665 5.2662 8.0733 5.3462 8.17995C5.4262 8.2933 6.5129 9.95995 8.1662 10.6733C8.55955 10.8467 8.8662 10.9467 9.1062 11.02C9.49955 11.1467 9.85955 11.1266 10.1462 11.0866C10.4662 11.04 11.1262 10.6866 11.2596 10.3C11.3996 9.9133 11.3996 9.58665 11.3529 9.5133C11.3062 9.43995 11.2062 9.4066 11.0396 9.3266Z" />
    </Icon>
  );
}
