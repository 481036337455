"use client";

import type { IconProps } from "../ui/icon";
import { Icon } from "../ui/icon";

export function Luggage2Icon({
  className,
  size,
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size}>
      <path d="M17 6.5H15V3.5C15 2.95 14.55 2.5 14 2.5H10C9.45 2.5 9 2.95 9 3.5V6.5H7C5.9 6.5 5 7.4 5 8.5V19.5C5 20.6 5.9 21.5 7 21.5C7 22.05 7.45 22.5 8 22.5C8.55 22.5 9 22.05 9 21.5H15C15 22.05 15.45 22.5 16 22.5C16.55 22.5 17 22.05 17 21.5C18.1 21.5 19 20.6 19 19.5V8.5C19 7.4 18.1 6.5 17 6.5ZM9.5 18.5H8V9.5H9.5V18.5ZM12.75 18.5H11.25V9.5H12.75V18.5ZM13.5 6.5H10.5V4H13.5V6.5ZM16 18.5H14.5V9.5H16V18.5Z" />
    </Icon>
  );
}
