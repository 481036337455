import { useMutation, useQueryClient } from "@lezzcode/query";

import { BackOfficeLoginSchema } from "@/features/backoffice-auth/validators";
import { toast } from "@/hooks/use-toast";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeLoginApi } from "../api";
import { BackOfficeLoginQueryKeyFactory } from "../query-key.factory";
import { BackOfficeLoginEntity } from "../types";

export const usePostBackOfficeLogin = () => {
  const api = new BackOfficeLoginApi();
  const queryClient = useQueryClient();
  const queryKeyFactory = new BackOfficeLoginQueryKeyFactory();

  return useMutation<
    BaseApiResponse<BackOfficeLoginEntity>,
    Error,
    BackOfficeLoginSchema
  >({
    mutationFn: api.login,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeyFactory.list(),
      });
    },
    onSuccess: async () => {
      toast({
        title: "Login berhasil!",
        variant: "success",
      });
    },
    onError: async () => {
      await queryClient.cancelQueries({
        queryKey: queryKeyFactory.list(),
      });
      toast({
        title: `Login gagal!`,
        variant: "destructive",
      });
    },
  });
};
