import { useQuery } from "@lezzcode/query";

import { BookingServiceApi } from "../api";
import { BookingPreviewEntity } from "../types/entity";
import { BookingQueryKeyFactory } from "../utils/query-key.factory";

export const useViewBookingTicket = (id: string) => {
  const queryKeyFactory = new BookingQueryKeyFactory();
  const api = new BookingServiceApi();

  return useQuery<BookingPreviewEntity, Error>({
    queryKey: queryKeyFactory.preview(id),
    queryFn: () => api.viewBookingTicket(id),
    enabled: !!id,
  });
};
