import { createFileRoute, useRouter } from "@tanstack/react-router";
import { FormProvider } from "react-hook-form";

import { BaseFooter } from "@/components/layout/footer/base-footer";
import { BaseNavHeader } from "@/components/layout/header/base-nav";
import { StatusWrapper } from "@/components/shared/status-wrapper";
import { Button } from "@/components/ui/button";
import { PreviewCard } from "@/features/flight-ticket/components/preview-card";
import { TimePeriodSelect } from "@/features/histories/components/time-period-select";
import { useHistoriesPage } from "@/features/histories/hooks/use-histories-page";

export const Route = createFileRoute("/histories/")({
  component: Index,
});

function Index() {
  const { history } = useRouter();
  const { methods, bookingHistories, isFetching } = useHistoriesPage();

  return (
    <div className="flex flex-col h-screen bg-white">
      <BaseNavHeader title="Riwayat" />

      <div className="flex-grow flex flex-col items-start gap-[22px] px-4 py-3">
        <FormProvider {...methods}>
          <TimePeriodSelect placeholder="Pilih Rentang Waktu">
            {({ onOpen }) => (
              <Button
                variant="outline"
                className="p-1 text-primary hover:text-primary text-sm font-semibold border-primary rounded-md"
                onClick={onOpen}
              >
                <img src="/image/time-range.png" />
                <p className="px-2">Rentang Waktu</p>
              </Button>
            )}
          </TimePeriodSelect>
        </FormProvider>

        <StatusWrapper.List
          className="w-full flex flex-col gap-2.5"
          classNames={{
            renderLoading: "h-40",
          }}
          isLoading={isFetching}
          rows={5}
          renderEmpty="Belum ada riwayat..."
        >
          {bookingHistories.map((item) => (
            <div key={String(item.category)}>
              <p className="font-bold uppercase mb-2.5">
                {String(item.category)}
              </p>
              <div className="flex flex-col gap-2.5">
                {item.items?.map((item, index) => (
                  <PreviewCard key={index} {...item} />
                ))}
              </div>
            </div>
          ))}
        </StatusWrapper.List>
      </div>

      <BaseFooter>
        <Button
          className="w-full text-lg h-12 text-white font-bold"
          variant="secondary"
          onClick={() => history.back()}
        >
          Kembali
        </Button>
      </BaseFooter>
    </div>
  );
}
