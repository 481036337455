import { ArrowUpDownIcon, FilterIcon } from "lucide-react";
import { useFormContext } from "react-hook-form";

import { Button } from "@/components/ui/button";
import { FormField } from "@/components/ui/form";

import { priceSortLabel } from "../utils";
import { FlightBookingFilterSchema } from "../validators";
import { FilterSelect } from "./filter-select";

export function FlightBookingFilters(): React.JSX.Element {
  const methods = useFormContext<FlightBookingFilterSchema>();

  return (
    <div className="w-full flex flex-row justify-between items-center py-3">
      <FilterSelect placeholder="Pilih maskapai">
        {({ onOpen }) => (
          <Button
            variant="outline"
            className="p-1 text-primary hover:text-primary text-sm border-primary rounded-md rounded-2xl"
            onClick={onOpen}
          >
            <FilterIcon size={20} className="ml-1" />
            <p className="px-2">Filter</p>
          </Button>
        )}
      </FilterSelect>

      <FormField
        control={methods.control}
        name="price"
        render={({ field }) => (
          <Button
            variant="outline"
            className="p-1 text-primary hover:text-primary text-sm border-primary rounded-md rounded-2xl"
            onClick={() => {
              if (field.value === "asc") return field.onChange("desc");
              field.onChange("asc");
            }}
          >
            <ArrowUpDownIcon size={20} className="ml-1" />
            <p className="px-2">{priceSortLabel[field.value]}</p>
          </Button>
        )}
      />
    </div>
  );
}
