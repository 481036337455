import { ColumnDef } from "@tanstack/react-table";

import { Switch } from "@/components/ui/switch";
import { BackOfficeUserDetail } from "@/services/backoffice-user/types";

import { EditDialog } from "../components/edit-dialog";

export type BackOfficeUserColumn = BackOfficeUserDetail;

export const columns: ColumnDef<BackOfficeUserColumn>[] = [
  {
    id: "no",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">NO</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">{row.index + 1}</div>
    ),
  },
  {
    accessorKey: "firstName",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">NAMA DEPAN</p>
      );
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.firstName ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "lastName",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">NAMA BELAKANG</p>
      );
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.lastName ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "email",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">EMAIL</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.email ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "phoneNumber",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">NO HP</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.phoneNumber ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "role",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">ROLE</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.role.name ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "isActive",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">STATUS</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        <Switch checked={row.original.isActive ?? "-"} />
      </div>
    ),
  },
  {
    accessorKey: "action",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">ACTION</p>;
    },
    enableHiding: false,
    cell: ({ row, ...props }) => {
      return <EditDialog row={row} {...props} />;
    },
  },
];
