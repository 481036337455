import { Trash2, UserRound } from "lucide-react";

import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

interface UnknownPassengerCardProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  title: string;
  nik: string;
  onDelete?: () => unknown;
}

export function KnownPassengerCard({
  title,
  nik,
  className,
  onClick,
  onDelete,
  ...rest
}: UnknownPassengerCardProps): React.JSX.Element {
  return (
    <div
      className={cn(
        "w-full h-[5.375rem] flex items-center gap-2.5 border justify-between border-border rounded-lg py-1.5 px-4",
        onClick && "cursor-pointer",
        className,
      )}
      style={{ boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)" }}
      onClick={onClick}
      {...rest}
    >
      <div className="flex items-center gap-2.5">
        <UserRound className="h-8 w-8 text-primary" />
        <div className="flex flex-col">
          <p className="text-xl text-primary">{title}</p>
          <p className="text-foreground text-base">{nik}</p>
        </div>
      </div>
      <Button
        className="-mr-2"
        size="icon"
        variant="ghost"
        onClick={(e) => {
          e.stopPropagation();
          onDelete && onDelete();
        }}
      >
        <Trash2 className="text-destructive-foreground h-6 w-6" />
      </Button>
    </div>
  );
}
