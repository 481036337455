import React from "react";

import { Icon, IconProps } from "../ui/icon";

export function IntermediateCircleFillIcon({
  className,
  size,
  ...props
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size} {...props}>
      <path d="M8.22184 14.6663C4.53994 14.6663 1.55518 11.6815 1.55518 7.99967C1.55518 4.31777 4.53994 1.33301 8.22184 1.33301C11.9037 1.33301 14.8885 4.31777 14.8885 7.99967C14.8885 11.6815 11.9037 14.6663 8.22184 14.6663ZM4.88851 7.33301V8.66634H11.5552V7.33301H4.88851Z" />
    </Icon>
  );
}
