import { useMutation, useQueryClient } from "@lezzcode/query";

import { EditClassSchema } from "@/features/backoffice-class/validators";
import { toast } from "@/hooks/use-toast";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeClassApi } from "../api";
import { BackOfficeClassQueryKeyFactory } from "../query-key.factory";

export const usePatchBackOfficeClass = () => {
  const api = new BackOfficeClassApi();
  const queryClient = useQueryClient();
  const queryKeyFactory = new BackOfficeClassQueryKeyFactory();

  return useMutation<BaseApiResponse<boolean>, Error, EditClassSchema>({
    mutationFn: api.update,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeyFactory.lists(),
      });
    },
    onSuccess: async () => {
      toast({
        title: "Update kelas berhasil!",
        variant: "success",
      });
    },
    onError: async () => {
      await queryClient.cancelQueries({
        queryKey: queryKeyFactory.lists(),
      });
      toast({
        title: `Update kelas gagal!`,
        variant: "destructive",
      });
    },
  });
};
