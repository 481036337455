import type { IconProps } from "../ui/icon";
import { Icon } from "../ui/icon";

export function DownloadBoldIcon({
  className,
  size,
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size}>
      <path d="M5 20H19V18H5V20ZM19 9H15V3H9V9H5L12 16L19 9Z" />
    </Icon>
  );
}
