import { QueryKeyFactory } from "../shared/query-key.factory";

export class BackOfficeQueryKeyFactory extends QueryKeyFactory {
  constructor() {
    super("back-office");
  }

  profile() {
    return [...this.all(), "profile"];
  }
}
