import { z } from "zod";

export const backOfficeOrderQuerySchema = z
  .object({
    search: z.string().optional(),
    status: z.string().optional(),
    dateRange: z
      .object({
        from: z.date().optional(),
        to: z.date().optional(),
      })
      .optional(),
  })
  .refine(
    (data) =>
      (data.dateRange?.from && data.dateRange?.to) ||
      (!data.dateRange?.from && !data.dateRange?.to),
    {
      message: "need to specify both 'from' and 'to'",
      path: ["dateRange"],
    },
  );

export type BackOfficeOrderQuery = z.infer<typeof backOfficeOrderQuerySchema>;
