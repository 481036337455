import { cva, VariantProps } from "class-variance-authority";
import React from "react";

import { Badge } from "@/components/ui/badge";
import { IconProps } from "@/components/ui/icon";
import { BackOfficeSharedStatus } from "@/features/backoffice/types";
import { BackOfficeInvoiceStatus } from "@/features/backoffice-invoice/types";
import { BackOfficeOrderStatus } from "@/features/backoffice-order/types";
import { cn } from "@/lib/utils";

export type StatusVariantKey =
  | "DEFAULT"
  | BackOfficeOrderStatus
  | BackOfficeInvoiceStatus
  | BackOfficeSharedStatus;

type StatusVariant = Record<StatusVariantKey, string>;

const statusBadgeVariants = cva<{ status: StatusVariant }>(
  "flex justify-center items-center font-bold space-x-1",
  {
    variants: {
      status: {
        DEFAULT: "bg-[#77A5FF33] text-[#0065B3]",
        RESCHEDULED: "bg-[#B5850B33] text-[#B5850B]",
        UNPUBLISHED: "bg-[#B5850B33] text-[#B5850B]",
        COMPLETED: "bg-[#FFD27733] text-[#B5850B]",
        RESERVED: "bg-[#FFD27733] text-[#B5850B]",
        SAVED: "bg-[#FFD27733] text-[#B5850B]",
        PUBLISHED: "bg-[#65FF5733] text-[#0BB530]",
        AVAILABLE: "bg-[#579AFF33] text-[#0E0BB5A3]",
        TICKETED: "bg-[#39B44A33] text-[#165E3D]",
        REFUNDED: "bg-[#D34C4C33] text-[#D34C4C]",
        REJECTED: "bg-[#FFD27733] text-[#B5850B]",
        EDIT: "bg-[#B5850B33] text-[#B5850B]",
        DETAIL: "bg-[#77A5FF33] text-[#0065B3]",
      },
    },
    defaultVariants: {
      status: "DEFAULT",
    },
  },
);

const iconVariants = cva<{ status: StatusVariant }>("", {
  variants: {
    status: {
      DEFAULT: "fill-[#0065B3]",
      RESCHEDULED: "fill-[#B5850B]",
      PUBLISHED: "fill-[#0BB530]",
      AVAILABLE: "fill-[#0E0BB5A3]",
      UNPUBLISHED: "fill-[#B5850B]",
      TICKETED: "fill-[#165E3D]",
      REFUNDED: "fill-[#D34C4C]",
      EDIT: "fill-[#B5850B] stroke-[#B5850B]",
      DETAIL: "fill-[#0065B3]",
      COMPLETED: "fill-[#165E3D]",
      REJECTED: "fill-[#FFD277]",
      RESERVED: "fill-[#FFD277]",
      SAVED: "fill-[#FFD277]",
    },
  },
  defaultVariants: {
    status: "DEFAULT",
  },
});

export interface StatusBadgeProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof statusBadgeVariants> {
  children?: React.ReactNode;
  icon?: (props: IconProps) => React.JSX.Element;
}

export function StatusBadge({
  children,
  className,
  icon: Icon,
  status,
  ...props
}: Readonly<StatusBadgeProps>) {
  return (
    <Badge
      className={cn(
        statusBadgeVariants({ status, className }),
        "hover:bg-current/50",
      )}
      {...props}
    >
      {Icon && (
        <Icon
          size={16}
          viewBox="0 0 16 16"
          className={cn(iconVariants({ status, className }))}
        />
      )}
      {children}
    </Badge>
  );
}
