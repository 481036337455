import { z } from "zod";

export const baseModuleSchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1).max(50),
  url: z.string().min(1).optional(),
  active: z.boolean().default(false).optional(),
});

export const editModuleSchema = baseModuleSchema;
export const addModuleSchema = editModuleSchema.omit({ id: true });

export type EditModuleSchema = z.infer<typeof editModuleSchema>;
export type AddModuleSchema = z.infer<typeof addModuleSchema>;
