import { useMutation, useQueryClient } from "@lezzcode/query";

import { AddClassificationSchema } from "@/features/backoffice-classification/validators";
import { toast } from "@/hooks/use-toast";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeClassificationApi } from "../api";
import { BackOfficeClassificationQueryKeyFactory } from "../query-key.factory";

export const useAddBackOfficeClassification = () => {
  const api = new BackOfficeClassificationApi();
  const queryClient = useQueryClient();
  const queryKeyFactory = new BackOfficeClassificationQueryKeyFactory();

  return useMutation<BaseApiResponse<boolean>, Error, AddClassificationSchema>({
    mutationFn: api.add,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeyFactory.lists(),
      });
    },
    onSuccess: async () => {
      toast({
        title: "Tambah klasifikasi berhasil!",
        variant: "success",
      });
    },
    onError: async () => {
      await queryClient.cancelQueries({
        queryKey: queryKeyFactory.lists(),
      });
      toast({
        title: `Tambah klasifikasi gagal!`,
        variant: "destructive",
      });
    },
  });
};
