import type { IconProps } from "../ui/icon";
import { Icon } from "../ui/icon";

export function UserBoldIcon({
  className,
  size,
}: IconProps): React.JSX.Element {
  return (
    <Icon viewBox="0 0 32 33" className={className} size={size}>
      <g clip-path="url(#clip0_2195_9394)">
        <path d="M14.667 3.19336C12.8989 3.19336 11.2032 3.89574 9.95295 5.14598C8.7027 6.39622 8.00033 8.09192 8.00033 9.86003C8.00033 11.6281 8.7027 13.3238 9.95295 14.5741C11.2032 15.8243 12.8989 16.5267 14.667 16.5267C16.4351 16.5267 18.1308 15.8243 19.381 14.5741C20.6313 13.3238 21.3337 11.6281 21.3337 9.86003C21.3337 8.09192 20.6313 6.39622 19.381 5.14598C18.1308 3.89574 16.4351 3.19336 14.667 3.19336ZM14.667 17.86C15.4537 17.86 16.223 17.916 16.9657 18.02C17.2159 18.055 17.451 18.1603 17.6436 18.3237C17.8363 18.4871 17.9785 18.7019 18.0539 18.9431C18.1292 19.1842 18.1344 19.4418 18.069 19.6858C18.0036 19.9298 17.8702 20.1502 17.6843 20.3214C16.5869 21.3266 15.7465 22.5803 15.2338 23.9774C14.7211 25.3745 14.551 26.8742 14.7377 28.3507C14.7612 28.5404 14.7437 28.733 14.6863 28.9153C14.6289 29.0977 14.5329 29.2655 14.405 29.4075C14.277 29.5495 14.12 29.6624 13.9446 29.7384C13.7692 29.8144 13.5795 29.8518 13.3883 29.848C10.7083 29.7947 8.20166 29.56 6.32966 28.9867C5.39366 28.7 4.51099 28.2974 3.84166 27.704C3.13366 27.0774 2.66699 26.2334 2.66699 25.1934C2.66699 24.144 3.14433 23.1627 3.79233 22.3414C4.45099 21.508 5.36166 20.7414 6.42966 20.088C8.56699 18.7867 11.4737 17.86 14.667 17.86ZM28.283 19.2387C28.9079 19.8638 29.2589 20.7115 29.2589 21.5954C29.2589 22.4792 28.9079 23.3269 28.283 23.952L23.7257 28.5094C23.4198 28.8151 23.0231 29.0134 22.595 29.0747L19.5163 29.5147C19.3112 29.5439 19.1021 29.525 18.9055 29.4593C18.709 29.3937 18.5304 29.2833 18.384 29.1367C18.2375 28.9901 18.1272 28.8115 18.0618 28.6149C17.9964 28.4183 17.9776 28.2091 18.007 28.004L18.447 24.9267C18.508 24.4981 18.7063 24.1009 19.0123 23.7947L23.5697 19.2374C24.1948 18.6125 25.0424 18.2614 25.9263 18.2614C26.8102 18.2614 27.6579 18.6125 28.283 19.2374V19.2387Z" />
      </g>
      <defs>
        <clipPath id="clip0_2195_9394">
          <rect width="32" height="32" transform="translate(0 0.527344)" />
        </clipPath>
      </defs>
    </Icon>
  );
}
