import { useQuery } from "@lezzcode/query";

import { BookingServiceApi } from "../api";
import { BookingHistoriesDto } from "../dto";
import { BookingEntity } from "../types/entity";
import { BookingQueryKeyFactory } from "../utils/query-key.factory";

export const useFindBooking = (dto?: BookingHistoriesDto) => {
  const api = new BookingServiceApi();
  const queryKeyFactory = new BookingQueryKeyFactory();

  return useQuery<BookingEntity[], Error>({
    queryKey: queryKeyFactory.list(dto),
    queryFn: () => api.findBooking(dto),
    initialData: [],
  });
};
