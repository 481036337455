import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import { useAddBackOfficeAccessModule } from "@/services/backoffice-access-module/hooks/use-add-backoffice-access-module";

import { AddAccessModuleSchema, addAccessModuleSchema } from "../validators";

export const useAddDialog = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const methods = useForm<AddAccessModuleSchema>({
    defaultValues: {},
    mode: "onChange",
    resolver: zodResolver(addAccessModuleSchema),
  });

  const { handleSubmit } = methods;

  const { mutateAsync, isPending } = useAddBackOfficeAccessModule();

  const onSubmit = useCallback(
    async (data: AddAccessModuleSchema) => {
      await mutateAsync(data);
      setDialogOpen(false);
    },
    [mutateAsync],
  );

  return {
    methods,
    handleSubmit,
    onSubmit,
    dialogOpen,
    setDialogOpen,
    isPending,
  };
};
