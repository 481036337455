import { useSearch } from "@tanstack/react-router";
import { ChevronDownIcon, PersonStandingIcon } from "lucide-react";
import React from "react";

import { StatusWrapper } from "@/components/shared/status-wrapper";
import { useGetBooking } from "@/services/booking/hooks/use-get-booking";

import { SectionContainer } from "./section-container";

export function PassengerInformations(): React.JSX.Element {
  const search = useSearch({ from: "/checkout/" });
  const { data: bookingData, isPending } = useGetBooking(search.bookingId);

  const Passengers = bookingData?.reservation.Passengers;

  return (
    <SectionContainer>
      <p className="text-base mb-1">Informasi Penumpang</p>
      <StatusWrapper.List
        isLoading={isPending}
        className="flex flex-col gap-1"
        rows={1}
      >
        {Passengers?.map((item, index) => (
          <div
            key={index}
            className="flex flex-row items-center gap-2.5 py-2 border-b"
          >
            <PersonStandingIcon size={24} className="fill-muted-foreground" />
            <div className="flex-1 flex flex-col gap-1">
              <p>
                Penumpang {index + 1} - {item.Type}
              </p>
              <p className="text-primary">
                {item.FirstName} {item.LastName}
              </p>
            </div>
            <div className="p-1 rounded-full border">
              <ChevronDownIcon
                size={24}
                className="stroke-muted-foreground-secondary"
              />
            </div>
          </div>
        ))}
      </StatusWrapper.List>
    </SectionContainer>
  );
}
