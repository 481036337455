import React from "react";
import { Icon, IconProps } from "../ui/icon";

export function TablerEditIcon({
  className,
  size,
  ...props
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size} {...props}>
      <path
        d="M4.66699 4.66699H4.00033C3.6467 4.66699 3.30756 4.80747 3.05752 5.05752C2.80747 5.30756 2.66699 5.6467 2.66699 6.00033V12.0003C2.66699 12.3539 2.80747 12.6931 3.05752 12.9431C3.30756 13.1932 3.6467 13.3337 4.00033 13.3337H10.0003C10.3539 13.3337 10.6931 13.1932 10.9431 12.9431C11.1932 12.6931 11.3337 12.3539 11.3337 12.0003V11.3337"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.6667 3.3334L12.6667 5.3334M13.59 4.39007C13.8526 4.12751 14.0001 3.77139 14.0001 3.40007C14.0001 3.02875 13.8526 2.67264 13.59 2.41007C13.3274 2.14751 12.9713 2 12.6 2C12.2287 2 11.8726 2.14751 11.61 2.41007L6 8.00007V10.0001H8L13.59 4.39007Z"
        stroke="#B5850B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
}
