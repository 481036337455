import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { SelectDrawer } from "@/components/ui/select-drawer";
import { useDisclose } from "@/hooks/use-disclose";

import { HistoriesFilterSchema } from "../validators";

interface TimePeriodSelectProps {
  children: (props: ReturnType<typeof useDisclose>) => React.JSX.Element;
  placeholder: string;
}

export function TimePeriodSelect({
  children,
  placeholder,
}: TimePeriodSelectProps): React.JSX.Element {
  const methods = useFormContext<HistoriesFilterSchema>();
  const { isOpen, setIsOpen, onOpen, onClose, onToggle } = useDisclose();

  const startDate = methods.watch("from");
  const endDate = methods.watch("to");

  return (
    <div>
      {children({ isOpen, setIsOpen, onOpen, onClose, onToggle })}
      <SelectDrawer
        open={isOpen}
        onOpenChange={setIsOpen}
        placeholder={placeholder}
        classNames={{ drawerContent: "h-[360px]" }}
      >
        <div className="p-3 bg-white">
          <p className="text-xs text-center text-muted-foreground-secondary mb-10">
            *Tanggal <i>sampai</i> tidak boleh lebih kecil dari tanggal{" "}
            <i>dari</i>
          </p>
          <div className="flex flex-col">
            <FormField
              control={methods.control}
              name="from"
              render={({ field: { value, onChange } }) => {
                const formattedValue = value?.slice(0, 10);

                return (
                  <FormItem className="mb-4">
                    <FormLabel className="font-bold text-md">Dari</FormLabel>
                    <FormControl>
                      <Input
                        type="date"
                        max={endDate}
                        value={formattedValue}
                        onChange={(e) =>
                          onChange(dayjs(e.target.value).toISOString())
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <FormField
              control={methods.control}
              name="to"
              render={({ field: { value, onChange } }) => {
                const formattedValue = value?.slice(0, 10);

                return (
                  <FormItem className="mb-4">
                    <FormLabel className="font-bold text-md">Sampai</FormLabel>
                    <FormControl>
                      <Input
                        type="date"
                        min={startDate}
                        value={formattedValue}
                        onChange={(e) =>
                          onChange(dayjs(e.target.value).toISOString())
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>
        </div>
      </SelectDrawer>
    </div>
  );
}
