import type { IconProps } from "../ui/icon";
import { Icon } from "../ui/icon";

export function PositionBoldIcon({
  className,
  size,
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size}>
      <g clipPath="url(#clip0_186_52)">
        <path d="M12.0003 3C11.2918 2.99895 10.6058 3.24866 10.0638 3.70493C9.5218 4.16119 9.15876 4.79455 9.03899 5.49283C8.91921 6.19111 9.05043 6.90925 9.40941 7.52005C9.76838 8.13085 10.3319 8.5949 11.0003 8.83V11H8.00027C7.20462 11 6.44156 11.3161 5.87895 11.8787C5.31634 12.4413 5.00027 13.2044 5.00027 14V15.17C4.33278 15.4059 3.77018 15.8702 3.41191 16.4808C3.05365 17.0914 2.92279 17.809 3.04246 18.5068C3.16214 19.2046 3.52464 19.8375 4.0659 20.2939C4.60716 20.7502 5.29232 21.0005 6.00027 21.0005C6.70823 21.0005 7.39339 20.7502 7.93465 20.2939C8.4759 19.8375 8.83841 19.2046 8.95809 18.5068C9.07776 17.809 8.9469 17.0914 8.58863 16.4808C8.23037 15.8702 7.66777 15.4059 7.00027 15.17V14C7.00027 13.7348 7.10563 13.4804 7.29317 13.2929C7.4807 13.1054 7.73506 13 8.00027 13H16.0003C16.2655 13 16.5198 13.1054 16.7074 13.2929C16.8949 13.4804 17.0003 13.7348 17.0003 14V15.17C16.3328 15.4059 15.7702 15.8702 15.4119 16.4808C15.0536 17.0914 14.9228 17.809 15.0425 18.5068C15.1621 19.2046 15.5246 19.8375 16.0659 20.2939C16.6072 20.7502 17.2923 21.0005 18.0003 21.0005C18.7082 21.0005 19.3934 20.7502 19.9346 20.2939C20.4759 19.8375 20.8384 19.2046 20.9581 18.5068C21.0778 17.809 20.9469 17.0914 20.5886 16.4808C20.2304 15.8702 19.6678 15.4059 19.0003 15.17V14C19.0003 13.2044 18.6842 12.4413 18.1216 11.8787C17.559 11.3161 16.7959 11 16.0003 11H13.0003V8.83C13.6674 8.59385 14.2296 8.12952 14.5876 7.51904C14.9456 6.90855 15.0763 6.19118 14.9567 5.49366C14.8371 4.79614 14.4747 4.16334 13.9338 3.70705C13.3928 3.25076 12.708 3.00033 12.0003 3Z" />
      </g>
      <defs>
        <clipPath id="clip0_186_52">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </Icon>
  );
}
