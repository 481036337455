import { useQuery } from "@lezzcode/query";

import { NotificationServiceApi } from "../api";
import { NotificationEntity } from "../types/entity";
import { NotificationQueryKeyFactory } from "../utils/query-key.factory";

export const useFindNotification = () => {
  const api = new NotificationServiceApi();
  const queryKeyFactory = new NotificationQueryKeyFactory();

  return useQuery<NotificationEntity[], Error>({
    queryKey: queryKeyFactory.lists(),
    queryFn: api.findNotification,
    initialData: [],
  });
};
