import { useMutation } from "@lezzcode/query";

import { BookingServiceApi } from "../api";
import { BookingTransactionEntity } from "../types/entity";

export const useGetDigiPayTransaction = () => {
  const api = new BookingServiceApi();

  return useMutation<{ data: BookingTransactionEntity }, Error, string>({
    mutationFn: api.getDigiPayTransaction,
  });
};
