interface BaseNavHeaderProps {
  title: string;
}

export function BaseNavHeader({
  title,
}: BaseNavHeaderProps): React.JSX.Element {
  return (
    <div className="w-full h-14 shrink-0 bg-primary flex items-center justify-center">
      <h1 className="text-xl text-white">{title}</h1>
    </div>
  );
}
