import { useQuery } from "@lezzcode/query";
import { AxiosError } from "axios";

import { BackOfficeInvoiceQueryFilters } from "@/features/backoffice-invoice/validators/query-filters";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeInvoiceApi } from "../api";
import { BackOfficeInvoiceQueryKeyFactory } from "../query-key.factory";
import { BackOfficeInvoiceDetail } from "../types";

export const useFindBackOfficeInvoice = (
  filters?: BackOfficeInvoiceQueryFilters,
) => {
  const backOfficeInvoiceApi = new BackOfficeInvoiceApi();
  const backOfficeInvoiceKeyFactory = new BackOfficeInvoiceQueryKeyFactory();

  return useQuery<BaseApiResponse<BackOfficeInvoiceDetail[]>, AxiosError>({
    queryKey: backOfficeInvoiceKeyFactory.list(filters),
    queryFn: () => {
      return backOfficeInvoiceApi.list({
        from: filters?.from as string,
        to: filters?.to as string,
        search: filters?.search,
        company: filters?.company,
        payer: filters?.payer,
      });
    },
  });
};
