import { StatusWrapper } from "@/components/shared/status-wrapper";
import { Checkbox } from "@/components/ui/checkbox";
import { useGetDigiBalance } from "@/services/digi/hooks/use-get-digi-balance";

import { PaymentMethodTypeEnum } from "../types";
import { SectionContainer } from "./section-container";

interface PaymentMethodsProps {
  paymentMethod?: PaymentMethodTypeEnum;
  onChange: (paymentMethod?: PaymentMethodTypeEnum) => void;
}

export function PaymentMethods({
  paymentMethod,
  onChange,
}: PaymentMethodsProps): React.JSX.Element {
  const { data: digiBalanceData, isFetching } = useGetDigiBalance();

  return (
    <SectionContainer>
      <p className="text-base font-bold mb-3">Pilih Pembayaran</p>
      <StatusWrapper.List isLoading={isFetching} rows={1}>
        <label htmlFor="saldo-digi" className="cursor-pointer">
          <div className="flex flex-row items-center gap-3.5 px-3 py-4.5">
            <Checkbox
              id="saldo-digi"
              checked={paymentMethod === PaymentMethodTypeEnum.SALDO_DIGI}
              onCheckedChange={(checked) => {
                if (checked) return onChange(PaymentMethodTypeEnum.SALDO_DIGI);
                onChange(undefined);
              }}
            />
            <img src="/image/flight-ticket-checkout/coin.png" />
            <div className="flex flex-row items-center gap-2.5">
              <div>
                <p className="text-base mb-1">Saldo Digi</p>
                <p className="text-sm text-muted-foreground">
                  Rp{" "}
                  {Number(digiBalanceData?.balance).toLocaleString("id", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </p>
              </div>
            </div>
          </div>
        </label>
      </StatusWrapper.List>
    </SectionContainer>
  );
}
