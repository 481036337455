import type { IconProps } from "../ui/icon";
import { Icon } from "../ui/icon";

export function BaggageIcon({ className, size }: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7172 4.71505C11.1787 4.71506 10.6546 4.88892 10.2228 5.21075C9.79102 5.53259 9.47468 5.9852 9.32081 6.50127H14.1136C13.9597 5.9852 13.6434 5.53259 13.2116 5.21075C12.7799 4.88892 12.2557 4.71506 11.7172 4.71505ZM8.43342 3.93197C7.74428 4.621 7.28935 5.50936 7.13305 6.47126C7.1367 6.50353 7.13861 6.53597 7.13876 6.56843V21.4384C7.13876 21.4613 7.13876 21.4827 7.1359 21.5056H16.2985C16.2967 21.4832 16.2958 21.4608 16.2957 21.4384V6.56843C16.2959 6.53597 16.2978 6.50353 16.3014 6.47126C16.1643 5.62741 15.7969 4.83776 15.2398 4.18933C14.6826 3.5409 13.9573 3.05884 13.1437 2.79626C12.3301 2.53368 11.4598 2.50077 10.6287 2.70116C9.79761 2.90155 9.03795 3.32746 8.43342 3.93197ZM18.079 6.50127C18.0808 6.52362 18.0818 6.54602 18.0819 6.56843V21.4384C18.0819 21.4613 18.0819 21.4827 18.079 21.5056H18.1476C18.6168 21.5056 19.0813 21.4132 19.5147 21.2336C19.9482 21.0541 20.342 20.7909 20.6737 20.4592C21.0055 20.1275 21.2686 19.7337 21.4481 19.3002C21.6277 18.8668 21.7201 18.4023 21.7201 17.9331V10.0737C21.7201 9.12625 21.3437 8.21758 20.6737 7.54762C20.0038 6.87765 19.0951 6.50127 18.1476 6.50127H18.079ZM5.2868 6.50127H5.3554C5.35361 6.52362 5.35266 6.54602 5.35254 6.56843V21.4384C5.35254 21.4613 5.35254 21.4827 5.3554 21.5056H5.2868C4.81766 21.5056 4.35312 21.4132 3.91969 21.2336C3.48626 21.0541 3.09243 20.7909 2.7607 20.4592C2.42897 20.1275 2.16582 19.7337 1.98629 19.3002C1.80676 18.8668 1.71436 18.4023 1.71436 17.9331L1.71436 10.0737C1.71436 9.12625 2.09074 8.21758 2.7607 7.54762C3.43067 6.87765 4.33933 6.50127 5.2868 6.50127Z"
      />
    </Icon>
  );
}
