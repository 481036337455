import { useSearch } from "@tanstack/react-router";
import { useMemo } from "react";

import { StatusWrapper } from "@/components/shared/status-wrapper";
import { cn } from "@/lib/utils";
import { useGetBooking } from "@/services/booking/hooks/use-get-booking";
import { BookingStatusEnum } from "@/services/booking/types/entity";
import { formatWordUnit } from "@/utils/function";

import { SectionContainer } from "./section-container";

export function PriceDetail(): React.JSX.Element {
  const search = useSearch({ from: "/checkout/" });
  const { data: bookingData, isPending } = useGetBooking(search.bookingId, {
    refetchInterval: 5000,
  });

  const payments = bookingData?.reservation.Payments;
  const passengers = bookingData?.reservation.Passengers;
  const tax = passengers?.[0].Ssrs[0]?.Tax;

  const groupedPassengers = useMemo(() => {
    if (!passengers) return {};

    return passengers.reduce<Record<string | number, number>>((obj, item) => {
      obj[item.Type] = obj[item.Type] ? obj[item.Type] + 1 : 1;
      return obj;
    }, {});
  }, [passengers]);

  const Adult = groupedPassengers["Adult"];
  const Child = groupedPassengers["Child"];
  const Infant = groupedPassengers["Infant"];

  const adultLabel = !!Adult && `${Adult} ${formatWordUnit("adult", Adult)}`;

  const childLabel =
    !!Child && `, ${Child} ${formatWordUnit("child", Child, "children")}`;

  const infantLabel =
    !!Infant && `, ${Infant} ${formatWordUnit("infants", Infant)}`;

  const price = bookingData?.booking.price ?? 0;
  const ticketPrice = bookingData?.booking?.ticketPrice ?? 0;
  const serviceFee = bookingData?.booking?.serviceFee ?? 0;
  const platformFee = bookingData?.booking?.platformFee ?? 0;

  const isTransactionNotReady =
    !price ||
    bookingData?.booking?.status !== BookingStatusEnum.Booking_RESERVED;

  return (
    <SectionContainer>
      <p className="text-base mb-3">Detail Harga</p>
      <StatusWrapper.Container
        isLoading={isPending}
        className={cn(isPending && "h-[240px]")}
      >
        <div className="flex flex-col gap-[5px]">
          <div className="flex flex-row justify-between items-center py-2">
            <p className="text-base">
              {adultLabel} {childLabel} {infantLabel}
            </p>
            {!isTransactionNotReady ? (
              <p className="text-base">
                {ticketPrice.toLocaleString("id", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </p>
            ) : (
              <p className="text-base animate-pulse">Loading price..</p>
            )}
          </div>
          <div className="flex flex-row justify-between items-center py-2">
            <p className="text-base">Pajak</p>
            <p className="text-base text-muted-foreground-secondary">
              {!tax ? "Included" : tax}
            </p>
          </div>
          <div className="pl-4 flex flex-row justify-between items-center py-2">
            <p className="text-base">Biaya Platform</p>
            <p
              className={cn(
                "text-base",
                isTransactionNotReady && "animate-pulse",
              )}
            >
              {isTransactionNotReady
                ? "Loading..."
                : `${platformFee.toLocaleString("id", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}`}
            </p>
          </div>
          <div className="pl-4 flex flex-row justify-between items-center py-2">
            <p className="text-base">Biaya Layanan</p>
            <p
              className={cn(
                "text-base",
                isTransactionNotReady && "animate-pulse",
              )}
            >
              {isTransactionNotReady
                ? "Loading..."
                : `${serviceFee.toLocaleString("id", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
            </p>
          </div>
          <div className="flex flex-row justify-between items-center py-2 pb-[13px] border-b">
            <p className="text-base">Bagasi</p>
            <p className="text-base">{passengers?.[0].Ssrs[0]?.SsrName}</p>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center mt-4">
          <p className="text-base text-primary">Total Harga</p>
          {!isTransactionNotReady ? (
            <p className="text-2xl text-primary">
              {payments?.[0].Currency}{" "}
              {price?.toLocaleString("id", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </p>
          ) : (
            <p className="text-2xl text-primary animate-pulse">
              Loading Price...
            </p>
          )}
        </div>
      </StatusWrapper.Container>
    </SectionContainer>
  );
}
