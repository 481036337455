import { useState } from "react";

import { BackOfficeOrderColumn } from "../constants/table-column";

export const useDetailDialog = () => {
  const [selectedValue, setSelectedValue] = useState<BackOfficeOrderColumn>();
  const [dialogOpen, setDialogOpen] = useState(false);

  const onClick = (data: BackOfficeOrderColumn) => {
    setSelectedValue(data);
  };

  return {
    selectedValue,
    onClick,
    dialogOpen,
    setDialogOpen,
  };
};
