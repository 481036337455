import dayjs from "dayjs";

import { StatusWrapper } from "@/components/shared/status-wrapper";
import { cn } from "@/lib/utils";
import { useViewBookingTicket } from "@/services/booking/hooks/use-view-booking-ticket";

interface ETicketProps {
  bookingId?: string;
}

export function ETicket({ bookingId }: ETicketProps): React.JSX.Element {
  const { data: bookingTicketData, isFetching } = useViewBookingTicket(
    bookingId as string,
  );

  const booking = bookingTicketData?.booking;
  const flightDetails = booking?.reservation.FlightDetails;
  const passengers = booking?.reservation.Passengers;
  const segments = booking?.reservation.Segments;
  const payments = booking?.reservation.Payments;
  const price = booking?.price ?? 0;

  console.log({ booking });

  return (
    <StatusWrapper.Container
      isLoading={isFetching}
      className={cn(isFetching && "h-[600px] p-2.5")}
    >
      <div
        style={{
          fontSize: 14,
          color: "#3F3F3F",
          padding: "10px 10px 14px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 0px",
          }}
        >
          <img
            src="https://i.imgur.com/UmUEv5M.png"
            width={80}
            height={28.55}
            style={{ objectFit: "contain" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <p style={{ fontSize: 16, fontWeight: 700 }}>Flight Itenerary</p>
            <p style={{ color: "#939393", textAlign: "end" }}>
              {bookingTicketData?.formattedTicketedTime}
            </p>
          </div>
        </div>
        <div
          style={{
            height: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            margin: "10px 0px",
          }}
        >
          <div style={{ flex: 1, backgroundColor: "#0065B3" }} />
          <div style={{ flex: 1, backgroundColor: "#A4CC39" }} />
          <div style={{ flex: 1, backgroundColor: "#0065B3" }} />
          <div style={{ flex: 1, backgroundColor: "#A4CC39" }} />
          <div style={{ flex: 1, backgroundColor: "#0065B3" }} />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            padding: "0px 0px 10px 0px",
            marginBottom: 10,
            borderBottom: "1px solid #EEEEEE",
          }}
        >
          <p
            style={{
              padding: "10px 0px",
            }}
          >
            <span style={{ color: "#939396" }}>Depart: </span>
            <span>
              {dayjs(flightDetails?.[0].DepartDate).format("ddd, DD MMM YYYY")}
            </span>
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 0px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img
                src={flightDetails?.[0].AirlineImageUrl}
                style={{ width: 80 }}
              />
              <p style={{ color: "#939393" }}>{segments?.[0].AirlineName}</p>
              <p style={{ fontSize: 16, fontWeight: 600 }}>
                {flightDetails?.[0].FlightNumber}
              </p>
              <p style={{ color: "#939393" }}>
                {flightDetails?.[0].Category} Class
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                fontSize: 16,
              }}
            >
              <p style={{ fontWeight: 700 }}>Booking Code</p>
              <p
                style={{
                  color: "#939393",
                  textTransform: "uppercase",
                }}
              >
                {booking?.reservation.BookingCode}
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 18,
              padding: "10px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 25,
                height: 66,
              }}
            >
              <div>
                <p style={{ fontSize: 16, fontWeight: 700 }}>
                  {flightDetails?.[0].DepartTime}
                </p>
                <p style={{ fontWeight: 500, color: "#939393" }}>
                  {dayjs(flightDetails?.[0].DepartDate).format("D MMM")}
                </p>
              </div>
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: -11,
                    width: "24px",
                    height: "24px",
                  }}
                >
                  <img src="https://i.imgur.com/WEftFXp.png" />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 20,
                    height: "calc(100% + 1rem)",
                    borderRightWidth: 2,
                    borderColor: "#0065B3",
                    borderStyle: "dashed",
                  }}
                />
              </div>
              <div>
                <p style={{ fontSize: 16, fontWeight: 700 }}>
                  {segments?.[0].Origin} ({flightDetails?.[0].Origin})
                </p>
                <p style={{ color: "#939393" }}>
                  {segments?.[0].OriginAirport}
                </p>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", gap: 25 }}>
              <div>
                <p style={{ fontSize: 16, fontWeight: 700 }}>
                  {flightDetails?.[0].ArriveTime}
                </p>
                <p style={{ fontWeight: 500, color: "#939393" }}>
                  {dayjs(flightDetails?.[0].ArriveDate).format("D MMM")}
                </p>
              </div>

              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: 8,
                    left: -11,
                    width: "24px",
                    height: "24px",
                  }}
                >
                  <img src="https://i.imgur.com/Ll2RoDY.png" />
                </div>
              </div>

              <div>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                  }}
                >
                  {segments?.[0].Destination} ({flightDetails?.[0].Destination})
                </p>
                <p style={{ color: "#939393" }}>
                  {segments?.[0].DestinationAirport}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            padding: "10px 0px",
          }}
        >
          <p
            style={{
              color: "#0065B3",
              fontSize: 16,
              fontWeight: 600,
              marginBottom: 10,
            }}
          >
            Detail Penumpang
          </p>
          <table
            style={{
              width: "100%",
              textAlign: "left",
              borderBottom: "1px solid #EEEEEE",
            }}
          >
            <tr
              style={{
                borderBottom: "1px solid #EEEEEE",
              }}
            >
              <th style={{ fontWeight: 600, padding: "5px 0px" }}></th>
              <th style={{ fontWeight: 600, padding: "5px 0px" }}>Nama</th>
              <th style={{ fontWeight: 600, padding: "5px 0px" }}>
                Nomor Tiket
              </th>
              <th style={{ fontWeight: 600, padding: "5px 0px" }}>Tipe</th>
            </tr>
            {passengers?.map((item) => (
              <tr>
                <td style={{ padding: "5px 0px" }}>{item.Index}.</td>
                <td style={{ padding: "5px 0px" }}>
                  {item?.Title} {item?.FirstName} {item?.LastName}
                </td>
                <td style={{ padding: "5px 0px" }}>
                  {item?.TicketNumber ?? "-"}
                </td>
                <td style={{ padding: "5px 0px" }}>{item?.Type}</td>
              </tr>
            ))}
          </table>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: 10, color: "#939393" }}>
              *include VAT and SSR
            </p>
            <p>
              <span style={{ color: "#939393", fontSize: 12 }}>Total: </span>
              <span style={{ fontSize: 16, fontWeight: 600, color: "#0065B3" }}>
                {" "}
                {payments?.[0].Currency}{" "}
                {price.toLocaleString("id", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </span>
            </p>
          </div>
        </div>
      </div>
    </StatusWrapper.Container>
  );
}
