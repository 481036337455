import { useQuery } from "@lezzcode/query";
import { AxiosError } from "axios";

import { BackOfficeUserRoleQueryFilters } from "@/features/backoffice-user-role/validators/query-filters";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeUserRoleApi } from "../api";
import { BackOfficeUserRoleQueryKeyFactory } from "../query-key.factory";
import { BackOfficeUserRoleDetail } from "../types";

export const useFindBackOfficeUserRole = (
  filters?: BackOfficeUserRoleQueryFilters,
) => {
  const api = new BackOfficeUserRoleApi();
  const queryKeyFactory = new BackOfficeUserRoleQueryKeyFactory();

  return useQuery<BaseApiResponse<BackOfficeUserRoleDetail[]>, AxiosError>({
    queryKey: queryKeyFactory.list(filters),
    queryFn: () => {
      return api.list({
        search: filters?.search,
      });
    },
  });
};
