import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { usePatchBackOfficeClassification } from "@/services/backoffice-classification/hooks/use-patch-backoffice-classification";

import { BackOfficeClassificationColumn } from "../constants/table-column";
import {
  EditClassificationSchema,
  editClassificationSchema,
} from "../validators";

export const useEditDialog = () => {
  const [selectedValue, setSelectedValue] =
    useState<BackOfficeClassificationColumn>();
  const [dialogOpen, setDialogOpen] = useState(false);

  const methods = useForm<EditClassificationSchema>({
    defaultValues: {
      airlines: [],
    },
    mode: "onChange",
    resolver: zodResolver(editClassificationSchema),
  });

  const { handleSubmit, reset } = methods;

  const { mutateAsync, isPending } = usePatchBackOfficeClassification();

  const onSubmit = useCallback(
    async (data: EditClassificationSchema) => {
      await mutateAsync(data);
      setDialogOpen(false);
    },
    [mutateAsync],
  );

  const onClick = (data: BackOfficeClassificationColumn) => {
    setSelectedValue({
      ...data,
      isActive: data.isActive ?? false,
    });
  };

  useEffect(() => {
    if (!selectedValue) return;
    reset({
      id: selectedValue.id,
      classId: selectedValue.classes[0].classId,
      isActive: selectedValue.isActive,
      positionId: selectedValue.position.id,
      airlines: selectedValue.airlines.map((item) => ({
        value: item.id,
      })),
    });
  }, [reset, selectedValue]);

  return {
    methods,
    handleSubmit,
    onSubmit,
    onClick,
    dialogOpen,
    setDialogOpen,
    isPending,
    selectedValue,
    setSelectedValue,
  };
};
