import { DialogProps } from "@radix-ui/react-dialog";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils";

interface BackofficeDialogProps extends DialogProps {
  title: React.JSX.Element | string;
  button: React.JSX.Element;
  triggerClassName?: string;
  contentClassName?: string;
  headerClassName?: string;
}

export function BackofficeDialog({
  button,
  title,
  children,
  triggerClassName,
  contentClassName,
  headerClassName,
  ...rest
}: BackofficeDialogProps): React.JSX.Element {
  return (
    <Dialog {...rest}>
      <DialogTrigger className={triggerClassName}>{button}</DialogTrigger>
      <DialogContent
        className={cn("gap-0 px-10 py-5 rounded-xl", contentClassName)}
      >
        <DialogHeader className={cn("mb-5", headerClassName)}>
          {typeof title === "object" && title}
          {typeof title === "string" && (
            <DialogTitle className="text-[28px] font-semibold">
              {title}
            </DialogTitle>
          )}
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  );
}
