import { useMutation, useQueryClient } from "@lezzcode/query";

import { EditCompanySchema } from "@/features/backoffice-company/validators";
import { toast } from "@/hooks/use-toast";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeCompanyApi } from "../api";
import { BackOfficeCompanyQueryKeyFactory } from "../query-key.factory";

export const usePatchBackOfficeCompany = () => {
  const api = new BackOfficeCompanyApi();
  const queryClient = useQueryClient();
  const queryKeyFactory = new BackOfficeCompanyQueryKeyFactory();

  return useMutation<BaseApiResponse<boolean>, Error, EditCompanySchema>({
    mutationFn: api.update,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeyFactory.lists(),
      });
    },
    onSuccess: async () => {
      toast({
        title: "Update perusahaan berhasil!",
        variant: "success",
      });
    },
    onError: async () => {
      await queryClient.cancelQueries({
        queryKey: queryKeyFactory.lists(),
      });
      toast({
        title: `Update perusahaan gagal!`,
        variant: "destructive",
      });
    },
  });
};
