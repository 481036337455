import {
  createFileRoute,
  useNavigate,
  useParams,
  useRouter,
} from "@tanstack/react-router";

import { BaseFooter } from "@/components/layout/footer/base-footer";
import { BaseNavHeader } from "@/components/layout/header/base-nav";
import { StatusWrapper } from "@/components/shared/status-wrapper";
import { Button } from "@/components/ui/button";
import { MyTicketDetail } from "@/features/flight-my-ticket/components/detail";
import { MyTicketsPassengers } from "@/features/flight-my-ticket/components/passengers";
import { AirlinesOverview } from "@/features/flight-ticket/components/overview/airlines-overview";
import { DurationOverview } from "@/features/flight-ticket/components/overview/duration-overview";
import { StatusOverview } from "@/features/flight-ticket/components/overview/status-overview";
import { DateOverview } from "@/features/flight-ticket/components/overview/time-overview";
import { RefundRescheduleInfo } from "@/features/flight-ticket/components/refund-reschedule-info";
import { useFormatBookingTicket } from "@/features/flight-ticket/hooks/use-format-booking-ticket";
import { cn } from "@/lib/utils";
import { useGetBooking } from "@/services/booking/hooks/use-get-booking";
import { BookingStatusEnum } from "@/services/booking/types/entity";
import { formatDuration } from "@/utils/function";

export const Route = createFileRoute("/my-tickets/$ticketId/")({
  component: Index,
});

function Index() {
  const navigate = useNavigate();
  const { history } = useRouter();
  const { ticketId } = useParams({ from: "/my-tickets/$ticketId/" });

  const { data: bookingData, isLoading } = useGetBooking(ticketId);
  const {
    flightDetails,
    passengers,
    departDate,
    departTime,
    arriveTime,
    duration,
    status,
    airlineName,
    origin,
    destination,
  } = useFormatBookingTicket({
    bookingData,
  });

  const isCorporate = bookingData?.booking.payer === "CORPORATE";
  const isContinuePayment =
    !isCorporate && status === BookingStatusEnum.RESERVED;

  return (
    <div className="flex flex-col h-screen bg-white">
      <BaseNavHeader title="Tiket Saya" />

      <div className="flex-grow">
        <StatusWrapper.Container
          className="h-20 flex flex-row justify-between p-4 pt-3 border-b"
          isLoading={isLoading}
        >
          <DurationOverview
            origin={origin}
            destination={destination}
            departTime={departTime}
            arriveTime={arriveTime}
            duration={formatDuration(duration)}
          />
          <div className="flex flex-col items-end">
            <DateOverview date={departDate} />
            <StatusOverview isCorporate={isCorporate} status={status} />
          </div>
        </StatusWrapper.Container>

        <StatusWrapper.Container
          isLoading={isLoading}
          className={cn("px-4 pt-2.5 pb-3 border-b", isLoading && "h-20")}
        >
          <AirlinesOverview
            className="p-0 border-b-0"
            airlineLogo={
              <img className="w-6" src={flightDetails?.[0].AirlineImageUrl} />
            }
            airlineName={`${airlineName} - ${flightDetails?.[0].Category}`}
            airplaneName={flightDetails?.[0].FlightNumber}
          />
        </StatusWrapper.Container>

        <StatusWrapper.Container
          isLoading={isLoading}
          className={cn(isLoading && "px-4 pt-2.5 pb-3 h-16 border-b")}
        >
          <MyTicketsPassengers
            isCorporate={isCorporate}
            passengers={passengers}
          />
        </StatusWrapper.Container>

        <RefundRescheduleInfo booking={bookingData} />
        <p className="text-xs text-center text-muted-foreground -mt-2">
          ( Maksimal 1 hari sebelum keberangkatan )
        </p>
      </div>

      <BaseFooter className="flex-row">
        <Button
          className="flex-1 text-lg h-12 text-white font-bold"
          variant="secondary"
          onClick={() => history.back()}
        >
          Kembali
        </Button>

        {!isContinuePayment && <MyTicketDetail booking={bookingData} />}

        {isContinuePayment && (
          <Button
            className="flex-1 h-full text-lg font-bold"
            onClick={() =>
              navigate({
                to: "/checkout",
                search: { bookingId: ticketId },
              })
            }
          >
            Lanjutkan Pembayaran
          </Button>
        )}
      </BaseFooter>
    </div>
  );
}
