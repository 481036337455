import { createFileRoute, Link } from "@tanstack/react-router";
import { useCallback, useMemo } from "react";

import { ExportBoldIcon } from "@/components/icons/export-bold";
import { Button } from "@/components/ui/button";
import { UserMenu } from "@/features/backoffice-auth/components/backoffice-user-menu";
import { InvoiceDetail } from "@/features/backoffice-invoice/components/invoice-detail";
import { useDownloadBackOfficeInvoice } from "@/services/backoffice-invoice/hooks/use-download-backoffice-invoice";
import { decodeFromBinary } from "@/utils/function";

export const Route = createFileRoute(
  "/_backoffice-other-layout/backoffice/invoices/$invoiceId/",
)({
  component: Index,
});

function Index() {
  const { mutateAsync } = useDownloadBackOfficeInvoice();
  const { invoiceId } = Route.useParams();

  const decodedInvoiceId = useMemo(() => {
    return decodeFromBinary(invoiceId);
  }, [invoiceId]);

  const onDownload = useCallback(
    async (invoiceId: string) => {
      await mutateAsync({ no: [invoiceId] });
    },
    [mutateAsync],
  );

  return (
    <div className="flex flex-col gap-5 p-5">
      <div className="flex gap-2.5 justify-end items-center">
        <Link to="/backoffice/invoices">
          <Button
            className="bg-transparent hover:bg-[#D34C4C]/10 h-10 w-10 rounded-full"
            size="icon"
          ></Button>
        </Link>
        <Button
          onClick={() => onDownload(decodedInvoiceId)}
          className="gap-2.5 h-11 px-[15px] py-2.5 text-base"
        >
          <ExportBoldIcon className="fill-white" />
          Download Invoice
        </Button>
        <UserMenu />
      </div>
      <InvoiceDetail />
    </div>
  );
}
