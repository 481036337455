import { api } from "@/lib/api";
import { BaseResponse } from "@/types";

import { NotificationEntity } from "./types/entity";

export class NotificationServiceApi {
  private baseUrl: string = "/notifications";

  findNotification = async () => {
    const { data } = await api.get<BaseResponse<NotificationEntity[]>>(
      this.baseUrl,
    );

    return data.data;
  };
}
