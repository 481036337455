import { useMemo } from "react";

import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";

import { StatusWrapper, StatusWrapperProps } from ".";
import { EmptyState } from "./empty-state";

interface ListProps extends StatusWrapperProps {
  rows?: number;
}

export function List({
  isLoading,
  rows,
  renderEmpty,
  className,
  classNames,
  children,
  ...rest
}: ListProps) {
  const loadingLists = useMemo(() => {
    return Array.from({ length: rows ?? 0 });
  }, [rows]);

  return (
    <StatusWrapper
      className={cn(!children && "justify-center items-center", className)}
      classNames={classNames}
      {...rest}
    >
      {isLoading &&
        loadingLists.map((_item, index) => (
          <Skeleton
            key={index}
            className={cn("w-full h-24 rounded-sm", classNames?.renderLoading)}
          />
        ))}
      {!isLoading && !children && <EmptyState renderEmpty={renderEmpty} />}
      {!isLoading && children}
    </StatusWrapper>
  );
}
