import { useQuery } from "@lezzcode/query";
import { AxiosError } from "axios";

import { BackOfficeOrderQueryFilters } from "@/features/backoffice-order/validators/query-filters";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeOrderApi } from "../api";
import { BackOfficeOrderQueryKeyFactory } from "../query-key.factory";
import { BackOfficeOrderDetail } from "../types";

export const useFindBackOfficeOrder = (
  filters?: BackOfficeOrderQueryFilters,
) => {
  const api = new BackOfficeOrderApi();
  const queryKeyFactory = new BackOfficeOrderQueryKeyFactory();

  return useQuery<BaseApiResponse<BackOfficeOrderDetail[]>, AxiosError>({
    queryKey: queryKeyFactory.list(filters),
    queryFn: () => {
      return api.list({
        from: filters?.from as string,
        to: filters?.to as string,
        search: filters?.search,
        status: filters?.status,
      });
    },
  });
};
