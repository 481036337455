import { HTMLAttributes } from "react";

import { Badge } from "@/components/ui/badge";
import { bookingStatusBackgroundColors } from "@/features/booking/utils";
import {
  corporateBookingStatusLabel,
  individualBookingStatusLabel,
} from "@/features/flight-my-ticket/utils";
import { cn } from "@/lib/utils";
import { BookingStatusEnum } from "@/services/booking/types/entity";

interface StatusOverviewProps extends HTMLAttributes<HTMLDivElement> {
  isCorporate?: boolean;
  status: BookingStatusEnum;
}

export function StatusOverview({
  isCorporate,
  status,
  className,
  ...rest
}: StatusOverviewProps): React.JSX.Element {
  const label = isCorporate
    ? corporateBookingStatusLabel[status]
    : individualBookingStatusLabel[status];

  const statusOverviewBackgroundColors = {
    ...bookingStatusBackgroundColors,
    Reserved: "#EF5350",
  };

  return (
    <Badge
      className={cn(
        "text-[13px] text-white px-[10px] py-[5px] rounded-[17px] mt-1",
        className,
      )}
      style={{
        backgroundColor: statusOverviewBackgroundColors[status],
      }}
      {...rest}
    >
      {label}
    </Badge>
  );
}
