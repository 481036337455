import { z } from "zod";

import { backOfficeQueryFiltersSchema } from "@/features/backoffice/validators";

export const backOfficeClassificationQueryFiltersSchema =
  backOfficeQueryFiltersSchema.pick({
    search: true,
  });

export type BackOfficeClassificationQueryFilters = z.infer<
  typeof backOfficeClassificationQueryFiltersSchema
>;
