import { z } from "zod";

export const baseCompanySchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1).max(50),
  isActive: z.boolean().default(false),
});

export const editCompanySchema = baseCompanySchema;
export const addCompanySchema = baseCompanySchema.omit({ id: true });

export type EditCompanySchema = z.infer<typeof baseCompanySchema>;
export type AddCompanySchema = z.infer<typeof addCompanySchema>;
