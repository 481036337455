import {
  createFileRoute,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";

import { BaseFooter } from "@/components/layout/footer/base-footer";
import { BaseNavHeader } from "@/components/layout/header/base-nav";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ApprovalList } from "@/features/notification/components/approval/approval-list";
import { TicketList } from "@/features/notification/components/ticket/ticket-list";

type SearchParams = {
  activeTab: "Ticket" | "Approval";
};

export const Route = createFileRoute("/notification/")({
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    return {
      activeTab: search.activeTab as SearchParams["activeTab"],
    };
  },
  component: Index,
});

function Index() {
  const navigate = useNavigate();
  const { activeTab } = useSearch({ from: "/notification/" });

  return (
    <div className="flex flex-col h-screen">
      <BaseNavHeader title="Notifikasi" />
      <Tabs
        defaultValue="Ticket"
        value={activeTab}
        onValueChange={(value) =>
          navigate({
            from: "/notification",
            search: { activeTab: value as SearchParams["activeTab"] },
          })
        }
        className="w-full mt-5"
      >
        <TabsList className="w-full px-[18px] gap-5">
          <TabsTrigger value="Ticket" className="flex-1 text-lg">
            Tiket
          </TabsTrigger>
          <TabsTrigger value="Approval" className="flex-1 text-lg">
            Approval
          </TabsTrigger>
        </TabsList>
        <TabsContent value="Ticket">
          <TicketList />
        </TabsContent>
        <TabsContent value="Approval">
          <ApprovalList />
        </TabsContent>
      </Tabs>
      <BaseFooter>
        <Button
          variant="secondary"
          className="w-full text-lg h-12 text-white font-bold"
          onClick={() => navigate({ to: "/" })}
        >
          Kembali
        </Button>
      </BaseFooter>
    </div>
  );
}
