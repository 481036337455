import { UserRound } from "lucide-react";

import { cn } from "@/lib/utils";

interface UnknownPassengerCardProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  title: string;
}

export function UnknownPassengerCard({
  title,
  className,
  onClick,
  ...rest
}: UnknownPassengerCardProps): React.JSX.Element {
  return (
    <div
      className={cn(
        "w-full h-16 flex items-center gap-2.5 border border-border rounded-lg py-1.5 px-4",
        onClick && "cursor-pointer",
        className,
      )}
      style={{ boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)" }}
      onClick={onClick}
      {...rest}
    >
      <UserRound className="h-8 w-8" />
      <p className="text-foreground text-xl">{title}</p>
    </div>
  );
}
