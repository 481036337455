import { z } from "zod";

export const baseInvoiceSchema = z.object({
  position: z.string().min(1).max(50),
  status: z.boolean().default(false),
});

export const editInvoiceSchema = baseInvoiceSchema;

export type EditInvoiceSchema = z.infer<typeof editInvoiceSchema>;

export const backOfficeInvoiceQuerySchema = z
  .object({
    search: z.string().optional(),
    status: z.string().optional(),
    company: z.string().optional(),
    payer: z.string().optional(),
    dateRange: z
      .object({
        from: z.date().optional(),
        to: z.date().optional(),
      })
      .optional(),
  })
  .refine(
    (data) =>
      (data.dateRange?.from && data.dateRange?.to) ||
      (!data.dateRange?.from && !data.dateRange?.to),
    {
      message: "need to specify both 'from' and 'to'",
      path: ["dateRange"],
    },
  );

export type BackOfficeInvoiceQuery = z.infer<
  typeof backOfficeInvoiceQuerySchema
>;
