import { z } from "zod";

export const baseClassificationSchema = z.object({
  id: z.string().min(1),
  positionId: z.string().min(1),
  classId: z.string().min(1),
  isActive: z.boolean().default(false),
  airlines: z
    .array(z.object({ value: z.number() }))
    .refine((data) => data.length > 0, {
      message: "Please select at least one airline",
    }),
});

export const editClassificationSchema = baseClassificationSchema;
export const addClassificationSchema = editClassificationSchema.omit({
  id: true,
});

export type EditClassificationSchema = z.infer<typeof editClassificationSchema>;
export type AddClassificationSchema = z.infer<typeof addClassificationSchema>;
