import type { IconProps } from "../ui/icon";
import { Icon } from "../ui/icon";

export function BellBoldIcon({
  className,
  size,
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size}>
      <path d="M17.8663 13.0862V11.1914C17.867 9.91087 17.3988 8.66749 16.5373 7.66237C15.6758 6.65725 14.4714 5.94915 13.1189 5.65258C13.1292 5.61584 13.1372 5.57859 13.1429 5.541V3.05262C13.1429 2.77345 13.0224 2.50571 12.8081 2.3083C12.5938 2.1109 12.3031 2 12 2C11.6969 2 11.4062 2.1109 11.1919 2.3083C10.9776 2.50571 10.8571 2.77345 10.8571 3.05262V5.541C10.8628 5.57859 10.8708 5.61584 10.8811 5.65258C9.52858 5.94915 8.32418 6.65725 7.46267 7.66237C6.60117 8.66749 6.13295 9.91087 6.13371 11.1914V13.0862C6.13371 15.5977 4 16.2251 4 17.4808C4 18.105 4 18.7366 4.61486 18.7366H19.3851C20 18.7366 20 18.105 20 17.4808C20 16.2251 17.8663 15.5977 17.8663 13.0862Z" />
      <path d="M8.36914 19.7892C8.67232 20.445 9.17816 21.0043 9.82373 21.3973C10.4693 21.7904 11.2261 22 12 22C12.7739 22 13.5307 21.7904 14.1763 21.3973C14.8218 21.0043 15.3277 20.445 15.6309 19.7892H8.36914Z" />
    </Icon>
  );
}
