import { api } from "@/lib/api";

import { BaseApiResponse } from "../shared/types";
import { BackOfficeProfileEntity } from "./types/entity";

export class BackOfficeApi {
  private baseUrl: string = "/backoffice";

  profile = async (): Promise<BaseApiResponse<BackOfficeProfileEntity>> => {
    const { data } = await api.get<BaseApiResponse<BackOfficeProfileEntity>>(
      `${this.baseUrl}/profile`,
    );

    return data;
  };
}
