import { Link } from "@tanstack/react-router";

import { Button } from "@/components/ui/button";
import { useFlightStore } from "@/stores/use-flight-store";

import { TicketDetail } from "./ticket-detail";

export function SubmitTicket(): React.JSX.Element | null {
  const { ticket, setTicket } = useFlightStore();

  if (!ticket) return null;

  return (
    <TicketDetail
      open={Boolean(ticket)}
      onOpenChange={(open) => {
        if (!open) setTicket(null);
      }}
      footer={
        <div className="flex justify-between gap-4">
          <div className="flex flex-col">
            <p className="text-muted-foreground">
              Total Harga untuk 1 Penumpang
            </p>
            <p className="text-2xl text-primary">
              Rp.{" "}
              {ticket.Fare.toLocaleString("id", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </p>
          </div>
          <Link
            to={"/book/plane/list/$listId"}
            search
            params={{ listId: encodeURIComponent(ticket.Id) }}
          >
            <Button className="h-[53px] w-[140px] text-2xl text-white">
              Ajukan
            </Button>
          </Link>
        </div>
      }
    />
  );
}
