"use client";

import { useTablePagination } from "@lezztable-demo/react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { cn } from "@/lib/utils";

import {
  Pagination,
  PaginationButton,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from "../../ui/pagination";

type BaseTableProps<T> = {
  data: T[];
  columns: ColumnDef<T, unknown>[];
};

export function BaseTable<T>({ data, columns }: Readonly<BaseTableProps<T>>) {
  const PAGINATION_CONFIG = {
    SKIP: 0,
    LIMIT: 10,
    MAX_DISPLAY: 3,
  };

  const totalRecords = useMemo(() => {
    return Array.isArray(data) ? data.length : 0;
  }, [data]);

  const { activePageIndex, onChangePage, totalPage } = useTablePagination({
    skip: 0,
    limit: 10,
    totalRecords,
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      pagination: {
        pageIndex: activePageIndex,
        pageSize: PAGINATION_CONFIG.LIMIT,
      },
    },
  });

  return (
    <div className="w-full">
      <div className="rounded-md border lg:w-[75vw] 2xl:w-full">
        <Table>
          <TableHeader className="bg-[#F9F9F9]">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  className={row.index % 2 === 1 ? "bg-[#F9F9F9]" : ""}
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          Showing{" "}
          <span className="font-bold">{`${activePageIndex + 1}-${totalPage}`}</span>{" "}
          of <span className="font-bold">{totalRecords}</span>
        </div>
        <div className="space-x-2">
          <Pagination>
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious
                  onClick={() => {
                    onChangePage(activePageIndex - 1);
                  }}
                  disabled={!table.getCanPreviousPage()}
                />
              </PaginationItem>
              {Array.from({ length: totalPage }, (_, index) => {
                const isWithinRange =
                  index < PAGINATION_CONFIG.MAX_DISPLAY ||
                  (index >= activePageIndex - 1 &&
                    index <= activePageIndex + 1);

                const isEllipsisPosition =
                  index === PAGINATION_CONFIG.MAX_DISPLAY ||
                  index === activePageIndex - 2 ||
                  index === activePageIndex + 2;

                if (isWithinRange) {
                  return (
                    <PaginationItem key={index}>
                      <PaginationButton
                        onClick={() => onChangePage(index)}
                        isActive={activePageIndex === index}
                        className={cn(
                          activePageIndex === index &&
                            "bg-primary text-white hover:bg-primary/80 hover:text-white",
                        )}
                      >
                        {index + 1}
                      </PaginationButton>
                    </PaginationItem>
                  );
                }

                if (isEllipsisPosition) {
                  return (
                    <PaginationItem key={index}>
                      <PaginationEllipsis />
                    </PaginationItem>
                  );
                }

                return null;
              })}
              <PaginationItem>
                <PaginationNext
                  onClick={() => {
                    onChangePage(activePageIndex + 1);
                  }}
                  disabled={!table.getCanNextPage()}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        </div>
      </div>
    </div>
  );
}
