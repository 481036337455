import dayjs from "dayjs";
import get from "lodash.get";

import { GroupItem } from "@/types";

import { ONE_MILLION } from "./constant";

// kalau mau multilevel => categoryPaths : string[] ( recursive )
export function groupItems<T extends Record<string, unknown>>(
  categoryPath: string,
  items: T[],
): GroupItem<T>[] {
  return items.reduce<GroupItem<T>[]>((result, item) => {
    const curr = [...result];

    const matchedIndex = curr.findIndex(
      (groupedItem) => groupedItem.category === get(item, categoryPath),
    );

    if (matchedIndex !== -1) {
      curr[matchedIndex].items?.push(item);
    } else {
      curr.push({ category: get(item, categoryPath), items: [item] });
    }

    return curr;
  }, []);
}

export function formatWordUnit(
  word: string,
  unit: number,
  pluralForm?: string,
) {
  if (unit < 2) return word;
  if (pluralForm) return pluralForm;
  return `${word}s`;
}

export function formatDuration(timeString?: string): string {
  if (!timeString) return "";

  const timeArray = timeString.split(":").map((item) => {
    if (item[0] === "0") return item.slice(1, 3);
    return item;
  });

  return `${timeArray[0]}h ${timeArray[1] ? `${timeArray[1]}m` : ""}`;
}

export function formatMillion(value: number, precision?: number): string {
  return (value / ONE_MILLION).toFixed(precision ?? 2);
}

export function downloadFile(
  data: Blob | MediaSource,
  fileName: string,
): unknown {
  const url = URL.createObjectURL(data);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  a.remove();
  setTimeout(() => window.URL.revokeObjectURL(url), 100);

  return;
}

export function getDatesInRange(startDate: Date, endDate: Date) {
  const date = new Date(startDate.getTime());
  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

export function getPreviousYears(range: number) {
  const yearsList = [];
  const currentYear = dayjs().year();

  for (let year = currentYear; year >= currentYear - range; year--) {
    yearsList.push(year);
  }

  return yearsList;
}

export function queryTransformer(searchObj: object) {
  return Object.entries(searchObj).reduce((acc, [key, value]) => {
    try {
      const parsedValue = JSON.parse(value);

      return { ...acc, [key]: parsedValue };
    } catch (error) {
      return { ...acc, [key]: value };
    }
  }, {});
}

export function decodeFromBinary(str: string): string {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );
}

export function encodeToBinary(str: string): string {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (_, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    }),
  );
}

export function filterNonNull(obj?: object) {
  if (!obj) return {};
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => v));
}

export function getLezztableURL(): string | undefined {
  if (import.meta.env.VITE_ENVIRONMENT === "production")
    return import.meta.env.VITE_BACKEND_URL;

  return;
}
