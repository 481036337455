import "dayjs/locale/id";

import dayjs from "dayjs";
dayjs.locale("id");

import {
  LezzCodeQueryClient,
  LezzCodeQueryClientProvider,
} from "@lezzcode/query";
import { createRootRoute, Outlet, redirect } from "@tanstack/react-router";
import React, { Suspense } from "react";

import { Toaster } from "@/components/ui/toaster";
import { toast } from "@/hooks/use-toast";
import { backOfficeRouteAliases } from "@/services/backoffice-module/types/route-aliases";
import { UserAuthServiceApi } from "@/services/user-auth/api";

const queryClient = new LezzCodeQueryClient();

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

export const Route = createRootRoute({
  component: () => {
    return (
      <>
        <LezzCodeQueryClientProvider client={queryClient}>
          <Outlet />
        </LezzCodeQueryClientProvider>
        <Suspense>
          <TanStackRouterDevtools />
        </Suspense>
        <Toaster />
      </>
    );
  },
  beforeLoad: async ({ location }) => {
    const userAuthService = new UserAuthServiceApi();

    if (
      location.pathname.includes("/redirect") ||
      location.pathname.includes("/under-maintenance")
    ) {
      return;
    }

    if (location.pathname.includes("/backoffice")) {
      if (!location.pathname.includes("/backoffice/sign-in")) {
        try {
          const { data } = await userAuthService.backOfficeProfile();
          if (data.role.name === "SUPERADMIN") return;

          const route = backOfficeRouteAliases.find((item) =>
            location.pathname.includes(item.route),
          );
          const isValidRoute = data.role.RoleAccess.some((role) => {
            if (role.active && route?.name.includes(role.access.name))
              return true;
          });

          if (isValidRoute) return;

          toast({
            title: "Anda tidak memiliki akses ke halaman ini",
            variant: "destructive",
          });
        } catch (error) {
          userAuthService.logout();
          throw redirect({ to: "/backoffice/sign-in" });
        }
      }
      return;
    }

    try {
      await userAuthService.profile();
    } catch (error) {
      throw redirect({ to: "/under-maintenance" });
    }
  },
});
