import { CircleUserRound } from "lucide-react";

import { PassengerEntity } from "@/services/flight-passenger/types";

type ApprovalPassengerProps = PassengerEntity;

export function ApprovalPassenger({
  IdNumber,
  FirstName,
  LastName,
}: ApprovalPassengerProps): React.JSX.Element {
  return (
    <div className="flex flex-row gap-[15px] items-center px-4 py-3 border-b">
      <CircleUserRound
        size={32}
        className="stroke-muted-foreground-secondary"
      />
      <div className="flex flex-col">
        <p className="text-xl font-semibold">{`${FirstName} ${LastName}`}</p>
        <p className="text-sm text-muted-foreground-secondary">{IdNumber}</p>
      </div>
    </div>
  );
}
