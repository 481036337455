import { z } from "zod";

export const baseAccessModuleSchema = z.object({
  id: z.string().min(1),
  accessId: z.string().min(1),
  roleId: z.string().min(1),
  active: z.boolean().default(false),
});

export const editAccessModuleSchema = baseAccessModuleSchema;
export const addAccessModuleSchema = editAccessModuleSchema.omit({ id: true });

export type EditAccessModuleSchema = z.infer<typeof editAccessModuleSchema>;
export type AddAccessModuleSchema = z.infer<typeof addAccessModuleSchema>;
