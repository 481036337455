import qs from "qs";

import {
  AddCompanySchema,
  addCompanySchema,
  EditCompanySchema,
  editCompanySchema,
} from "@/features/backoffice-company/validators";
import { api } from "@/lib/api";
import { filterNonNull } from "@/utils/function";

import { BaseApiResponse } from "../shared/types";
import { BackOfficeCompanyDetail } from "./types";
import { GetBackOfficeCompanyListDto } from "./types/dto";

export class BackOfficeCompanyApi {
  async list(
    dto?: GetBackOfficeCompanyListDto,
  ): Promise<BaseApiResponse<BackOfficeCompanyDetail[]>> {
    const strDto = qs.stringify(filterNonNull(dto), {
      encode: false,
    });
    const { data } = await api.get<BaseApiResponse<BackOfficeCompanyDetail[]>>(
      `/backoffice/companies?${strDto}`,
    );

    return data;
  }

  update = async (
    dto: EditCompanySchema,
  ): Promise<BaseApiResponse<boolean>> => {
    const parsed = editCompanySchema.parse(dto);

    const data = await api.patch<BaseApiResponse<boolean>>(
      `/backoffice/companies/${dto.id}`,
      parsed,
    );

    return data.data;
  };

  add = async (dto: AddCompanySchema): Promise<BaseApiResponse<boolean>> => {
    const parsed = addCompanySchema.parse(dto);

    const data = await api.post<BaseApiResponse<boolean>>(
      `/backoffice/companies`,
      parsed,
    );

    return data.data;
  };
}
