import { ColumnDef } from "@tanstack/react-table";

import { Switch } from "@/components/ui/switch";
import { BackOfficeModuleDetail } from "@/services/backoffice-module/types";

import { EditDialog } from "../components/edit-dialog";

export type BackOfficeModuleColumn = BackOfficeModuleDetail;

export const columns: ColumnDef<BackOfficeModuleColumn>[] = [
  {
    id: "no",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">NO</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">{row.index + 1}</div>
    ),
  },
  {
    accessorKey: "name",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">NAMA MODUL</p>
      );
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.name ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "isActive",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">STATUS</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        <Switch checked={row.original.active ?? "-"} />
      </div>
    ),
  },
  {
    accessorKey: "action",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">ACTION</p>;
    },
    enableHiding: false,
    cell: ({ row, ...props }) => {
      return <EditDialog row={row} {...props} />;
    },
  },
];
