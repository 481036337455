import { useQuery } from "@lezzcode/query";
import { AxiosError } from "axios";

import { BackOfficeClassificationQueryFilters } from "@/features/backoffice-classification/validators/query-filters";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeClassificationApi } from "../api";
import { BackOfficeClassificationQueryKeyFactory } from "../query-key.factory";
import { BackOfficeClassificationDetail } from "../types";

export const useFindBackOfficeClassification = (
  filters?: BackOfficeClassificationQueryFilters,
) => {
  const api = new BackOfficeClassificationApi();
  const queryKeyFactory = new BackOfficeClassificationQueryKeyFactory();

  return useQuery<
    BaseApiResponse<BackOfficeClassificationDetail[]>,
    AxiosError
  >({
    queryKey: queryKeyFactory.list(filters),
    queryFn: () => {
      return api.list({
        search: filters?.search,
      });
    },
  });
};
