"use client";

import { useNavigate } from "@tanstack/react-router";
import { LogOutIcon } from "lucide-react";
import { type JSX } from "react";

import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuthLogout } from "@/features/backoffice-auth/hooks/use-auth-logout";
import { useFindBackOfficeProfile } from "@/services/backoffice/hooks/use-find-backoffice-profile";

function UserMenu(): JSX.Element {
  const navigate = useNavigate({ from: "" });
  const { onLogout } = useAuthLogout();

  const { data } = useFindBackOfficeProfile();

  const handleLogout = async () => {
    onLogout();
    navigate({
      to: "/backoffice/sign-in",
      params: true,
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="cursor-pointer">
        <Avatar>
          <AvatarFallback className="uppercase bg-primary text-white">
            <img src="/brand-2.png" />
          </AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56">
        <DropdownMenuLabel>{`${data?.data.firstName} ${data?.data.lastName}`}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={handleLogout}>
          <LogOutIcon
            className="fill-transparent stroke-foreground mr-2"
            size={20}
          />
          <span>Log out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
export { UserMenu };
