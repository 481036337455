import { useNavigate, useSearch } from "@tanstack/react-router";
import dayjs from "dayjs";
import { useMemo, useState } from "react";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import { FIVE_DAYS_IN_MILLISECONDS } from "@/utils/constant";
import { getDatesInRange } from "@/utils/function";

interface DateRangeProps {
  onDateChange: (changed: boolean) => void;
}

export function DateRange({ onDateChange }: DateRangeProps): React.JSX.Element {
  const search = useSearch({ from: "/book/plane/list/" });
  const navigate = useNavigate({ from: "/book/plane/list" });

  const [initialDate] = useState<Date>(new Date(search.DepartDate));

  const activeDate = dayjs(search.DepartDate).format("YYYY-MM-DD");

  const dateOptions = useMemo(() => {
    const currentDate = new Date();

    let fiveDaysBefore = new Date(
      initialDate.getTime() - FIVE_DAYS_IN_MILLISECONDS,
    );

    const fiveDaysAfter = new Date(
      initialDate.getTime() + FIVE_DAYS_IN_MILLISECONDS,
    );

    if (fiveDaysBefore < currentDate) {
      fiveDaysBefore = currentDate;
    }

    return getDatesInRange(fiveDaysBefore, fiveDaysAfter);
  }, [initialDate]);

  return (
    <div className="py-[5.5px] bg-background">
      <Carousel>
        <CarouselContent>
          {dateOptions.map((item, index) => {
            const departDate = dayjs(item).format("YYYY-MM-DD");
            const isActive = departDate === activeDate;

            return (
              <CarouselItem key={index} className="basis-[117px]">
                <div
                  className={cn(
                    "relative flex flex-col items-center p-1 rounded-lg cursor-pointer",
                    isActive && "bg-primary",
                  )}
                  onClick={() => {
                    navigate({
                      search: {
                        ...search,
                        DepartDate: departDate,
                      },
                    });

                    onDateChange(true);
                  }}
                >
                  <Separator
                    orientation="vertical"
                    className="absolute left-[-0.5rem] bg-muted-foreground/25"
                  />
                  <p
                    className={cn(
                      "text-lg text-[#3F3F3F]",
                      isActive && "text-white",
                    )}
                  >
                    {dayjs(item).format("ddd")}
                  </p>
                  <p
                    className={cn(
                      "text-sm text-muted-foreground-secondary",
                      isActive && "text-white",
                    )}
                  >
                    {dayjs(item).format("DD MMM YYYY")}
                  </p>
                </div>
              </CarouselItem>
            );
          })}
        </CarouselContent>
      </Carousel>
    </div>
  );
}
