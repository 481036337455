import qs from "qs";

import {
  EditOrderSchema,
  editOrderSchema,
} from "@/features/backoffice-order/validators/edit-order";
import { api } from "@/lib/api";
import { downloadFile, filterNonNull } from "@/utils/function";

import { BaseApiResponse } from "../shared/types";
import { BackOfficeOrderDetail } from "./types";
import {
  GetBackOfficeOrderExportDto,
  GetBackOfficeOrderListDto,
} from "./types/dto";

export class BackOfficeOrderApi {
  async list(
    dto?: GetBackOfficeOrderListDto,
  ): Promise<BaseApiResponse<BackOfficeOrderDetail[]>> {
    const strDto = qs.stringify(filterNonNull(dto), {
      encode: false,
    });
    const { data } = await api.get<BaseApiResponse<BackOfficeOrderDetail[]>>(
      `/backoffice/bookings?${strDto}`,
    );

    return data;
  }

  update = async (dto: EditOrderSchema): Promise<BaseApiResponse<boolean>> => {
    const parsed = editOrderSchema.parse(dto);

    const data = await api.patch<BaseApiResponse<boolean>>(
      `/backoffice/bookings/${dto.id}`,
      parsed,
    );

    return data.data;
  };

  export = async (
    dto?: GetBackOfficeOrderExportDto,
  ): Promise<BaseApiResponse<boolean>> => {
    const strDto = qs.stringify(filterNonNull(dto), {
      encode: false,
    });
    const data = await api.get(`/backoffice/bookings/exports/xlsx?${strDto}`, {
      responseType: "blob",
    });

    downloadFile(data.data, `order-${new Date().toISOString()}.xlsx`);

    return data.data;
  };
}
