import qs from "qs";

import {
  AddPositionSchema,
  addPositionSchema,
  EditPositionSchema,
  editPositionSchema,
} from "@/features/backoffice-position/validators";
import { api } from "@/lib/api";
import { filterNonNull } from "@/utils/function";

import { BaseApiResponse } from "../shared/types";
import { BackOfficePositionDetail } from "./types";
import { GetBackOfficePositionListDto } from "./types/dto";

export class BackOfficePositionApi {
  async list(
    dto?: GetBackOfficePositionListDto,
  ): Promise<BaseApiResponse<BackOfficePositionDetail[]>> {
    const strDto = qs.stringify(filterNonNull(dto), {
      encode: false,
    });
    const { data } = await api.get<BaseApiResponse<BackOfficePositionDetail[]>>(
      `/backoffice/positions?${strDto}`,
    );

    return data;
  }

  update = async (
    dto: EditPositionSchema,
  ): Promise<BaseApiResponse<boolean>> => {
    const parsed = editPositionSchema.parse(dto);

    const data = await api.patch<BaseApiResponse<boolean>>(
      `/backoffice/positions/${dto.id}`,
      parsed,
    );

    return data.data;
  };

  add = async (dto: AddPositionSchema): Promise<BaseApiResponse<boolean>> => {
    const parsed = addPositionSchema.parse(dto);

    const data = await api.post<BaseApiResponse<boolean>>(
      `/backoffice/positions`,
      parsed,
    );

    return data.data;
  };
}
