import { DialogClose, DialogProps } from "@radix-ui/react-dialog";
import { PlusIcon } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { BackofficeDialog } from "@/features/backoffice/components/backoffice-dialog";
import { useFindBackOfficeAirline } from "@/services/backoffice-airline/hooks/use-find-backoffice-airline";
import { useFindBackOfficeClass } from "@/services/backoffice-class/hooks/use-find-backoffice-class";
import { useFindBackOfficePosition } from "@/services/backoffice-position/hooks/use-find-backoffice-position";

import { useAddDialog } from "../hooks/use-add-dialog";

interface AddDialogProps extends DialogProps {}

export function AddDialog({ ...props }: AddDialogProps) {
  const {
    methods,
    handleSubmit,
    onSubmit,
    dialogOpen,
    setDialogOpen,
    isPending,
  } = useAddDialog();

  const { data: dataClasses } = useFindBackOfficeClass();

  const { data: dataPositions } = useFindBackOfficePosition();

  const { data: dataAirlines } = useFindBackOfficeAirline();

  return (
    <BackofficeDialog
      title="Tambah Klasifikasi"
      button={
        <Button className="gap-2.5 h-11 px-[15px] py-2.5 text-base">
          <PlusIcon className="w-[22px] fill-white" />
          Tambah
        </Button>
      }
      triggerClassName="w-fit"
      open={dialogOpen}
      onOpenChange={setDialogOpen}
      {...props}
    >
      <div className="space-y-4">
        <Form {...methods}>
          <form
            className="flex flex-col gap-4 max-h-[50vh] overflow-auto"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-thumb-slate-500/40 scrollbar-track-slate-200 overflow-auto">
              <FormField
                control={methods.control}
                name="positionId"
                render={({ field }) => (
                  <FormItem className="mb-4">
                    <FormLabel className="font-bold text-md">Posisi</FormLabel>
                    <FormControl>
                      <Select
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <SelectTrigger className="flex w-full">
                          <SelectValue placeholder="Pilih Jabatan" />
                        </SelectTrigger>
                        <SelectContent className="max-w-[410px]">
                          {dataPositions?.data.map((position) => (
                            <SelectItem key={position.id} value={position.id}>
                              {position.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={methods.control}
                name="classId"
                render={({ field }) => (
                  <FormItem className="mb-4">
                    <FormLabel className="font-bold text-md">Kelas</FormLabel>
                    <FormControl>
                      <Select
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <SelectTrigger className="flex w-full">
                          <SelectValue placeholder="Pilih Kelas" />
                        </SelectTrigger>
                        <SelectContent className="max-w-[410px]">
                          {dataClasses?.data.map((classItem) => (
                            <SelectItem key={classItem.id} value={classItem.id}>
                              {classItem.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={methods.control}
                name="airlines"
                render={() => {
                  return (
                    <FormItem>
                      <FormLabel className="font-bold text-md">
                        Maskapai
                      </FormLabel>
                      {dataAirlines?.data.map((item) => (
                        <FormField
                          key={item.id}
                          control={methods.control}
                          name="airlines"
                          render={({ field }) => (
                            <FormItem
                              key={item.id}
                              className="flex flex-row items-start space-x-3 space-y-0"
                            >
                              <FormControl>
                                <Checkbox
                                  checked={field.value?.some(
                                    (value) => value.value === item.id,
                                  )}
                                  onCheckedChange={(checked) => {
                                    return checked
                                      ? field.onChange([
                                          ...field.value,
                                          { value: item.id },
                                        ])
                                      : field.onChange(
                                          field.value?.filter(
                                            (value) => value.value !== item.id,
                                          ),
                                        );
                                  }}
                                />
                              </FormControl>
                              <FormLabel className="text-sm font-normal">
                                {item.name}
                              </FormLabel>
                            </FormItem>
                          )}
                        />
                      ))}
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <FormField
                control={methods.control}
                name="isActive"
                render={({ field }) => (
                  <FormItem className="mb-4">
                    <FormLabel className="font-bold text-md">Status</FormLabel>
                    <FormControl className="block">
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex justify-between items-center space-x-2 mt-2">
              <DialogClose asChild>
                <Button
                  type="button"
                  variant="destructive"
                  className="w-full rounded-2xl text-white"
                >
                  Kembali
                </Button>
              </DialogClose>
              <Button
                type="submit"
                isLoading={isPending}
                disabled={!methods.formState.isValid || isPending}
                variant="default"
                className="w-full rounded-2xl"
              >
                Tambah
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </BackofficeDialog>
  );
}
