import { z } from "zod";

export const baseClassSchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1).max(50),
  isActive: z.boolean().default(false),
});

export const editClassSchema = baseClassSchema;
export const addClassSchema = editClassSchema.omit({ id: true });

export type EditClassSchema = z.infer<typeof editClassSchema>;
export type AddClassSchema = z.infer<typeof addClassSchema>;
