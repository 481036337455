import { HeadlessDialog } from "@/components/headless-ui/dialog";
import { Form, FormSchema } from "@/lezzform/_generated/add-passenger";

interface EditPassengerDialogProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => unknown;
  defaultValues?: FormSchema;
  onSubmit?: (values: FormSchema) => unknown;
}

export function EditPassengerDialog({
  onOpenChange,
  open,
  defaultValues,
  onSubmit,
}: EditPassengerDialogProps) {
  return (
    <HeadlessDialog
      open={open}
      onClose={() => onOpenChange && onOpenChange(false)}
      title="Ubah Penumpang"
    >
      <Form
        key={`EditPassengerDialog-${open}`}
        onAction={{
          cancelButton: () => onOpenChange && onOpenChange(false),
        }}
        defaultValues={defaultValues}
        onSubmit={(form, values) => {
          form.reset({});
          onSubmit && onSubmit(values);
          onOpenChange && onOpenChange(false);
        }}
      />
    </HeadlessDialog>
  );
}
