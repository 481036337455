import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";

import * as utils from "@/lib/utils";
import { BackOfficeOrderDetail } from "@/services/backoffice-order/types";

import { DetailDialog } from "../components/detail-dialog";
import { EditDialog } from "../components/edit-dialog";
import { StatusBadgeOrder } from "../components/status-badge";

export type BackOfficeOrderColumn = Omit<
  BackOfficeOrderDetail,
  "userId" | "pnrId" | "payer" | "createdAt" | "PaymentStatus"
>;

export const columns: ColumnDef<BackOfficeOrderColumn>[] = [
  {
    id: "no",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">NO</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">{row.index + 1}</div>
    ),
  },
  {
    accessorKey: "Passengers",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">NAMA</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.Passengers.map(
          (passenger) => `${passenger.FirstName} ${passenger.LastName}`,
        ).join(",")}
      </div>
    ),
  },
  {
    accessorKey: "departTime",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">TANGGAL PERGI</p>
      );
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.departTime
          ? `${dayjs(row.original.departTime).format("DD-MM-YYYY")}`
          : "-"}
      </div>
    ),
  },
  {
    accessorKey: "code",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">KODE BOOKING</p>
      );
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.code ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "departArriveTime",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">
          BERANGKAT - SAMPAI
        </p>
      );
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {`${dayjs(row.original.departTime).format("HH:mm")} - ${dayjs(row.original.arriveTime).format("HH:mm")}`}
      </div>
    ),
  },
  {
    accessorKey: "originDestination",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">ASAL - TUJUAN</p>
      );
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {`${row.original.origin} - ${row.original.destination}`}
      </div>
    ),
  },
  {
    accessorKey: "price",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">HARGA TIKET</p>
      );
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {utils.formatToRupiah(row.original.price)}
      </div>
    ),
  },
  {
    id: "status",
    accessorKey: "status",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">STATUS</p>;
    },
    cell: ({ row }) => <StatusBadgeOrder status={row.original.status} />,
  },
  {
    accessorKey: "action",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">ACTION</p>;
    },
    enableHiding: false,
    cell: ({ row, ...props }) => {
      return (
        <div className="flex space-x-2">
          <DetailDialog row={row} {...props} />
          <EditDialog row={row} {...props} />
        </div>
      );
    },
  },
];
