import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";

import { StatusWrapper, StatusWrapperProps } from ".";
import { EmptyState } from "./empty-state";

type ContainerProps = StatusWrapperProps;

export function Container({
  isLoading,
  renderEmpty,
  className,
  classNames,
  children,
  ...rest
}: ContainerProps) {
  return (
    <StatusWrapper
      className={cn(!children && "justify-center items-center", className)}
      classNames={classNames}
      {...rest}
    >
      {isLoading && (
        <Skeleton className={cn("w-full h-full", classNames?.renderLoading)} />
      )}
      {!isLoading && !children && <EmptyState renderEmpty={renderEmpty} />}
      {!isLoading && children}
    </StatusWrapper>
  );
}
