"use client";

import { Icon, type IconProps } from "../ui/icon";

export function TimeFillIcon({
  className,
  size,
  ...props
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size} {...props}>
      <path d="M8.72184 14.6663C5.03994 14.6663 2.05518 11.6815 2.05518 7.99967C2.05518 4.31777 5.03994 1.33301 8.72184 1.33301C12.4037 1.33301 15.3885 4.31777 15.3885 7.99967C15.3885 11.6815 12.4037 14.6663 8.72184 14.6663ZM9.38851 7.99967V4.66634H8.05518V9.33301H12.0552V7.99967H9.38851Z" />
    </Icon>
  );
}
