import { useNavigate } from "@tanstack/react-router";
import { ChevronRight } from "lucide-react";

import { Badge } from "@/components/ui/badge";
import { bookingStatusBackgroundColors } from "@/features/booking/utils";
import { AirlinesOverview } from "@/features/flight-ticket/components/overview/airlines-overview";
import { DurationOverview } from "@/features/flight-ticket/components/overview/duration-overview";
import { DateOverview } from "@/features/flight-ticket/components/overview/time-overview";
import { BookingEntity } from "@/services/booking/types/entity";
import { formatDuration } from "@/utils/function";

import { useFormatBookingTicket } from "../hooks/use-format-booking-ticket";

type PreviewCardProps = BookingEntity;

export function PreviewCard({
  booking,
  reservation,
}: PreviewCardProps): React.JSX.Element {
  const navigate = useNavigate();
  const {
    flightDetails,
    departDate,
    departTime,
    arriveTime,
    duration,
    status,
    statusLabel,
    airlineName,
    origin,
    destination,
  } = useFormatBookingTicket({
    bookingData: { booking, reservation },
  });

  return (
    <div
      key={booking.id}
      className="w-full flex flex-col gap-2.5 p-[15px] border rounded-xl shadow cursor-pointer"
      onClick={() =>
        navigate({
          to: "/my-tickets/$ticketId",
          params: { ticketId: booking.id },
        })
      }
    >
      <div className="flex flex-row justify-between items-center">
        <DateOverview date={departDate} />
        <Badge
          className="text-[13px] text-white px-[10px] py-[5px] rounded-[17px]"
          style={{
            backgroundColor: bookingStatusBackgroundColors[status],
          }}
        >
          {statusLabel}
        </Badge>
      </div>
      <DurationOverview
        origin={origin}
        destination={destination}
        departTime={departTime}
        arriveTime={arriveTime}
        duration={formatDuration(duration)}
      />
      <div className="flex justify-between items-center">
        <AirlinesOverview
          className="p-0 border-b-0"
          airlineLogo={
            <img className="w-6" src={flightDetails?.[0].AirlineImageUrl} />
          }
          airlineName={`${airlineName} - ${flightDetails?.[0].Category}`}
        />
        <div className="flex flex-row items-center gap-2.5">
          <p className="text-[13px] text-primary">Lihat detail</p>
          <div className="p-1 rounded-full border">
            <ChevronRight size={16} className="stroke-muted-foreground" />
          </div>
        </div>
      </div>
    </div>
  );
}
