import qs from "qs";

import { api } from "@/lib/api";

import { BaseApiResponse } from "../shared/types";
import { BackOfficeAirlineDetail } from "./types";
import { GetBackOfficeAirlineListDto } from "./types/dto";

export class BackOfficeAirlineApi {
  async list(
    dto?: GetBackOfficeAirlineListDto,
  ): Promise<BaseApiResponse<BackOfficeAirlineDetail[]>> {
    const strDto = qs.stringify(dto, {
      encode: false,
    });
    const { data } = await api.get<BaseApiResponse<BackOfficeAirlineDetail[]>>(
      `/backoffice/airlines?${strDto}`,
    );

    return data;
  }
}
