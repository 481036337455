import { useQuery } from "@lezzcode/query";
import { AxiosError } from "axios";

import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeApi } from "../api";
import { BackOfficeQueryKeyFactory } from "../query-key.factory";
import { BackOfficeProfileEntity } from "../types/entity";

export const useFindBackOfficeProfile = () => {
  const api = new BackOfficeApi();
  const queryKeyFactory = new BackOfficeQueryKeyFactory();

  return useQuery<BaseApiResponse<BackOfficeProfileEntity>, AxiosError>({
    queryKey: queryKeyFactory.profile(),
    queryFn: () => {
      return api.profile();
    },
  });
};
