import { z } from "zod";

import { backOfficeQueryFiltersSchema } from "@/features/backoffice/validators";

export const backOfficePositionQueryFiltersSchema =
  backOfficeQueryFiltersSchema.pick({
    search: true,
  });

export type BackOfficePositionQueryFilters = z.infer<
  typeof backOfficePositionQueryFiltersSchema
>;
