import { BookingStatusEnum } from "@/services/booking/types/entity";

export const bookingStatusBackgroundColors: Record<BookingStatusEnum, string> =
  {
    Saved: "#A7AA31",
    Reserved: "#0288D1",
    Ticketed: "#70B95E",
    Canceled: "#EF5350",
    Expired: "#EF5350",
    BookingError: "#EF5350",
    RESCHEDULED: "#70B95E",
    EXPIRED: "#EF5350",
    ERROR: "#EF5350",
    RESERVED: "#0288D1",
  };
