import dayjs from "dayjs";
import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

interface DateOverviewProps extends HTMLAttributes<HTMLDivElement> {
  date: string;
}

export function DateOverview({
  date,
  className,
  ...rest
}: DateOverviewProps): React.JSX.Element {
  return (
    <p className={cn("text-primary text-base font-bold", className)} {...rest}>
      {dayjs(date).format("ddd, DD MMM YYYY")}
    </p>
  );
}
