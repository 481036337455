import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import { useAddBackOfficeCompany } from "@/services/backoffice-company/hooks/use-add-backoffice-company";

import { AddCompanySchema, addCompanySchema } from "../validators";

export const useAddDialog = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const methods = useForm<AddCompanySchema>({
    defaultValues: {},
    mode: "onChange",
    resolver: zodResolver(addCompanySchema),
  });

  const { handleSubmit } = methods;

  const { mutateAsync, isPending } = useAddBackOfficeCompany();

  const onSubmit = useCallback(
    async (data: AddCompanySchema) => {
      await mutateAsync({
        isActive: data.isActive,
        name: data.name,
      });

      setDialogOpen(false);
    },
    [mutateAsync],
  );

  return {
    methods,
    handleSubmit,
    onSubmit,
    dialogOpen,
    setDialogOpen,
    isPending,
  };
};
