import { zodResolver } from "@hookform/resolvers/zod";
import { UserRound } from "lucide-react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { cn } from "@/lib/utils";
import { useVerifySpd } from "@/services/digi/hooks/use-verify-spd";
import { VerifySpdSchema, verifySpdSchema } from "@/services/digi/types/dto";
import { FlightPassengerEntity } from "@/services/flight-passenger/types";

interface UnverifiedPassengerProps {
  passenger: FlightPassengerEntity;
  onVerified: (requestNumber: string) => unknown;
}

export function UnverifiedPassenger({
  passenger,
  onVerified,
}: UnverifiedPassengerProps): React.JSX.Element {
  const { toast } = useToast();
  const form = useForm<VerifySpdSchema>({
    resolver: zodResolver(verifySpdSchema),
    defaultValues: { spdNumber: passenger.requestNumber, nip: passenger.nip },
  });

  const { mutateAsync, isPending } = useVerifySpd();

  const onSubmit: SubmitHandler<VerifySpdSchema> = async (values) => {
    const data = await mutateAsync(values);

    if (!data.data)
      return toast({
        title: "Nomor Request Tidak Valid!",
        variant: "destructive",
      });

    return onVerified(values.spdNumber);
  };

  return (
    <div
      key={passenger.IdNumber}
      className="w-full rounded-sm p-4 border border-border flex flex-col gap-4"
      style={{ boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)" }}
    >
      <div className="flex items-center gap-2.5">
        <UserRound className="text-primary h-8 w-8" />
        <p className="text-xl text-primary">{passenger.Name}</p>
      </div>
      <Form {...form}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="w-full h-[3.75rem] border border-border rounded-xl p-2.5 flex items-center justify-between"
        >
          <FormField
            control={form.control}
            name="spdNumber"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <Input
                    placeholder="Masukkan Nomor Request"
                    className={cn(
                      "border-none rounded-none ring-transparent w-full pl-0",
                    )}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <Button
            isLoading={isPending}
            type="button"
            onClick={form.handleSubmit(onSubmit)}
            className="w-[88px] rounded-md"
          >
            Submit
          </Button>
        </form>
      </Form>
    </div>
  );
}
