import { useQueryClient } from "@lezzcode/query";

import { UserAuthServiceApi } from "@/services/user-auth/api";

export const useAuthLogout = () => {
  const userAuthServiceApi = new UserAuthServiceApi();
  const queryClient = useQueryClient();

  const onLogout = () => {
    userAuthServiceApi.logout();
    queryClient.clear();
  };

  return { onLogout };
};
