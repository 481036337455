import { z } from "zod";

export const flightSearchSchema = z.object({
  Origin: z.string(),
  Destination: z.string(),
  DepartDate: z.string(),
  CabinClass: z.string(),
  Adult: z.number(),
  Child: z.number(),
  Infant: z.number(),
  PreferredAirlines: z.array(z.number()),
});

export type FlightSearchSchema = z.infer<typeof flightSearchSchema>;
