import { HeadlessDialog } from "@/components/headless-ui/dialog";
import { useDisclose } from "@/hooks/use-disclose";
import { Form, FormSchema } from "@/lezzform/_generated/select-payment";

interface SelectPaymentMethodDialogProps {
  onSubmit: (values: FormSchema) => unknown;
  children: (args: ReturnType<typeof useDisclose>) => React.JSX.Element;
}

export function SelectPaymentMethodDialog({
  onSubmit,
  children,
}: SelectPaymentMethodDialogProps) {
  const { isOpen, onClose, onOpen, setIsOpen, onToggle } = useDisclose();

  return (
    <>
      {children({ isOpen, onClose, onOpen, onToggle, setIsOpen })}
      <HeadlessDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Pilih Pembayaran"
        classNames={{ Panel: "w-[475px]", Title: "text-foreground" }}
      >
        <Form
          onAction={{
            cancelButton: onClose,
          }}
          onSubmit={async (form, values) => {
            onSubmit && (await onSubmit(values));
            form.reset();
          }}
        />
      </HeadlessDialog>
    </>
  );
}
