import { useSearch } from "@tanstack/react-router";
import dayjs from "dayjs";

import { StatusWrapper } from "@/components/shared/status-wrapper";
import { AirlinesOverview } from "@/features/flight-ticket/components/overview/airlines-overview";
import { cn } from "@/lib/utils";
import { useGetBooking } from "@/services/booking/hooks/use-get-booking";
import { formatDuration } from "@/utils/function";

import { SectionContainer } from "./section-container";

export function OrderSummary(): React.JSX.Element {
  const search = useSearch({ from: "/checkout/" });
  const { data: bookingData, isPending } = useGetBooking(search.bookingId);

  const flightDetails = bookingData?.reservation.FlightDetails;
  const segments = bookingData?.reservation.Segments;

  return (
    <SectionContainer>
      <p className="text-base mb-3">Rangkuman Pesanan</p>
      <StatusWrapper.Container
        className={cn(isPending && "h-[220px]")}
        isLoading={isPending}
      >
        <div className="flex flex-col border rounded-lg overflow-hidden">
          <div className="flex flex-row justify-between items-center px-4 py-2 bg-primary">
            <p className="text-sm	text-white font-bold">Departure flight</p>
            <p className="text-sm	text-white font-bold">
              {dayjs(bookingData?.booking.departTime).format(
                "ddd, DD MMM YYYY",
              )}
            </p>
          </div>
          <AirlinesOverview
            className="px-[15px] pb-2 pt-[23px]"
            airlineLogo={
              <img src={flightDetails?.[0].AirlineImageUrl} className="h-8" />
            }
            airlineName={`${segments?.[0].AirlineName} - ${flightDetails?.[0].Category}`}
            airplaneName={flightDetails?.[0].FlightNumber}
          />
          <div className="flex flex-row justify-between items-center gap-4 px-[15px] pt-2 pb-[17px]">
            <div>
              <p className="text-base">
                {flightDetails?.[0].AirportV2Details[0].Iata}
              </p>
              <p className="text-sm">{flightDetails?.[0].DepartTime}</p>
              <p className="text-xs">
                {dayjs(flightDetails?.[0].DepartDate).format(
                  "ddd, DD MMM YYYY",
                )}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <p className="text-muted-foreground text-xs">
                {formatDuration(flightDetails?.[0].Duration)}
              </p>
              <img src="/image/long-arrow.png" />
            </div>
            <div>
              <p className="text-base">
                {flightDetails?.[0].AirportV2Details[1].Iata}
              </p>
              <p className="text-sm">{flightDetails?.[0].ArriveTime}</p>
              <p className="text-xs">
                {dayjs(flightDetails?.[0].ArriveDate).format(
                  "ddd, DD MMM YYYY",
                )}
              </p>
            </div>
          </div>
        </div>
      </StatusWrapper.Container>
    </SectionContainer>
  );
}
