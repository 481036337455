import {
  AlertCircleIcon,
  CalendarIcon,
  CheckCircleIcon,
  Clock3Icon,
  LucideIcon,
  SaveIcon,
  XIcon,
} from "lucide-react";

import { BookingStatusEnum } from "@/services/booking/types/entity";

export const bookingStatusIcon: Record<BookingStatusEnum, LucideIcon> = {
  Saved: SaveIcon,
  Reserved: Clock3Icon,
  Ticketed: CheckCircleIcon,
  Canceled: XIcon,
  Expired: CalendarIcon,
  BookingError: AlertCircleIcon,
  RESCHEDULED: CalendarIcon,
  EXPIRED: CalendarIcon,
  ERROR: AlertCircleIcon,
  RESERVED: Clock3Icon,
};

export const corporateBookingStatusLabel: Record<BookingStatusEnum, string> = {
  Saved: "Tersimpan",
  Reserved: "Belum Approve",
  Ticketed: "Berhasil",
  Canceled: "Dibatalkan",
  Expired: "Kadaluwarsa",
  BookingError: "Terjadi Error",
  RESCHEDULED: "Dijadwalkan Ulang",
  EXPIRED: "Kadaluwarsa",
  ERROR: "Terjadi Error",
  RESERVED: "Belum Approve",
};

export const individualBookingStatusLabel: Record<BookingStatusEnum, string> = {
  ...corporateBookingStatusLabel,
  Reserved: "Belum Bayar",
};
