import { cn } from "@/lib/utils";

type BackOfficeContainerProps = React.HTMLAttributes<HTMLDivElement>;

export function BackOfficeContainer({
  className,
  children,
  ...rest
}: BackOfficeContainerProps): React.JSX.Element {
  return (
    <div
      className={cn(
        "w-full flex-1 px-[38px] pt-[22px] pb-[18px] overflow-auto",
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
}
