import { useMemo } from "react";

import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import { DurationDetail } from "@/features/flight-ticket/components/detail/duration-detail";
import { FlightDetail } from "@/features/flight-ticket/components/detail/flight-detail";
import { useFlightStore } from "@/stores/use-flight-store";
import { formatDuration } from "@/utils/function";

interface TicketDetailProps {
  open: boolean;
  onOpenChange: (open: boolean) => unknown;
  footer?: React.JSX.Element | null;
}

export function TicketDetail({
  onOpenChange,
  open,
  footer,
}: TicketDetailProps): React.JSX.Element | null {
  const { ticket } = useFlightStore();

  const originAirport = useMemo(() => {
    return ticket?.AirportV2Details.find(
      (airport) => airport.Iata === ticket.Origin,
    );
  }, [ticket?.AirportV2Details, ticket?.Origin]);

  const destinationAirport = useMemo(() => {
    return ticket?.AirportV2Details.find(
      (airport) => airport.Iata === ticket.Destination,
    );
  }, [ticket?.AirportV2Details, ticket?.Destination]);

  if (!ticket) return null;

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent>
        <DrawerHeader className="flex flex-col items-center h-[5.25rem] border-b border-border">
          <DrawerTitle className="text-xl text-primary">
            {ticket.AirlineName}
          </DrawerTitle>
          <DrawerDescription className="text-sm text-muted-foreground">
            {ticket.Origin} - {ticket.Destination} /{" "}
            {formatDuration(ticket.Duration)} / Direct
          </DrawerDescription>
        </DrawerHeader>
        <div className="">
          <DurationDetail
            origin={originAirport?.City as string}
            destination={destinationAirport?.City as string}
            duration={ticket.Duration}
          />
          <FlightDetail ticket={ticket} />
        </div>
        {footer && <DrawerFooter>{footer}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  );
}
