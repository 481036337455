import { z as t } from "zod";
import {
  Lezzform as F,
  FormField as m,
  FormItem as l,
  FormLabel as i,
  FormControl as s,
  FormMessage as u,
  Text as P,
  CustomLayout as k,
  Input as d,
  EmailInput as y,
  PhoneNumberInput as S,
  Dropdown as x,
  Button as b,
} from "@lezzform/react";
import { ChevronDown as L } from "lucide-react";
import * as c from "react";
import { jsx as e, jsxs as a } from "react/jsx-runtime";
var M = t.object({
    Title: t.string(),
    Name: t
      .string()
      .min(1, "Minimal 1 karakter")
      .refine((r) => !r || /^[a-zA-Z\s]+$/.test(r), {
        message: "Nama tidak valid",
      }),
    nip: t.string().min(9, "Masukkan 9 digit nomor NIP"),
    IdNumber: t
      .string()
      .max(16, "Melebihi 16 digit nomor KTP")
      .min(16, "Masukkan 16 digit nomor KTP")
      .refine((r) => !r || /^[0-9\s]+$/.test(r), {
        message: "Hanya menerima input angka",
      }),
    Email: t.string().email("Email tidak valid"),
    MobilePhone: t
      .string()
      .max(14, "Maksimal 14 karakter")
      .min(10, "Minimal 10 karakter")
      .refine(
        (r) =>
          !r ||
          /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(r),
        { message: "Nomor HP tidak valid" },
      ),
  }),
  C = "UBfyiO0kme6WfkJxPwUt",
  w = ({
    onSubmit: r,
    onError: p,
    onSuccess: z,
    defaultValues: f,
    onAction: h,
    formProps: B,
  }) => {
    let g = c.useRef();
    c.useEffect(() => {
      r && (g.current = r);
    }, [r]);
    let R = c.useCallback(async (n, o) => {
      if (g.current) return g.current(n, o);
    }, []);
    return e(
      F,
      {
        id: C,
        defaultValues: f,
        onSubmit: R,
        onError: p,
        onSuccess: z,
        zodSchema: M,
        mode: "onChange",
        children: (n) =>
          a(F.Container, {
            children: [
              e(P.Small, { styles: { root: {} }, children: "Nama Penumpang" }),
              a(k, {
                style: {
                  gap: "8px",
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "88px 1fr",
                },
                children: [
                  e(m, {
                    control: n.control,
                    name: "Title",
                    render: ({ field: o }) =>
                      a(l, {
                        children: [
                          e(i, { isRequired: !0 }),
                          e(s, {
                            children: e(x, {
                              label: "",
                              name: o.name,
                              value: o.value,
                              onChange: o.onChange,
                              placeholder: "Mr.",
                              items: [
                                { label: "Mr.", value: "Mr" },
                                { label: "Mrs.", value: "Mrs" },
                              ],
                              isRequired: !0,
                              styles: { root: {} },
                              suffixAdornment: {
                                icon: e(L, { size: 14, color: "#000000" }),
                              },
                            }),
                          }),
                          e(u, {}),
                        ],
                      }),
                  }),
                  e(m, {
                    control: n.control,
                    name: "Name",
                    render: ({ field: o }) =>
                      a(l, {
                        children: [
                          e(i, { isRequired: !0 }),
                          e(s, {
                            children: e(d, {
                              label: "",
                              name: o.name,
                              value: o.value ?? "",
                              onBlur: o.onBlur,
                              onChange: o.onChange,
                              placeholder: "Masukkan Nama Penumpang",
                              isRequired: !0,
                              styles: { root: {} },
                            }),
                          }),
                          e(u, {}),
                        ],
                      }),
                  }),
                ],
              }),
              e(m, {
                control: n.control,
                name: "nip",
                render: ({ field: o }) =>
                  a(l, {
                    children: [
                      e(i, { isRequired: !0, children: "NIP" }),
                      e(s, {
                        children: e(d, {
                          label: "NIP",
                          name: o.name,
                          value: o.value ?? "",
                          onBlur: o.onBlur,
                          onChange: o.onChange,
                          placeholder: "Masukkan NIP",
                          isRequired: !0,
                          styles: { root: {} },
                        }),
                      }),
                      e(u, {}),
                    ],
                  }),
              }),
              e(m, {
                control: n.control,
                name: "IdNumber",
                render: ({ field: o }) =>
                  a(l, {
                    children: [
                      e(i, { isRequired: !0, children: "KTP" }),
                      e(s, {
                        children: e(d, {
                          label: "KTP",
                          name: o.name,
                          value: o.value ?? "",
                          onBlur: o.onBlur,
                          onChange: o.onChange,
                          placeholder: "Masukkan KTP",
                          isRequired: !0,
                          styles: { root: {} },
                        }),
                      }),
                      e(u, {}),
                    ],
                  }),
              }),
              e(m, {
                control: n.control,
                name: "Email",
                render: ({ field: o }) =>
                  a(l, {
                    children: [
                      e(i, { isRequired: !0, children: "Email" }),
                      e(s, {
                        children: e(y, {
                          label: "Email",
                          name: o.name,
                          value: o.value ?? "",
                          onBlur: o.onBlur,
                          onChange: o.onChange,
                          placeholder: "Masukkan Alamat E-Mail",
                          isRequired: !0,
                          styles: { root: {} },
                        }),
                      }),
                      e(u, {}),
                    ],
                  }),
              }),
              e(m, {
                control: n.control,
                name: "MobilePhone",
                render: ({ field: o }) =>
                  a(l, {
                    children: [
                      e(i, { isRequired: !0, children: "Nomor HP" }),
                      e(s, {
                        children: e(S, {
                          label: "Nomor HP",
                          name: o.name,
                          value: o.value ?? "",
                          onBlur: o.onBlur,
                          onChange: o.onChange,
                          placeholder: "Masukkan Nomor Handphone",
                          isRequired: !0,
                          styles: { root: {} },
                        }),
                      }),
                      e(u, {}),
                    ],
                  }),
              }),
              a(k, {
                style: {
                  gap: "8px",
                  width: "100%",
                  display: "grid",
                  marginTop: "8px",
                  gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                },
                children: [
                  e(b, {
                    type: "button",
                    className: "w-full",
                    onClick: () => {
                      h?.cancelButton && h?.cancelButton(n);
                    },
                    styles: {
                      root: {
                        height: "48px",
                        fontSize: "18px",
                        fontWeight: "700",
                        borderRadius: "12px",
                        backgroundColor: "#D34C4C",
                      },
                    },
                    children: "Kembali",
                  }),
                  e(b, {
                    type: "submit",
                    className: "w-full",
                    disabled: n.formState.isSubmitting,
                    isLoading: n.formState.isSubmitting,
                    styles: {
                      root: {
                        height: "48px",
                        fontSize: "18px",
                        fontWeight: "700",
                        borderRadius: "12px",
                        backgroundColor: "#0065B3",
                      },
                    },
                    children: "Tambah Penumpang",
                  }),
                ],
              }),
            ],
          }),
      },
      C,
    );
  };
export { w as Form };
