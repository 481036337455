import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { usePatchBackOfficeUser } from "@/services/backoffice-user/hooks/use-patch-backoffice-user";

import { BackOfficeUserColumn } from "../constants/table-column";
import { EditUserSchema, editUserSchema } from "../validators";

export const useEditDialog = () => {
  const [selectedValue, setSelectedValue] = useState<BackOfficeUserColumn>();
  const [dialogOpen, setDialogOpen] = useState(false);

  const methods = useForm<EditUserSchema>({
    defaultValues: {},
    mode: "onChange",
    resolver: zodResolver(editUserSchema),
  });

  const { handleSubmit, reset } = methods;

  const { mutateAsync, isPending } = usePatchBackOfficeUser();

  const onSubmit = useCallback(
    async (data: EditUserSchema) => {
      await mutateAsync(data);
      setDialogOpen(false);
    },
    [mutateAsync],
  );

  const onClick = (data: BackOfficeUserColumn) => {
    setSelectedValue(data);
  };

  useEffect(() => {
    if (!selectedValue) return;
    reset(selectedValue);
  }, [reset, selectedValue]);

  return {
    methods,
    handleSubmit,
    onSubmit,
    onClick,
    dialogOpen,
    setDialogOpen,
    isPending,
  };
};
