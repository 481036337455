import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

import { useFindBooking } from "@/services/booking/hooks/use-find-booking";
import { BookingEntity } from "@/services/booking/types/entity";
import { groupItems } from "@/utils/function";

import { HistoriesFilterSchema, historiesFilterSchema } from "../validators";

export const useHistoriesPage = () => {
  const methods = useForm<HistoriesFilterSchema>({
    resolver: zodResolver(historiesFilterSchema),
  });

  const { data, isFetching } = useFindBooking({
    from: methods.watch("from"),
    to: methods.watch("to"),
  });

  const bookingHistories = useMemo(() => {
    const bookings = data.map((item) => ({
      ...item,
      booking: {
        ...item.booking,
        createdAt: dayjs(item.booking.departTime).format("MMMM"),
      },
    }));

    return groupItems<BookingEntity>("booking.createdAt", bookings);
  }, [data]);

  return { methods, bookingHistories, isFetching };
};
