import dayjs from "dayjs";
import { z } from "zod";

import { ssrSchema } from "@/services/ssr/types/entity";

export const basePassengerSchema = z.object({
  Title: z.string(),
  IdNumber: z.string(),
  Email: z.string().email(),
  MobilePhone: z
    .string()
    .max(14)
    .min(10)
    .refine(
      (value) => {
        const phoneRegex =
          /^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/;
        return phoneRegex.test(value);
      },
      {
        message: "Invalid phone number format",
      },
    ),
  BirthDate: z
    .string()
    .optional()
    .default(dayjs().subtract(34, "years").format("DD-MM-YYYY")),
  Nationality: z.string().optional().default("ID"),
  HomePhone: z.string().optional().default(""),
  AdultAssoc: z.string().optional().default(""),
  EmergencyEmail: z.string().optional().default(""),
  EmergencyFullName: z.string().optional().default(""),
  EmergencyPhone: z.string().optional().default(""),
  Index: z.number().optional().default(1),
  IsSeniorCitizen: z.boolean().optional().default(false),
  OtherPhone: z.string().optional().default(""),
  PassportExpire: z.string().optional().default(""),
  PassportNumber: z.string().optional().default(""),
  PassportOrigin: z.string().optional().default(""),
  Seats: z.array(z.string()).optional().default([]),
  Ssrs: z.array(ssrSchema).optional().default([]),
  Type: z.number().optional().default(1),
  requestNumber: z.string().min(1).optional(),
  nip: z.string().min(9, "Masukkan 9 digit nomor NIP").optional(),
});

export const flightPassengerSchema = z
  .object({
    Name: z.string(),
  })
  .and(basePassengerSchema);

export type FlightPassengerSchema = z.infer<typeof flightPassengerSchema>;
export type FlightPassengerEntity = FlightPassengerSchema;

export const passengerSchema = z
  .object({
    FirstName: z.string(),
    LastName: z.string(),
    Nik: z.string(),
    TicketNumber: z.string(),
  })
  .and(basePassengerSchema);

export type PassengerSchema = z.infer<typeof passengerSchema>;
export type PassengerEntity = PassengerSchema;
