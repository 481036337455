import { QueryKey } from "@lezzcode/query";

import { QueryKeyFactory } from "@/services/shared/query-key.factory";

export class DigiQueryKeyFactory extends QueryKeyFactory {
  constructor() {
    super("digi");
  }

  balance(): QueryKey {
    return [...this.all(), "balance"];
  }
}
