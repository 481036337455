import { z } from "zod";

export const editOrderSchema = z
  .object({
    id: z.string().min(1),
    status: z.string().min(1).max(50),
    code: z.string().optional(),
    airline: z.string().min(2).max(50),
    departTime: z.date(),
    arriveTime: z.date(),
    origin: z.string().min(2).max(100),
    destination: z.string().min(2).max(100),
    price: z.number().optional().default(0),
    rescheduleCharge: z.number().optional().default(0),
    refundAmount: z.number().optional().default(0),
  })
  .refine((data) => data.departTime < data.arriveTime, {
    message: "Departure time must be earlier than arrival time",
    path: ["departTime"],
  });

export type EditOrderSchema = z.infer<typeof editOrderSchema>;
