import { useQuery } from "@lezzcode/query";
import { Row } from "@tanstack/react-table";

import { BackOfficeAirlineApi } from "@/services/backoffice-airline/api";
import { BackOfficeAirlineQueryKeyFactory } from "@/services/backoffice-airline/query-key.factory";
import { BackOfficeAirlineDetail } from "@/services/backoffice-airline/types";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeClassificationColumn } from "../constants/table-column";

export function CellAirline({
  row,
}: {
  row: Row<BackOfficeClassificationColumn>;
}) {
  const backOfficeAirlineApi = new BackOfficeAirlineApi();
  const queryKeyFactory = new BackOfficeAirlineQueryKeyFactory();

  const { data: dataAirlines } = useQuery<
    BaseApiResponse<BackOfficeAirlineDetail[]>
  >({
    queryKey: queryKeyFactory.list(),
    queryFn: () => {
      return backOfficeAirlineApi.list();
    },
  });

  const airlines = row.original.airlines.map((item) => item.name);

  if (dataAirlines?.data.length === airlines.length) {
    return <div className="text-center whitespace-nowrap">SEMUA MASKAPAI</div>;
  }

  if (!airlines.includes("Garuda")) {
    return <div className="text-center whitespace-nowrap">NON GARUDA</div>;
  }

  return (
    <div className="text-center whitespace-nowrap">
      {airlines.join(", ").toUpperCase() ?? "-"}
    </div>
  );
}
