import { BaggageIcon } from "@/components/icons/baggage";
import { Luggage2Icon } from "@/components/icons/luggage-2";
import { Badge } from "@/components/ui/badge";
import { PassengerEntity } from "@/services/flight-passenger/types";

interface PassengerOverviewProps extends PassengerEntity {
  isCorporate: boolean;
}

export function PassengerOverview({
  isCorporate,
  Index,
  Type,
  FirstName,
  LastName,
  Email,
  IdNumber,
  MobilePhone,
  Ssrs,
  requestNumber,
}: PassengerOverviewProps): JSX.Element {
  const fullName = `${FirstName} ${LastName}`;

  return (
    <div className="flex flex-row gap-[15px] px-5 py-2.5 border-b">
      <p className="text-xl text-muted-foreground-secondary">{Index}.</p>
      <div className="flex-1 flex flex-col gap-[3px]">
        <div className="flex flex-row items-center gap-2.5">
          <p className="font-semibold">{fullName}</p>
          <Badge className="px-[5px] py-[3px] text-xs rounded-lg">{Type}</Badge>
        </div>
        <p className="text-sm">{isCorporate ? requestNumber : IdNumber}</p>
        <div className="flex flex-row items-center gap-[5px]">
          <Luggage2Icon size={14} className="fill-muted-foreground-secondary" />
          <p className="text-md text-muted-foreground-secondary">
            {isCorporate ? IdNumber : MobilePhone}
          </p>
        </div>
        <div className="flex flex-row items-center gap-[5px]">
          <Luggage2Icon size={14} className="fill-muted-foreground-secondary" />
          <p className="text-md text-muted-foreground-secondary">
            {isCorporate ? MobilePhone : Email}
          </p>
        </div>
        <div className="flex flex-row items-center gap-[5px]">
          <BaggageIcon size={14} className="fill-muted-foreground-secondary" />
          <p className="text-md text-muted-foreground-secondary">
            {Ssrs[0]?.SsrName ?? "-"}
          </p>
        </div>
      </div>
    </div>
  );
}
