import "@lezzform/react/style.css";
import "./index.css";

import * as Sentry from "@sentry/react";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import qs from "qs";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import { queryTransformer } from "./utils/function";

// Create a new router instance
const router = createRouter({
  routeTree,
  parseSearch: (searchStr) => {
    const searchWithoutQuestionMark = searchStr.replace(/\?/g, "");

    let parsed = qs.parse(searchWithoutQuestionMark);
    parsed = queryTransformer(parsed);

    return parsed;
  },
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [],
});

// Render the app
const rootElement = document.getElementById("app")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>,
  );
}
