import { stringify } from "qs";
import { z } from "zod";

import { api } from "@/lib/api";
import { separateName } from "@/lib/utils";

import {
  FlightPassengerEntity,
  flightPassengerSchema,
  PassengerEntity,
} from "../flight-passenger/types";
import { FlightTicketEntity } from "../flight-ticket/types";
import { BaseApiResponse } from "../shared/types";
import { UserAuthServiceApi } from "../user-auth/api";
import {
  BookingFlightSchema,
  bookingFlightSchema,
  BookingHistoriesDto,
} from "./dto";
import {
  ApproverEntity,
  BookingEntity,
  BookingTransactionEntity,
} from "./types/entity";

export class BookingServiceApi {
  private baseUrl: string = "/booking";

  findBooking = async (dto?: BookingHistoriesDto) => {
    const filters = stringify(dto);
    const { data } = await api.get<BookingEntity[]>(
      `${this.baseUrl}?${filters}`,
    );

    return data;
  };

  findActiveBooking = async () => {
    const { data } = await api.get<BookingEntity[]>(
      `${this.baseUrl}/active-booking`,
    );
    return data;
  };

  getBooking = async (id: string) => {
    const { data } = await api.get(`${this.baseUrl}/${id}`);
    return data.data;
  };

  bookingFlight = async (dto: BookingFlightSchema) => {
    const parsed = await bookingFlightSchema.parse(dto);

    const corporateDto = parsed as Extract<
      BookingFlightSchema,
      { payer: "CORPORATE" }
    >;

    const mappedBody: {
      approverId?: string;
      flight: FlightTicketEntity;
      payer: string;
      classId: string;
      Passengers: Partial<PassengerEntity>[];
      Contact: Partial<PassengerEntity>;
    } = {
      Passengers: parsed.Passengers.map((passenger, index) => {
        const { firstName: FirstName, lastName: LastName } = separateName(
          passenger.Name,
        );

        return {
          ...passenger,
          Index: index + 1,
          FirstName,
          LastName: !LastName ? FirstName : LastName,
          Name: undefined,
        };
      }),
      approverId: corporateDto.approverId,
      classId: parsed.classId,
      Contact: {
        Email: "johndoe@email.com",
        Title: "Mr",
        FirstName: "John",
        LastName: "Doe",
        MobilePhone: "08111111111",
        HomePhone: "08121111222",
      },
      flight: parsed.flight as unknown as FlightTicketEntity,
      payer: parsed.payer,
    };

    const data = await api.post(`${this.baseUrl}`, mappedBody);

    return data.data;
  };

  viewBookingTicket = async (id: string) => {
    const { data } = await api.get(`${this.baseUrl}/${id}/view-ticket`);
    return data;
  };

  findBookingApprover = async (
    passengers: FlightPassengerEntity[],
  ): Promise<BaseApiResponse<ApproverEntity[]>> => {
    const parsed = await z.array(flightPassengerSchema).parse(passengers);

    const data = await api.post<BaseApiResponse<ApproverEntity[]>>(
      `${this.baseUrl}/get-approvers`,
      parsed.map((passenger) => {
        const { firstName: FirstName, lastName: LastName } = separateName(
          passenger.Name,
        );

        return {
          ...passenger,
          FirstName,
          LastName: !LastName ? FirstName : LastName,
          Name: undefined,
        };
      }),
    );

    return data.data;
  };

  downloadBookingTicket = async (dto: string) => {
    const userAuthService = new UserAuthServiceApi();
    const initTokens = userAuthService.getToken();

    window.open(
      `${import.meta.env.VITE_BACKEND_URL}/booking/${dto}/download-ticket?token=${initTokens.access_token}`,
    );
  };

  getDigiPayTransaction = async (id: string) => {
    const { data } = await api.get<{ data: BookingTransactionEntity }>(
      `${this.baseUrl}/${id}/digi-pay`,
    );
    return data;
  };
}
