import { useQuery } from "@lezzcode/query";

import { ApprovalServiceApi } from "../api";
import { ApprovalEntity } from "../types/entity";
import { ApprovalQueryKeyFactory } from "../utils/query-key.factory";

export const useFindApproval = () => {
  const api = new ApprovalServiceApi();
  const queryKeyFactory = new ApprovalQueryKeyFactory();

  return useQuery<ApprovalEntity[], Error>({
    queryKey: queryKeyFactory.lists(),
    queryFn: api.findApproval,
    initialData: [],
  });
};
