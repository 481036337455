import { zodResolver } from "@hookform/resolvers/zod";
import { Row } from "@tanstack/react-table";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { usePatchBackOfficeOrder } from "@/services/backoffice-order/hooks/use-patch-backoffice-order";

import { BackOfficeOrderColumn } from "../constants/table-column";
import { BackOfficeOrderStatus } from "../types";
import { EditOrderSchema, editOrderSchema } from "../validators/edit-order";

export const useEditDialog = ({ row }: { row: Row<BackOfficeOrderColumn> }) => {
  const isDisabled =
    row.original.status !== "TICKETED" && row.original.status !== "RESCHEDULED";

  const [selectedValue, setSelectedValue] = useState<BackOfficeOrderColumn>();
  const [selectedStatus, setSelectedStatus] =
    useState<BackOfficeOrderStatus>("DEFAULT");
  const [dialogOpen, setDialogOpen] = useState(false);

  const methods = useForm<EditOrderSchema>({
    defaultValues: {},
    mode: "onChange",
    resolver: zodResolver(editOrderSchema),
  });

  const { handleSubmit, setValue, reset } = methods;

  const onChange = useCallback(
    (value: string) => {
      setValue("status", value);
      setSelectedStatus(value as BackOfficeOrderStatus);
    },
    [setValue],
  );

  const { mutateAsync, isPending } = usePatchBackOfficeOrder();

  const onSubmit = useCallback(
    async (data: EditOrderSchema) => {
      await mutateAsync(data);
      setDialogOpen(false);
    },
    [mutateAsync],
  );

  const onClick = (data: BackOfficeOrderColumn) => {
    setSelectedValue(data);
  };

  useEffect(() => {
    if (!selectedValue) return;

    reset({
      ...selectedValue,
      status: selectedStatus === "DEFAULT" ? "" : selectedStatus,
      arriveTime: new Date(selectedValue.arriveTime),
      departTime: new Date(selectedValue.departTime),
      price: selectedValue.price ?? 0,
      refundAmount: selectedValue.refundAmount ?? 0,
      rescheduleCharge: selectedValue.rescheduleCharge ?? 0,
    });
  }, [selectedValue, selectedStatus, reset]);

  return {
    isDisabled,
    onChange,
    selectedStatus,
    methods,
    handleSubmit,
    onSubmit,
    onClick,
    dialogOpen,
    setDialogOpen,
    isPending,
  };
};
