import { createFileRoute, Outlet } from "@tanstack/react-router";

import { BackOfficeContainer } from "@/features/backoffice/components/backoffice-container";
import { BackOfficeNavHeader } from "@/features/backoffice/components/layout/header/backoffice-nav";
import { BackOfficeSidemenu } from "@/features/backoffice/components/layout/sidemenu/backoffice-sidemenu";

export const Route = createFileRoute("/_backoffice-layout")({
  component: Layout,
});

function Layout() {
  return (
    <div className="flex flex-row">
      <BackOfficeSidemenu />
      <div className="flex flex-col flex-1 h-full">
        <BackOfficeNavHeader />
        <BackOfficeContainer>
          <Outlet />
        </BackOfficeContainer>
      </div>
    </div>
  );
}
