import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { usePatchBackOfficeModule } from "@/services/backoffice-module/hooks/use-patch-backoffice-module";

import { BackOfficeModuleColumn } from "../constants/table-column";
import { EditModuleSchema, editModuleSchema } from "../validators";

export const useEditDialog = () => {
  const [selectedValue, setSelectedValue] = useState<BackOfficeModuleColumn>();
  const [dialogOpen, setDialogOpen] = useState(false);

  const methods = useForm<EditModuleSchema>({
    defaultValues: {},
    mode: "onChange",
    resolver: zodResolver(editModuleSchema),
  });

  const { handleSubmit, reset } = methods;

  const { mutateAsync, isPending } = usePatchBackOfficeModule();

  const onSubmit = useCallback(
    async (data: EditModuleSchema) => {
      await mutateAsync(data);
      setDialogOpen(false);
    },
    [mutateAsync],
  );

  const onClick = (data: BackOfficeModuleColumn) => {
    setSelectedValue(data);
  };

  useEffect(() => {
    if (!selectedValue) return;
    reset(selectedValue);
  }, [reset, selectedValue]);

  return {
    methods,
    handleSubmit,
    onSubmit,
    onClick,
    dialogOpen,
    setDialogOpen,
    isPending,
  };
};
