import qs from "qs";

import {
  AddModuleSchema,
  addModuleSchema,
  EditModuleSchema,
  editModuleSchema,
} from "@/features/backoffice-module/validators";
import { api } from "@/lib/api";
import { filterNonNull } from "@/utils/function";

import { BaseApiResponse } from "../shared/types";
import { BackOfficeModuleDetail } from "./types";
import { GetBackOfficeModuleListDto } from "./types/dto";

export class BackOfficeModuleApi {
  async list(
    dto?: GetBackOfficeModuleListDto,
  ): Promise<BaseApiResponse<BackOfficeModuleDetail[]>> {
    const strDto = qs.stringify(filterNonNull(dto), {
      encode: false,
    });
    const { data } = await api.get<BaseApiResponse<BackOfficeModuleDetail[]>>(
      `/backoffice/accesses?${strDto}`,
    );

    return data;
  }

  update = async (dto: EditModuleSchema): Promise<BaseApiResponse<boolean>> => {
    const parsed = editModuleSchema.parse(dto);

    const data = await api.patch<BaseApiResponse<boolean>>(
      `/backoffice/accesses/${dto.id}`,
      parsed,
    );

    return data.data;
  };

  add = async (dto: AddModuleSchema): Promise<BaseApiResponse<boolean>> => {
    const parsed = addModuleSchema.parse(dto);

    const data = await api.post<BaseApiResponse<boolean>>(
      `/backoffice/accesses`,
      parsed,
    );

    return data.data;
  };
}
