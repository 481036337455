import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

interface DurationOverviewProps extends HTMLAttributes<HTMLDivElement> {
  origin: string;
  destination: string;
  departTime: string;
  arriveTime: string;
  duration: string;
}

export function DurationOverview({
  origin,
  destination,
  departTime,
  arriveTime,
  duration,
  className,
  ...rest
}: DurationOverviewProps): React.JSX.Element {
  return (
    <div
      className={cn("flex flex-row items-center gap-3", className)}
      {...rest}
    >
      <div className="flex flex-col gap-0.5">
        <p className="text-lg">{departTime}</p>
        <p className="text-base">{origin}</p>
      </div>

      <div className="flex flex-col items-center">
        <p className="text-xs text-muted-foreground-secondary">{duration}</p>
        <img src="/image/short-arrow.png" />
        <p className="text-xs text-muted-foreground-secondary">Direct</p>
      </div>

      <div className="flex flex-col gap-0.5">
        <p className="text-lg">{arriveTime}</p>
        <p className="text-base">{destination}</p>
      </div>
    </div>
  );
}
