import { z } from "zod";

import { HistoriesFilterSchema } from "@/features/histories/validators";
import { flightPassengerSchema } from "@/services/flight-passenger/types";

export const corporatePartialBookingFlightSchema = z.object({
  payer: z.literal("CORPORATE"),
  approverId: z.string(),
});

export type CorporatePartialBookingFlightSchema = z.infer<
  typeof corporatePartialBookingFlightSchema
>;

export const userPartialBookingFlightSchema = z.object({
  payer: z.literal("USER"),
});

export type UserPartialBookingFlightSchema = z.infer<
  typeof userPartialBookingFlightSchema
>;

export const bookingFlightSchema = z
  .object({
    flight: z.any(),
    classId: z.string(),
    Passengers: z.array(flightPassengerSchema),
  })
  .and(
    z.discriminatedUnion("payer", [
      corporatePartialBookingFlightSchema,
      userPartialBookingFlightSchema,
    ]),
  );

export type BookingFlightSchema = z.infer<typeof bookingFlightSchema>;

export type BookingHistoriesDto = HistoriesFilterSchema;
