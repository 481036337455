import { useQuery } from "@lezzcode/query";
import { AxiosError } from "axios";

import { BackOfficeClassQueryFilters } from "@/features/backoffice-class/validators/query-filters";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeClassApi } from "../api";
import { BackOfficeClassQueryKeyFactory } from "../query-key.factory";
import { BackOfficeClassDetail } from "../types";

export const useFindBackOfficeClass = (
  filters?: BackOfficeClassQueryFilters,
) => {
  const api = new BackOfficeClassApi();
  const queryKeyFactory = new BackOfficeClassQueryKeyFactory();

  return useQuery<BaseApiResponse<BackOfficeClassDetail[]>, AxiosError>({
    queryKey: queryKeyFactory.list(filters),
    queryFn: () => {
      return api.list({
        search: filters?.search,
      });
    },
  });
};
