import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import Cookies from "js-cookie";
import { createRef, useCallback } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { PasswordInput } from "@/components/ui/password-input";
import {
  BackOfficeLoginSchema,
  backOfficeLoginSchema,
} from "@/features/backoffice-auth/validators";
import { usePostBackOfficeLogin } from "@/services/backoffice-auth/hooks/use-post-backoffice-login";

const validateSearch = z.object({
  search: z.string().optional(),
});

export const Route = createFileRoute(
  "/_backoffice-other-layout/backoffice/sign-in/",
)({
  component: Index,
  validateSearch,
});

function Index() {
  const navigate = useNavigate({
    from: "/backoffice/sign-in",
  });

  const methods = useForm<BackOfficeLoginSchema>({
    resolver: zodResolver(backOfficeLoginSchema),
  });

  const recaptchaRef = createRef<ReCAPTCHA>();

  const { mutateAsync, isPending } = usePostBackOfficeLogin();

  const onSubmit = useCallback(
    async (data: BackOfficeLoginSchema) => {
      const response = await mutateAsync(data);
      Cookies.set("access_token", response.data.token);
      navigate({
        to: "/backoffice/home",
      });
    },
    [mutateAsync, navigate],
  );

  const { handleSubmit } = methods;

  return (
    <div className="flex h-screen items-center justify-center bg-[url('/backoffice-login-bg.webp')] bg-cover">
      <div className="flex flex-col gap-5">
        <Card className="w-[720px]">
          <CardHeader>
            <div className="flex justify-center">
              <img
                src="/pnm-travel-logo.webp"
                alt="PNM Travel Logo"
                className="bg-primaryw-fit h-[71.37px]"
              />
            </div>
          </CardHeader>
          <CardContent>
            <Form {...methods}>
              <form
                className="flex flex-col w-full space-y-2"
                onSubmit={handleSubmit(onSubmit)}
              >
                <FormField
                  control={methods.control}
                  name="username"
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel className="font-bold text-md">
                        Username
                      </FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Masukkan username..." />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={methods.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel className="font-bold text-md">
                        Password
                      </FormLabel>
                      <FormControl>
                        <PasswordInput
                          {...field}
                          placeholder="Masukkan password..."
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={methods.control}
                  name="captchaToken"
                  render={({ field }) => (
                    <FormItem className="">
                      <div className="w-full flex justify-end items-center">
                        <ReCAPTCHA
                          {...field}
                          ref={recaptchaRef}
                          sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY!}
                          className="w-full"
                        />
                      </div>
                    </FormItem>
                  )}
                />

                <Button
                  isLoading={isPending}
                  disabled={!methods.formState.isValid || isPending}
                  variant="default"
                  className="w-full rounded-2xl"
                >
                  Sign in
                </Button>
              </form>
            </Form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
