import { ColumnDef } from "@tanstack/react-table";

import { BackOfficeUserRoleDetail } from "@/services/backoffice-user-role/types";

import { EditDialog } from "../components/edit-dialog";

export type BackOfficeUserRoleColumn = BackOfficeUserRoleDetail;

export const columns: ColumnDef<BackOfficeUserRoleColumn>[] = [
  {
    id: "no",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">NO</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">{row.index + 1}</div>
    ),
  },
  {
    accessorKey: "name",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">NAMA</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.name ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "code",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">KODE</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.code ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "action",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">ACTION</p>;
    },
    enableHiding: false,
    cell: ({ row, ...props }) => {
      return <EditDialog row={row} {...props} />;
    },
  },
];
