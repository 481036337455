import { Link } from "@tanstack/react-router";
import { useMemo } from "react";

import { AccessModuleIcon } from "@/components/icons/access-module-bold";
import { ClassificationBoldIcon } from "@/components/icons/classification-bold";
import { CompanyBoldIcon } from "@/components/icons/company-bold";
import { HomeBoldIcon } from "@/components/icons/home-bold";
import { InvoiceBoldIcon } from "@/components/icons/invoice-bold";
import { ModuleIcon } from "@/components/icons/module-bold";
import { PositionBoldIcon } from "@/components/icons/position-bold";
import { SeatBoldIcon } from "@/components/icons/seat-bold";
import { TicketBoldIcon } from "@/components/icons/ticket-bold";
import { UserBoldIcon } from "@/components/icons/user-bold";
import { UserRoleIcon } from "@/components/icons/user-role-bold";
import { BackOfficeMenu } from "@/features/backoffice/types";
import { cn } from "@/lib/utils";

export function BackOfficeSidemenu(): React.JSX.Element {
  const links = useMemo(() => {
    return [
      {
        icon: HomeBoldIcon,
        label: "Beranda",
        link: "home",
      },
      {
        icon: TicketBoldIcon,
        label: "Pemesanan",
        link: "orders",
      },
      {
        icon: PositionBoldIcon,
        label: "Jabatan",
        link: "positions",
      },
      {
        icon: CompanyBoldIcon,
        label: "Perusahaan",
        link: "companies",
      },
      {
        icon: SeatBoldIcon,
        label: "Class",
        link: "classes",
      },
      {
        icon: ClassificationBoldIcon,
        label: "Klasifikasi",
        link: "classifications",
      },
      {
        icon: InvoiceBoldIcon,
        label: "Invoice",
        link: "invoices",
      },
      {
        icon: UserBoldIcon,
        label: "User",
        link: "users",
      },
      {
        icon: UserRoleIcon,
        label: "User Role",
        link: "user-roles",
      },
      {
        icon: ModuleIcon,
        label: "Modul",
        link: "modules",
      },
      {
        icon: AccessModuleIcon,
        label: "Akses Modul",
        link: "access-modules",
      },
    ];
  }, []);

  return (
    <div className="w-[280px] min-h-screen pt-[50px] bg-primary">
      <img src="/brand.png" width={150} className="mx-auto mb-[50px]" />
      <div className="flex flex-col gap-2.5">
        {links.map((item, index) => {
          const currentLink = item.link as BackOfficeMenu;
          const Icon = item.icon;

          return (
            <Link
              key={index}
              to={`/backoffice/${currentLink}`}
              className={cn(
                "flex flex-row items-center gap-4 p-3 text-white fill-white rounded-lg cursor-pointer",
              )}
              activeProps={{
                style: {
                  color: "#0065b3",
                  fill: "#0065b3",
                },
                className: "bg-white/80",
              }}
            >
              <Icon className="fill-inherit" />
              <p className="text-xl font-bold">{item.label}</p>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
