import { useMutation } from "@lezzcode/query";

import { BackOfficeInvoiceDownloadSchema } from "@/features/backoffice-invoice/validators/download-invoice";
import { toast } from "@/hooks/use-toast";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeInvoiceApi } from "../api";

export const useDownloadBackOfficeInvoice = () => {
  const api = new BackOfficeInvoiceApi();

  return useMutation<
    BaseApiResponse<boolean>,
    Error,
    BackOfficeInvoiceDownloadSchema
  >({
    mutationFn: api.downloadInvoice,
    onError: async ({ message }) => {
      toast({
        title: `Download invoice gagal: ${message}.`,
        variant: "destructive",
      });
    },
  });
};
