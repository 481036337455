import { useMutation, useQueryClient } from "@lezzcode/query";

import { EditOrderSchema } from "@/features/backoffice-order/validators/edit-order";
// import { useSearchQuery } from "@/hooks/use-search-query";
import { toast } from "@/hooks/use-toast";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeOrderApi } from "../api";
import { BackOfficeOrderQueryKeyFactory } from "../query-key.factory";

export const usePatchBackOfficeOrder = () => {
  const api = new BackOfficeOrderApi();
  const queryClient = useQueryClient();
  const queryKeyFactory = new BackOfficeOrderQueryKeyFactory();

  return useMutation<BaseApiResponse<boolean>, Error, EditOrderSchema>({
    mutationFn: api.update,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeyFactory.lists(),
      });
    },
    onSuccess: () => {
      toast({
        title: "Update pemesanan berhasil!",
        variant: "success",
      });
    },
    onError: async () => {
      await queryClient.cancelQueries({
        queryKey: queryKeyFactory.lists(),
      });
      toast({ title: "Update pemesanan gagal!", variant: "destructive" });
    },
  });
};
