import { Trash2Icon, UserRound } from "lucide-react";

import { Button } from "@/components/ui/button";
import { FlightPassengerEntity } from "@/services/flight-passenger/types";

interface VerifiedPassengerProps {
  passenger: FlightPassengerEntity;
  onDelete: () => unknown;
}

export function VerifiedPassenger({
  passenger,
  onDelete,
}: VerifiedPassengerProps): React.JSX.Element {
  return (
    <div
      key={passenger.IdNumber}
      className="w-full rounded-sm p-4 border border-border flex flex-col gap-4"
      style={{ boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)" }}
    >
      <div className="flex items-center justify-between gap-2.5">
        <div className="flex items-center gap-2 5">
          <UserRound className="text-primary h-8 w-8" />
          <div>
            <p className="text-xl text-primary">{passenger.Name}</p>
            <p className="text-foreground">{passenger.requestNumber}</p>
          </div>
        </div>
        <Button
          className="-mr-2"
          variant="ghost"
          size="icon"
          onClick={() => onDelete()}
        >
          <Trash2Icon className="text-destructive-foreground h-6 w-6" />
        </Button>
      </div>
    </div>
  );
}
