import { StatusWrapper } from "@/components/shared/status-wrapper";
import { cn } from "@/lib/utils";
import { useFindApproval } from "@/services/approval/hooks/use-find-approval";

import { ApprovalItem } from "./approval-item";

export function ApprovalList(): React.JSX.Element {
  const { data: approvals, isFetching } = useFindApproval();

  return (
    <StatusWrapper.List
      className={cn("min-h-[calc(100vh-120px)]", isFetching && "gap-3 p-5")}
      isLoading={isFetching}
      rows={10}
      renderEmpty="No approvals yet"
    >
      {approvals.map((item) => (
        <ApprovalItem key={item.id} {...item} />
      ))}
    </StatusWrapper.List>
  );
}
