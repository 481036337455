import { useQuery } from "@lezzcode/query";

import { DigiServiceApi } from "../api";
import { DigiBalanceEntity } from "../types/entity";
import { DigiQueryKeyFactory } from "../utils/query-key.factory";

export const useGetDigiBalance = () => {
  const api = new DigiServiceApi();
  const queryKeyFactory = new DigiQueryKeyFactory();

  return useQuery<DigiBalanceEntity, Error>({
    queryKey: queryKeyFactory.balance(),
    queryFn: api.getBalance,
  });
};
