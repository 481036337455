import { useQuery } from "@lezzcode/query";

import { BackOfficeAirlineQueryFilters } from "@/features/backoffice-airline/validators/query-filters";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeAirlineApi } from "../api";
import { BackOfficeAirlineQueryKeyFactory } from "../query-key.factory";
import { BackOfficeAirlineDetail } from "../types";

export const useFindBackOfficeAirline = (
  filters?: BackOfficeAirlineQueryFilters,
) => {
  const api = new BackOfficeAirlineApi();
  const queryKeyFactory = new BackOfficeAirlineQueryKeyFactory();

  return useQuery<BaseApiResponse<BackOfficeAirlineDetail[]>>({
    queryKey: queryKeyFactory.list(filters),
    queryFn: () => {
      return api.list();
    },
  });
};
