import { z } from "zod";

import { backOfficeQueryFiltersSchema } from "@/features/backoffice/validators";

export const backOfficeModuleQueryFiltersSchema =
  backOfficeQueryFiltersSchema.pick({
    search: true,
  });

export type BackOfficeModuleQueryFilters = z.infer<
  typeof backOfficeModuleQueryFiltersSchema
>;
