import z from "zod";

import { PassengerEntity } from "@/services/flight-passenger/types";
import { SegmentEntity } from "@/services/flight-segment/types/entity";
import { FlightTicketEntity } from "@/services/flight-ticket/types";
import { PaymentEntity } from "@/services/payment/types/entity";

export enum BookingStatusEnum {
  SAVED = "Saved",
  RESERVED = "Reserved",
  TICKETED = "Ticketed",
  CANCELED = "Canceled",
  EXPIRED = "Expired",
  BOOKING_ERROR = "BookingError",
  RESCHEDULED = "RESCHEDULED",
  Booking_EXPIRED = "EXPIRED",
  ERROR = "ERROR",
  Booking_RESERVED = "RESERVED",
}

export type BookingEntity = {
  booking: {
    id: string;
    departTime: string;
    arriveTime: string;
    origin: string;
    destination: string;
    airline: string;
    status: BookingStatusEnum;
    payer: string;
    transactions: { id: string; externalId: string; amount: number }[];
    price: number;
    serviceFee: number | null;
    platformFee: number | null;
    ticketPrice: number | null;
  };
  reservation: {
    BookingCode: string;
    Ticketed: string;
    Status: BookingStatusEnum;
    Passengers: PassengerEntity[];
    FlightDetails: FlightTicketEntity[];
    Segments: SegmentEntity[];
    Payments: PaymentEntity[];
  };
  payment: { externalId: string };
};

export type BookingPreviewEntity = {
  formattedDepartDate: string;
  formattedFullDepartDate: string;
  formattedArriveDate: string;
  formattedTicketedTime: string;
  booking: BookingEntity["booking"] & {
    reservation: BookingEntity["reservation"];
  };
};

export const approverSchema = z.object({
  name: z.string(),
  emp_no: z.string(),
});

export type ApproverSchema = z.infer<typeof approverSchema>;
export type ApproverEntity = ApproverSchema;

export type BookingTransactionEntity = {
  id: string;
  externalId: string;
  amount: number;
};
