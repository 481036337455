import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

interface AirlinesOverviewProps extends HTMLAttributes<HTMLDivElement> {
  airlineLogo?: JSX.Element;
  airlineName?: JSX.Element | string;
  airplaneName?: JSX.Element | string;
}

export function AirlinesOverview({
  airlineLogo,
  airlineName,
  airplaneName,
  className,
  ...rest
}: AirlinesOverviewProps): React.JSX.Element {
  return (
    <div
      className={cn(
        "flex flex-col gap-2.5 px-4 pt-2.5 pb-3 border-b",
        className,
      )}
      {...rest}
    >
      <div className="flex flex-row items-center gap-2.5">
        {airlineLogo}
        {typeof airlineName === "object" && airlineName}
        {typeof airlineName === "string" && (
          <p className="text-base">{airlineName}</p>
        )}
      </div>

      {typeof airplaneName === "object" && airplaneName}
      {typeof airplaneName === "string" && (
        <p className="text-xs text-muted-foreground-secondary">
          {airplaneName}
        </p>
      )}
    </div>
  );
}
