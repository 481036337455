import type { IconProps } from "../ui/icon";
import { Icon } from "../ui/icon";

export function HomeBoldIcon({
  className,
  size,
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size}>
      <path d="M21.7189 11.2929L19.7165 9.29286L12.7079 2.29279C12.5201 2.10532 12.2655 2 12 2C11.7345 2 11.4799 2.10532 11.2921 2.29279L4.28352 9.29286L2.28106 11.2929C2.09867 11.4815 1.99776 11.7341 2.00004 11.9963C2.00232 12.2585 2.10762 12.5093 2.29325 12.6947C2.47889 12.8801 2.73001 12.9853 2.99253 12.9876C3.25505 12.9899 3.50796 12.8891 3.6968 12.7069L3.99016 12.4139V20C3.99016 20.5304 4.20113 21.0391 4.57666 21.4142C4.9522 21.7893 5.46153 22 5.99262 22H8.99631C9.26185 22 9.51652 21.8946 9.70429 21.7071C9.89205 21.5196 9.99754 21.2652 9.99754 21V16.9999C9.99754 16.7347 10.103 16.4804 10.2908 16.2928C10.4786 16.1053 10.7332 15.9999 10.9988 15.9999H13.0012C13.2668 15.9999 13.5214 16.1053 13.7092 16.2928C13.897 16.4804 14.0025 16.7347 14.0025 16.9999V21C14.0025 21.2652 14.1079 21.5196 14.2957 21.7071C14.4835 21.8946 14.7381 22 15.0037 22H18.0074C18.5385 22 19.0478 21.7893 19.4233 21.4142C19.7989 21.0391 20.0098 20.5304 20.0098 20V12.4139L20.3032 12.7069C20.492 12.8891 20.7449 12.9899 21.0075 12.9876C21.27 12.9853 21.5211 12.8801 21.7067 12.6947C21.8924 12.5093 21.9977 12.2585 22 11.9963C22.0022 11.7341 21.9013 11.4815 21.7189 11.2929Z" />
    </Icon>
  );
}
