import { ColumnDef } from "@tanstack/react-table";

import { Switch } from "@/components/ui/switch";
import { BackOfficeClassificationDetail } from "@/services/backoffice-classification/types";

import { EditDialog } from "../components/edit-dialog";
import { CellAirline } from "../components/table-cell-airline";

export type BackOfficeClassificationColumn = BackOfficeClassificationDetail;

export const columns: ColumnDef<BackOfficeClassificationColumn>[] = [
  {
    id: "no",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">NO</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">{row.index + 1}</div>
    ),
  },
  {
    accessorKey: "position.name",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">
          POSISI/JABATAN
        </p>
      );
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.position?.name ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "class.name",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">KELAS</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.classes[0].classes.name ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "airlines",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">MASKAPAI</p>
      );
    },
    cell: ({ row }) => <CellAirline row={row} />,
  },
  {
    accessorKey: "isActive",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">STATUS</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        <Switch checked={row.original.isActive ?? "-"} />
      </div>
    ),
  },
  {
    accessorKey: "action",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">ACTION</p>;
    },
    enableHiding: false,
    cell: ({ row, ...props }) => {
      return <EditDialog row={row} {...props} />;
    },
  },
];
