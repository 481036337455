import { z } from "zod";

export const backOfficeQueryFiltersSchema = z.object({
  from: z.union([z.string(), z.date()]).optional(),
  to: z.union([z.string(), z.date()]).optional(),
  search: z.string().optional(),
  status: z.string().optional(),
  company: z.string().optional(),
  payer: z.string().optional(),
});

export type BackOfficeQueryFilters = z.infer<
  typeof backOfficeQueryFiltersSchema
>;
