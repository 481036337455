import { z } from "zod";

export const backOfficeInvoiceExportSPDSchema = z.object({
  from: z.union([z.string(), z.date()]).optional(),
  to: z.union([z.string(), z.date()]).optional(),
  company: z.string().optional(),
});

export type BackOfficeInvoiceExportSPDSchema = z.infer<
  typeof backOfficeInvoiceExportSPDSchema
>;
