import { Row } from "@tanstack/react-table";
import { match } from "ts-pattern";

import { CheckboxCircleFillIcon } from "@/components/icons/checkbox-circle-fill";
import { IntermediateCircleFillIcon } from "@/components/icons/intermediate-circle-fill";
import { TimeFillIcon } from "@/components/icons/time-fill";
import {
  StatusBadge,
  StatusBadgeProps,
} from "@/components/shared/table/badges/status-badge";

import { BackOfficeOrderColumn } from "../constants/table-column";
import { BackOfficeOrderStatus } from "../types";

interface StatusBadgeOrderProps {
  status?: Row<BackOfficeOrderColumn>["original"]["status"];
}

export function StatusBadgeOrder({ status }: StatusBadgeOrderProps) {
  const badgeProps = match(status?.trim().toUpperCase())
    .returnType<{
      status: BackOfficeOrderStatus;
      icon?: StatusBadgeProps["icon"];
    }>()
    .with("RESCHEDULED", () => ({ status: "RESCHEDULED", icon: TimeFillIcon }))
    .with("TICKETED", () => ({
      status: "TICKETED",
      icon: CheckboxCircleFillIcon,
    }))
    .with("REJECTED", () => ({
      status: "REJECTED",
      icon: IntermediateCircleFillIcon,
    }))
    .with("REFUNDED", () => ({
      status: "REFUNDED",
      icon: IntermediateCircleFillIcon,
    }))
    .otherwise(() => ({ status: "DEFAULT" }));

  return (
    <StatusBadge {...badgeProps}>
      <p className="whitespace-nowrap">{status}</p>
    </StatusBadge>
  );
}
