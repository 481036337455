import qs from "qs";

import {
  AddUserSchema,
  addUserSchema,
  EditUserSchema,
  editUserSchema,
} from "@/features/backoffice-user/validators";
import { api } from "@/lib/api";
import { filterNonNull } from "@/utils/function";

import { BaseApiResponse } from "../shared/types";
import { BackOfficeUserDetail } from "./types";
import { GetBackOfficeUserListDto } from "./types/dto";

export class BackOfficeUserApi {
  async list(
    dto?: GetBackOfficeUserListDto,
  ): Promise<BaseApiResponse<BackOfficeUserDetail[]>> {
    const strDto = qs.stringify(filterNonNull(dto), {
      encode: false,
    });
    const { data } = await api.get<BaseApiResponse<BackOfficeUserDetail[]>>(
      `/backoffice/users?${strDto}`,
    );

    return data;
  }

  update = async (dto: EditUserSchema): Promise<BaseApiResponse<boolean>> => {
    const parsed = editUserSchema.parse(dto);
    delete parsed.confirmPassword;

    const data = await api.patch<BaseApiResponse<boolean>>(
      `/backoffice/users/${dto.id}`,
      parsed,
    );

    return data.data;
  };

  add = async (dto: AddUserSchema): Promise<BaseApiResponse<boolean>> => {
    const parsed = addUserSchema.parse(dto);
    delete parsed.confirmPassword;

    const data = await api.post<BaseApiResponse<boolean>>(
      `/backoffice/users`,
      parsed,
    );

    return data.data;
  };
}
