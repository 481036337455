import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import { useMemo } from "react";

import { cn } from "@/lib/utils";
import { ApprovalStatusEnum } from "@/services/approval/types/dto";
import { ApprovalEntity } from "@/services/approval/types/entity";

import { SharedNotificationItem } from "../shared-notification-item";

type ApprovalItemProps = ApprovalEntity;

export function ApprovalItem({
  id,
  bookingId,
  status,
  passengers,
  updatedAt,
}: ApprovalItemProps): React.JSX.Element {
  const navigate = useNavigate({ from: "/notification" });

  const currentPassenger = useMemo(() => {
    return passengers[0];
  }, [passengers]);

  const isRequested = status === ApprovalStatusEnum.REQUESTED;

  return (
    <SharedNotificationItem
      title={`Pengajuan oleh ${currentPassenger.FirstName} ${currentPassenger.LastName} (${dayjs(updatedAt).format("DD-MM-YYYY[,] HH:mm:ss")})`}
      descriptions={[
        `NIK : ${currentPassenger.Nik}`,
        `No. Pesanan : ${bookingId}`,
      ]}
      className={cn(isRequested && "bg-sky-300/20")}
      onClick={() =>
        navigate({
          to: "/approvals/$approvalId",
          params: { approvalId: id },
          search: { success: false, bookingId },
        })
      }
    />
  );
}
