import { DialogClose } from "@radix-ui/react-dialog";
import { CellContext } from "@tanstack/react-table";

import { TablerEditIcon } from "@/components/icons/tabler-edit";
import { StatusBadge } from "@/components/shared/table/badges/status-badge";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { BackofficeDialog } from "@/features/backoffice/components/backoffice-dialog";

import { BackOfficeCompanyColumn } from "../constants/table-column";
import { useEditDialog } from "../hooks/use-edit-dialog";

export function EditDialog({
  row,
}: Readonly<CellContext<BackOfficeCompanyColumn, unknown>>) {
  const {
    methods,
    handleSubmit,
    onSubmit,
    onClick,
    dialogOpen,
    setDialogOpen,
    isPending,
  } = useEditDialog();

  return (
    <BackofficeDialog
      title="Edit Perusahaan"
      button={
        <StatusBadge
          status="EDIT"
          onClick={() => onClick(row.original)}
          icon={TablerEditIcon}
        >
          <p className="whitespace-nowrap">Edit</p>
        </StatusBadge>
      }
      triggerClassName="w-full"
      open={dialogOpen}
      onOpenChange={setDialogOpen}
    >
      <div className="space-y-4">
        <Form {...methods}>
          <form
            className="flex flex-col gap-4 max-h-[50vh]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-thumb-slate-500/40 scrollbar-track-slate-200 overflow-auto">
              <FormField
                control={methods.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="mb-4">
                    <FormLabel className="font-bold text-md">
                      Perusahaan/Divisi
                    </FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Masukkan perusahaan..." />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={methods.control}
                name="isActive"
                render={({ field }) => (
                  <FormItem className="mb-4">
                    <FormLabel className="font-bold text-md">Status</FormLabel>
                    <FormControl className="block">
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex justify-between items-center space-x-2 mt-2">
              <DialogClose asChild>
                <Button
                  type="button"
                  variant="destructive"
                  className="w-full rounded-2xl text-white"
                >
                  Kembali
                </Button>
              </DialogClose>
              <Button
                type="submit"
                isLoading={isPending}
                disabled={!methods.formState.isValid || isPending}
                variant="default"
                className="w-full rounded-2xl"
              >
                Ubah
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </BackofficeDialog>
  );
}
