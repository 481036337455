import { useQuery } from "@lezzcode/query";
import { AxiosError } from "axios";

import { BackOfficeAccessModuleQueryFilters } from "@/features/backoffice-access-module/validators/query-filters";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeAccessModuleApi } from "../api";
import { BackOfficeAccessModuleQueryKeyFactory } from "../query-key.factory";
import { BackOfficeAccessModuleDetail } from "../types";

export const useFindBackOfficeAccessModule = (
  filters?: BackOfficeAccessModuleQueryFilters,
) => {
  const api = new BackOfficeAccessModuleApi();
  const queryKeyFactory = new BackOfficeAccessModuleQueryKeyFactory();

  return useQuery<BaseApiResponse<BackOfficeAccessModuleDetail[]>, AxiosError>({
    queryKey: queryKeyFactory.list(filters),
    queryFn: () => {
      return api.list({
        search: filters?.search,
      });
    },
  });
};
