import { useMutation } from "@lezzcode/query";

import { BackOfficeInvoiceExportSPDSchema } from "@/features/backoffice-invoice/validators/export-spd";
import { toast } from "@/hooks/use-toast";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeInvoiceApi } from "../api";

export const useExportBackOfficeInvoiceSPD = () => {
  const api = new BackOfficeInvoiceApi();

  return useMutation<
    BaseApiResponse<{ no: string }>,
    Error,
    BackOfficeInvoiceExportSPDSchema
  >({
    mutationFn: api.exportSPD,
    onError: async ({ message }) => {
      toast({
        title: `Export SPD gagal: ${message}.`,
        variant: "destructive",
      });
    },
  });
};
