import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

import {
  BookingEntity,
  BookingStatusEnum,
} from "@/services/booking/types/entity";

interface UseFormatBookingTicketProps {
  bookingData?: Partial<BookingEntity>;
}

export function useFormatBookingTicket({
  bookingData,
}: UseFormatBookingTicketProps) {
  const booking = bookingData?.booking;
  const reservation = bookingData?.reservation;

  const isRescheduled = booking?.status === BookingStatusEnum.RESCHEDULED;
  let status = reservation?.Status as BookingStatusEnum;

  const flightDetails = reservation?.FlightDetails;
  const segments = reservation?.Segments;
  const passengers = reservation?.Passengers;

  let statusLabel: string = status!;
  let airlineName = segments?.[0].AirlineName ?? "";
  let origin = flightDetails?.[0].Origin ?? "";
  let destination = flightDetails?.[0].Destination ?? "";
  let departDate = flightDetails?.[0].DepartDate ?? "";
  let departTime = flightDetails?.[0].DepartTime ?? "";
  let arriveTime = flightDetails?.[0].ArriveTime ?? "";
  let duration = flightDetails?.[0].Duration ?? "";

  if (booking?.status === "EXPIRED") {
    status = BookingStatusEnum.EXPIRED;
    statusLabel = BookingStatusEnum.EXPIRED;
  }

  if (isRescheduled) {
    status = BookingStatusEnum.RESCHEDULED;
    statusLabel = "Rescheduled";
    airlineName = booking.airline;
    origin = booking.origin;
    destination = booking.destination;
    departDate = booking.departTime;
    departTime = dayjs(booking.departTime).format("HH:mm");
    arriveTime = dayjs(booking.arriveTime).format("HH:mm");

    const durationObject = dayjs.duration(
      dayjs(booking.arriveTime).diff(dayjs(booking.departTime)),
    );

    duration = `${durationObject.hours()}:${durationObject.minutes()}`;
  }

  return {
    flightDetails,
    segments,
    passengers,
    status,
    statusLabel,
    airlineName,
    origin,
    destination,
    departDate,
    departTime,
    arriveTime,
    duration,
  };
}
