import { zodResolver } from "@hookform/resolvers/zod";
import { useSearch } from "@tanstack/react-router";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { UserAuthServiceApi } from "@/services/user-auth/api";

import {
  FlightBookingFilterSchema,
  flightBookingFilterSchema,
} from "../validators";

export function UseFlightBookingPage() {
  const search = useSearch({ from: "/book/plane/list/" });

  const form = useForm<FlightBookingFilterSchema>({
    resolver: zodResolver(flightBookingFilterSchema),
    defaultValues: {
      price: "desc",
      airlines: search.PreferredAirlines ?? [],
    },
  });

  const [isDateChanged, setIsDateChanged] = useState<boolean>();

  const title = useMemo(() => {
    const total =
      (search.Adult ?? 0) + (search?.Child ?? 0) + (search?.Infant ?? 0);

    return `${total} Penumpang - ${search.CabinClass}`;
  }, [search]);

  const tokens = useMemo(() => {
    const userAuthService = new UserAuthServiceApi();
    const tokens = userAuthService.getToken();
    return tokens;
  }, []);

  return { form, isDateChanged, setIsDateChanged, title, tokens };
}
