import { z } from "zod";

export const flightBookingFilterSchema = z.object({
  airlines: z.array(z.number()),
  price: z.enum(["asc", "desc"]),
});

export type FlightBookingFilterSchema = z.infer<
  typeof flightBookingFilterSchema
>;
