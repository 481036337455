import type { IconProps } from "../ui/icon";
import { Icon } from "../ui/icon";

export function UserRoleIcon({
  className,
  size,
}: IconProps): React.JSX.Element {
  return (
    <Icon viewBox="0 0 32 33" className={className} size={size}>
      <g clip-path="url(#clip0_2195_9618)">
        <path d="M20 28.5293H17.3333C16.6261 28.5293 15.9478 28.2483 15.4477 27.7482C14.9476 27.2482 14.6667 26.5699 14.6667 25.8626C14.6667 25.1554 14.9476 24.4771 15.4477 23.977C15.9478 23.4769 16.6261 23.196 17.3333 23.196H20V20.5293H17.3333C15.9188 20.5293 14.5623 21.0912 13.5621 22.0914C12.5619 23.0916 12 24.4481 12 25.8626C12 27.2771 12.5619 28.6337 13.5621 29.6339C14.5623 30.6341 15.9188 31.196 17.3333 31.196H20V28.5293ZM30.6667 25.8626C30.6667 27.2771 30.1048 28.6337 29.1046 29.6339C28.1044 30.6341 26.7478 31.196 25.3333 31.196H22.6667V28.5293H25.3333C26.0406 28.5293 26.7189 28.2483 27.219 27.7482C27.719 27.2482 28 26.5699 28 25.8626C28 25.1554 27.719 24.4771 27.219 23.977C26.7189 23.4769 26.0406 23.196 25.3333 23.196H22.6667V20.5293H25.3333C26.7478 20.5293 28.1044 21.0912 29.1046 22.0914C30.1048 23.0916 30.6667 24.4481 30.6667 25.8626Z" />
        <path d="M18.6667 24.5296H24V27.1962H18.6667V24.5296ZM9.33333 25.8629C9.33594 25.4159 9.37608 24.9699 9.45333 24.5296H4V22.6629C4 19.9962 9.33333 18.5296 12 18.5296C12.6035 18.5374 13.2054 18.5931 13.8 18.6962C14.8976 18.1493 16.107 17.864 17.3333 17.8629H24V5.8629C23.9979 5.15631 23.7163 4.47925 23.2166 3.97961C22.717 3.47997 22.0399 3.19834 21.3333 3.19623H15.76C15.487 2.41682 14.9785 1.74151 14.3049 1.26372C13.6313 0.785941 12.8258 0.529297 12 0.529297C11.1742 0.529297 10.3687 0.785941 9.69511 1.26372C9.0215 1.74151 8.51302 2.41682 8.24 3.19623H2.66667C1.96007 3.19834 1.28302 3.47997 0.78338 3.97961C0.28374 4.47925 0.0021103 5.15631 0 5.8629L0 24.5296C0.0021103 25.2362 0.28374 25.9132 0.78338 26.4129C1.28302 26.9125 1.96007 27.1941 2.66667 27.1962H9.45333C9.37608 26.7559 9.33594 26.3099 9.33333 25.8629ZM12 3.19623C12.2637 3.19623 12.5215 3.27443 12.7408 3.42094C12.96 3.56745 13.1309 3.77569 13.2318 4.01932C13.3328 4.26296 13.3592 4.53105 13.3077 4.78969C13.2563 5.04833 13.1293 5.28591 12.9428 5.47238C12.7563 5.65885 12.5188 5.78583 12.2601 5.83728C12.0015 5.88873 11.7334 5.86232 11.4898 5.76141C11.2461 5.66049 11.0379 5.48959 10.8914 5.27033C10.7449 5.05106 10.6667 4.79328 10.6667 4.52957C10.6677 4.17627 10.8085 3.83774 11.0584 3.58792C11.3082 3.3381 11.6467 3.19729 12 3.19623ZM12 8.52957C12.7911 8.52957 13.5645 8.76416 14.2223 9.20369C14.8801 9.64322 15.3928 10.2679 15.6955 10.9988C15.9983 11.7297 16.0775 12.534 15.9231 13.3099C15.7688 14.0859 15.3878 14.7986 14.8284 15.358C14.269 15.9174 13.5563 16.2984 12.7804 16.4527C12.0044 16.607 11.2002 16.5278 10.4693 16.2251C9.73836 15.9223 9.11365 15.4096 8.67412 14.7518C8.2346 14.0941 8 13.3207 8 12.5296C7.9993 12.0041 8.10228 11.4836 8.30305 10.998C8.50382 10.5124 8.79843 10.0711 9.17001 9.69957C9.54158 9.328 9.98282 9.03339 10.4684 8.83262C10.9541 8.63185 11.4745 8.52887 12 8.52957Z" />
      </g>
      <defs>
        <clipPath id="clip0_2195_9618">
          <rect width="32" height="32" transform="translate(0 0.529297)" />
        </clipPath>
      </defs>
    </Icon>
  );
}
