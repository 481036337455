import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute } from "@tanstack/react-router";
import debounce from "debounce";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { SearchOutlineIcon } from "@/components/icons/search-outline";
import { BaseTable } from "@/components/shared/table/base-table";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { AddDialog } from "@/features/backoffice-user-role/components/add-dialog";
import { columns } from "@/features/backoffice-user-role/constants/table-column";
import {
  BackOfficeUserRoleQueryFilters,
  backOfficeUserRoleQueryFiltersSchema,
} from "@/features/backoffice-user-role/validators/query-filters";
import { useSearchQuery } from "@/hooks/use-search-query";
import { useFindBackOfficeUserRole } from "@/services/backoffice-user-role/hooks/use-find-backoffice-user-role";

const validateSearch = z.object({
  search: z.string().optional(),
});

export const Route = createFileRoute(
  "/_backoffice-layout/backoffice/user-roles/",
)({
  component: Index,
  validateSearch,
});

function Index() {
  const { searchData, setSearch } =
    useSearchQuery<BackOfficeUserRoleQueryFilters>({
      from: "/_backoffice-layout/backoffice/user-roles/",
    });

  const { data } = useFindBackOfficeUserRole({
    search: searchData.search,
  });

  const defaultValues = useMemo(() => {
    return {
      search: searchData.search,
    };
  }, [searchData.search]);

  const methods = useForm<BackOfficeUserRoleQueryFilters>({
    mode: "onBlur",
    resolver: zodResolver(backOfficeUserRoleQueryFiltersSchema),
    defaultValues,
  });

  const { handleSubmit, getValues, reset } = methods;

  const onFilterSubmit = useCallback(() => {
    setSearch(getValues());
  }, [getValues, setSearch]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <div className="flex flex-col gap-5">
      <p className="text-[28px] font-bold">User Role</p>
      <div className="flex justify-between items-center space-x-4">
        <Form {...methods}>
          <form
            className="flex w-full space-x-2"
            onSubmit={handleSubmit(onFilterSubmit)}
            onBlur={handleSubmit(onFilterSubmit)}
            onChange={debounce(handleSubmit(onFilterSubmit), 500)}
          >
            <FormField
              control={methods.control}
              name="search"
              render={({ field }) => (
                <FormItem className="flex-[4]">
                  <FormControl>
                    <div className="relative flex items-center">
                      <div>
                        <SearchOutlineIcon className="absolute left-3.5 top-1/2 transform -translate-y-1/2 fill-[#939393]" />
                      </div>
                      <Input
                        {...field}
                        value={field.value}
                        placeholder="Cari"
                        className="px-12"
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>

        <AddDialog />
      </div>

      <BaseTable data={data?.data ?? []} columns={columns} />
    </div>
  );
}
