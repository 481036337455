import { useCallback, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";

import { Input } from "@/components/ui/input";
import { SelectDrawer, SelectDrawerItem } from "@/components/ui/select-drawer";
import { useDisclose } from "@/hooks/use-disclose";
import { useFindBookingApprover } from "@/services/booking/hooks/use-find-booking-approver";
import { ApproverEntity } from "@/services/booking/types/entity";
import { useFlightStore } from "@/stores/use-flight-store";

interface ApproverSelectProps {
  value: string;
  onSelect?: (value: string) => unknown;
  children: (
    props: ReturnType<typeof useDisclose> & { selected?: ApproverEntity },
  ) => React.JSX.Element;
  placeholder?: string;
}

export function ApproverSelect({
  value,
  onSelect,
  children,
  placeholder,
}: ApproverSelectProps): React.JSX.Element {
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch] = useDebounce(search, 1000);
  const { isOpen, setIsOpen, onOpen, onClose, onToggle } = useDisclose();

  const { passengers } = useFlightStore();
  const { data } = useFindBookingApprover(passengers);

  const filteredData = useMemo(() => {
    if (!data?.data) return [];

    const search = debouncedSearch.toLowerCase().trim();

    return data.data.filter((item) =>
      Object.entries(item)
        .filter(([, value]) => Boolean(value))
        .map(([, value]) => String(value).toLowerCase().includes(search))
        .includes(true),
    );
  }, [data?.data, debouncedSearch]);

  const selected = useMemo(() => {
    if (!data?.data || !value) return;

    const search = value.toLowerCase().trim();

    return data.data.find((item) =>
      Object.entries(item)
        .filter(([, value]) => Boolean(value))
        .map(([, value]) => String(value).toLowerCase().includes(search))
        .includes(true),
    );
  }, [data?.data, value]);

  const handleSelect = useCallback(
    (value: string) => {
      onSelect && onSelect(value);
      onClose();
    },
    [onSelect, onClose],
  );

  return (
    <div>
      {children({ isOpen, setIsOpen, onOpen, onClose, onToggle, selected })}
      <SelectDrawer
        open={isOpen}
        onOpenChange={setIsOpen}
        placeholder={placeholder}
        onChange={(value) => {
          handleSelect(value);
          onClose();
        }}
        value={value}
      >
        <div className="p-3 bg-white">
          <Input
            value={search}
            placeholder={placeholder}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {Boolean(filteredData.length) && (
          <div className="h-52 overflow-auto bg-white">
            {filteredData.map((item) => (
              <SelectDrawerItem key={item.emp_no} value={item.emp_no}>
                {item.name}
              </SelectDrawerItem>
            ))}
          </div>
        )}
      </SelectDrawer>
    </div>
  );
}
