import { StatusWrapper } from "@/components/shared/status-wrapper";
import { cn } from "@/lib/utils";
import { useFindNotification } from "@/services/notification/hooks/use-find-notification";

import { TicketItem } from "./ticket-item";

export function TicketList(): React.JSX.Element {
  const { data: ticketNotifications, isFetching } = useFindNotification();

  return (
    <StatusWrapper.List
      className={cn("min-h-[calc(100vh-120px)]", isFetching && "gap-3 p-5")}
      isLoading={isFetching}
      rows={10}
      renderEmpty="No ticket notifications yet"
    >
      {ticketNotifications.map((item) => (
        <TicketItem key={item.id} {...item} />
      ))}
    </StatusWrapper.List>
  );
}
