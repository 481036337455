import { useMutation } from "@lezzcode/query";

import { BackOfficeInvoiceExportSchema } from "@/features/backoffice-invoice/validators/export-invoice";
import { toast } from "@/hooks/use-toast";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeInvoiceApi } from "../api";

export const useExportBackOfficeInvoice = () => {
  const api = new BackOfficeInvoiceApi();

  return useMutation<
    BaseApiResponse<{ no: string }>,
    Error,
    BackOfficeInvoiceExportSchema
  >({
    mutationFn: api.exportInvoice,
    onError: async () => {
      toast({
        title: `Export invoice gagal. Pastikan status yang difilter mencakup 'Sudah Tersedia'.`,
        variant: "destructive",
      });
    },
  });
};
