import { createFileRoute, redirect, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { z } from "zod";

import { UserAuthServiceApi } from "@/services/user-auth/api";

const validateSearch = z.object({
  token: z.string(),
});

const userAuthService = new UserAuthServiceApi();

export const Route = createFileRoute("/redirect/")({
  validateSearch,
  beforeLoad: async (opts) => {
    try {
      const modifiedToken = opts.search.token.replace(/\s+/g, "+");
      const authenticate = await userAuthService.authenticate({
        webviewToken: modifiedToken,
      });
      userAuthService.saveToken(authenticate.data);

      return { authenticate };
    } catch (error) {
      console.error({ error });
      throw redirect({ to: "/under-maintenance" });
    }
  },
  loader: async () => {
    try {
      const profile = await userAuthService.profile();

      return { profile };
    } catch (error) {
      return { profile: null };
    }
  },
  component: Index,
});

function Index(): React.JSX.Element {
  const loader = Route.useLoaderData();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loader.profile) {
      navigate({ to: "/under-maintenance" });
      return;
    }

    navigate({ to: "/" });
  }, [loader.profile, navigate]);

  return <div>Redirecting...</div>;
}
