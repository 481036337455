import { createFileRoute } from "@tanstack/react-router";
import React from "react";

export const Route = createFileRoute("/_backoffice-layout/access-denied/")({
  component: Index,
});

function Index(): React.JSX.Element {
  return (
    <div className="flex flex-col justify-center items-center gap-[50px] w-screen h-screen bg-white px-12">
      <img src="/pnm-travel-logo.webp" />
      <p className="text-muted-foreground text-center text-xl">
        Mohon maaf halaman ini tidak dapat diakses dengan akses akun anda...
      </p>
    </div>
  );
}
