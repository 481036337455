import { Dialog, Transition } from "@headlessui/react";
import { Fragment, PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

interface HeadlessDialogProps extends PropsWithChildren {
  open?: boolean;
  onClose?: () => unknown;
  title?: string;
  classNames?: {
    Panel?: string;
    Title?: string;
  };
}

export function HeadlessDialog({
  open,
  onClose,
  title,
  children,
  classNames,
}: HeadlessDialogProps): React.JSX.Element {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog onClose={() => onClose && onClose()} className="relative z-50">
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-black/80 backdrop-blur-md"
            aria-hidden="true"
          />
        </Transition.Child>

        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className={cn(
                "mx-auto max-w-[475px] bg-white p-5 rounded-lg",
                classNames?.Panel,
              )}
            >
              {title && (
                <div className="w-full h-[58px]">
                  <Dialog.Title
                    className={cn(
                      "text-primary text-xl text-center",
                      classNames?.Title,
                    )}
                  >
                    {title}
                  </Dialog.Title>
                </div>
              )}

              <div className="w-full">{children}</div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
