import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function separateName(fullName: string): {
  firstName: string;
  lastName: string;
} {
  const names = fullName.trim().split(" ");
  const firstName = names.shift() || "";
  const lastName = names.join(" ");
  return { firstName, lastName };
}

export const formatToRupiah = (amount: number) =>
  new Intl.NumberFormat("id", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(amount ?? 0);

export function mergePath(...args: (string | undefined)[]): string {
  return args.filter((arg) => Boolean(arg)).join(".");
}
