import { z } from "zod";

const baseUserSchema = z.object({
  id: z.string().min(1),
  firstName: z.string().min(1).max(50),
  lastName: z.string().min(1).max(50),
  email: z.string().email(),
  phoneNumber: z.string().min(1),
  roleId: z.string().min(1),
  isActive: z.boolean().default(false),
  password: z.string().optional(),
  confirmPassword: z.string().optional(),
});

export const editUserSchema = baseUserSchema.refine(
  (data) => data.password === data.confirmPassword,
  {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  },
);

export const addUserSchema = baseUserSchema
  .omit({ id: true })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

export type EditUserSchema = z.infer<typeof editUserSchema>;
export type AddUserSchema = z.infer<typeof addUserSchema>;
