import type { IconProps } from "../ui/icon";
import { Icon } from "../ui/icon";

export function ExportBoldIcon({
  className,
  size,
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size}>
      <path d="M16.707 9.48C16.5195 9.28772 16.2652 9.17971 16 9.17971C15.7348 9.17971 15.4805 9.28772 15.293 9.48L13 11.8318V3.02564C13 2.75362 12.8946 2.49275 12.7071 2.3004C12.5196 2.10806 12.2652 2 12 2C11.7348 2 11.4804 2.10806 11.2929 2.3004C11.1054 2.49275 11 2.75362 11 3.02564V11.8318L8.707 9.48C8.61475 9.38204 8.50441 9.30391 8.3824 9.25015C8.2604 9.1964 8.12918 9.16811 7.9964 9.16692C7.86362 9.16574 7.73194 9.19169 7.60905 9.24326C7.48615 9.29483 7.3745 9.37099 7.2806 9.46729C7.18671 9.56359 7.11246 9.6781 7.06218 9.80415C7.0119 9.9302 6.9866 10.0653 6.98775 10.2014C6.9889 10.3376 7.01649 10.4722 7.0689 10.5973C7.12131 10.7225 7.19749 10.8356 7.293 10.9303L11.293 15.0328C11.3859 15.1283 11.4962 15.2041 11.6177 15.2558C11.7392 15.3075 11.8695 15.3341 12.001 15.3341C12.1325 15.3341 12.2628 15.3075 12.3843 15.2558C12.5058 15.2041 12.6161 15.1283 12.709 15.0328L16.709 10.9303C16.8962 10.7376 17.0012 10.4767 17.0008 10.2047C17.0004 9.93274 16.8947 9.67206 16.707 9.48Z" />
      <path d="M20 13.7949H17.45L14.475 16.8462C14.15 17.1795 13.7641 17.444 13.3395 17.6244C12.9148 17.8048 12.4597 17.8977 12 17.8977C11.5403 17.8977 11.0852 17.8048 10.6605 17.6244C10.2359 17.444 9.85001 17.1795 9.525 16.8462L6.55 13.7949H4C3.46957 13.7949 2.96086 14.011 2.58579 14.3957C2.21071 14.7804 2 15.3021 2 15.8462V19.9487C2 20.4928 2.21071 21.0145 2.58579 21.3992C2.96086 21.7839 3.46957 22 4 22H20C20.5304 22 21.0391 21.7839 21.4142 21.3992C21.7893 21.0145 22 20.4928 22 19.9487V15.8462C22 15.3021 21.7893 14.7804 21.4142 14.3957C21.0391 14.011 20.5304 13.7949 20 13.7949ZM17.5 19.9487C17.2033 19.9487 16.9133 19.8585 16.6666 19.6894C16.42 19.5204 16.2277 19.2801 16.1142 18.999C16.0007 18.7179 15.9709 18.4086 16.0288 18.1101C16.0867 17.8117 16.2296 17.5376 16.4393 17.3224C16.6491 17.1072 16.9164 16.9607 17.2074 16.9014C17.4983 16.842 17.7999 16.8725 18.074 16.9889C18.3481 17.1053 18.5824 17.3025 18.7472 17.5555C18.912 17.8085 19 18.106 19 18.4103C19 18.8183 18.842 19.2096 18.5607 19.4981C18.2794 19.7866 17.8978 19.9487 17.5 19.9487Z" />
    </Icon>
  );
}
