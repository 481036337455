import { Link } from "@tanstack/react-router";

import { BellBoldIcon } from "@/components/icons/bell-bold";
import { SettingsBoldIcon } from "@/components/icons/settings-bold";
import { Button } from "@/components/ui/button";
import { UserMenu } from "@/features/backoffice-auth/components/backoffice-user-menu";

export function BackOfficeNavHeader(): React.JSX.Element {
  return (
    <div className="w-full h-16 px-[50px] py-2.5 flex justify-end items-center gap-5 border-b">
      <Link to="/backoffice/home">
        <Button
          className="bg-transparent hover:bg-primary/10 h-8 w-8 rounded-full"
          size="icon"
        >
          <BellBoldIcon className="fill-foreground" size={24} />
        </Button>
      </Link>
      <Link to="/backoffice/home">
        <Button
          className="bg-transparent hover:bg-primary/10 h-8 w-8 rounded-full"
          size="icon"
        >
          <SettingsBoldIcon className="stroke-foreground" size={24} />
        </Button>
      </Link>
      <UserMenu />
    </div>
  );
}
