import { z } from "zod";

import { backOfficeQueryFiltersSchema } from "@/features/backoffice/validators";

export const backOfficeUserQueryFiltersSchema =
  backOfficeQueryFiltersSchema.pick({
    search: true,
  });

export type BackOfficeUserQueryFilters = z.infer<
  typeof backOfficeUserQueryFiltersSchema
>;
