"use client";

import { Icon, type IconProps } from "../ui/icon";

export function ClockIcon({
  className,
  size,
  ...props
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size} {...props}>
      <path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.9971 9.34873 20.9426 6.80688 19.0679 4.93215C17.1931 3.05742 14.6513 2.00291 12 2ZM15.982 15.982C15.7945 16.1695 15.5402 16.2748 15.275 16.2748C15.0098 16.2748 14.7555 16.1695 14.568 15.982L11.294 12.708C11.1069 12.5197 11.0014 12.2654 11 12V8C11 7.73478 11.1054 7.48043 11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289C12.8946 7.48043 13 7.73478 13 8V11.586L15.982 14.568C16.1695 14.7555 16.2748 15.0098 16.2748 15.275C16.2748 15.5402 16.1695 15.7945 15.982 15.982Z" />
    </Icon>
  );
}
