import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { Checkbox } from "@/components/ui/checkbox";
import { SelectDrawer } from "@/components/ui/select-drawer";
import { useDisclose } from "@/hooks/use-disclose";
import { useFindBackOfficeAirline } from "@/services/backoffice-airline/hooks/use-find-backoffice-airline";

import { FlightBookingFilterSchema } from "../validators";

interface FilterSelectProps {
  children: (props: ReturnType<typeof useDisclose>) => React.JSX.Element;
  placeholder: string;
}

export function FilterSelect({
  children,
  placeholder,
}: FilterSelectProps): React.JSX.Element {
  const { isOpen, setIsOpen, onOpen, onClose, onToggle } = useDisclose();

  const { data } = useFindBackOfficeAirline();
  const airlines = data?.data;

  const methods = useFormContext<FlightBookingFilterSchema>();
  const { watch, reset } = methods;
  const selectedAirlines = watch("airlines") as number[];

  const onCheckedChange = useCallback(
    (id: number) => {
      let currentAirlines = [...selectedAirlines];
      const checked = !currentAirlines.find((airline) => airline === id);

      if (checked) currentAirlines.push(id);

      if (!checked)
        currentAirlines = currentAirlines.filter((item) => item !== id);

      reset({ airlines: currentAirlines });
    },
    [selectedAirlines, reset],
  );

  return (
    <div>
      {children({ isOpen, setIsOpen, onOpen, onClose, onToggle })}
      <SelectDrawer
        open={isOpen}
        onOpenChange={setIsOpen}
        placeholder={placeholder}
        classNames={{ drawerContent: "h-min" }}
      >
        <div className="h-full max-h-[400px] py-3 bg-white overflow-auto">
          {airlines?.map((airline) => {
            const isChecked = !!selectedAirlines.find(
              (item) => item === airline.id,
            );

            return (
              <label
                key={airline.id}
                htmlFor={airline.name}
                className="flex flex-row items-center gap-3 px-7 py-4"
              >
                <p className="flex-1">{airline.name}</p>
                <Checkbox
                  id={airline.name}
                  className="rounded-[4px]"
                  checked={isChecked}
                  onCheckedChange={() => onCheckedChange(airline.id)}
                />
              </label>
            );
          })}
        </div>
      </SelectDrawer>
    </div>
  );
}
