import { Check, ChevronRight, UserRound } from "lucide-react";

import { cn } from "@/lib/utils";

import { ApproverSelect } from "./approver-select";

interface ApproverInputProps {
  value: string;
  onSelect: (value: string) => unknown;
  placeholder?: string;
}

export function ApproverInput({
  onSelect,
  value,
  placeholder,
}: ApproverInputProps): React.JSX.Element {
  return (
    <ApproverSelect
      value={value}
      onSelect={onSelect}
      placeholder="Pilih Approver"
    >
      {({ onOpen, selected }) => (
        <div
          className="w-full rounded-sm p-4 border border-border flex items-center justify-between gap-4 cursor-pointer"
          style={{ boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)" }}
          onClick={onOpen}
        >
          <div className="flex items-center gap-2.5">
            <UserRound
              className={cn(
                "text-foreground h-8 w-8",
                value && selected?.emp_no === value && "text-primary",
              )}
            />
            <p
              className={cn(
                "text-xl text-foreground",
                value && selected?.emp_no === value && "text-primary",
              )}
            >
              {value && selected?.name}
              {!value && placeholder && placeholder}
            </p>
          </div>
          {selected?.emp_no !== value && (
            <div className="h-6 w-6 rounded-full border border-border flex items-center justify-center">
              <ChevronRight className="h-4 w-4 text-foreground" />
            </div>
          )}
          {value && selected?.emp_no === value && (
            <Check className="text-green-500 h-6 w-6" />
          )}
        </div>
      )}
    </ApproverSelect>
  );
}
