import qs from "qs";

import {
  AddUserRoleSchema,
  addUserRoleSchema,
  EditUserRoleSchema,
  editUserRoleSchema,
} from "@/features/backoffice-user-role/validators";
import { api } from "@/lib/api";
import { filterNonNull } from "@/utils/function";

import { BaseApiResponse } from "../shared/types";
import { BackOfficeUserRoleDetail } from "./types";
import { GetBackOfficeUserRoleListDto } from "./types/dto";

export class BackOfficeUserRoleApi {
  async list(
    dto?: GetBackOfficeUserRoleListDto,
  ): Promise<BaseApiResponse<BackOfficeUserRoleDetail[]>> {
    const strDto = qs.stringify(filterNonNull(dto), {
      encode: false,
    });
    const { data } = await api.get<BaseApiResponse<BackOfficeUserRoleDetail[]>>(
      `/backoffice/roles?${strDto}`,
    );

    return data;
  }

  update = async (
    dto: EditUserRoleSchema,
  ): Promise<BaseApiResponse<boolean>> => {
    const parsed = editUserRoleSchema.parse(dto);
    delete parsed.code; // TODO: temporary
    delete parsed.isActive; // TODO: temporary

    const data = await api.patch<BaseApiResponse<boolean>>(
      `/backoffice/roles/${dto.id}`,
      parsed,
    );

    return data.data;
  };

  add = async (dto: AddUserRoleSchema): Promise<BaseApiResponse<boolean>> => {
    const parsed = addUserRoleSchema.parse(dto);
    delete parsed.code; // TODO: temporary
    delete parsed.isActive; // TODO: temporary

    const data = await api.post<BaseApiResponse<boolean>>(
      `/backoffice/roles`,
      parsed,
    );

    return data.data;
  };
}
