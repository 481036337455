import {
  BackOfficeLoginSchema,
  backOfficeLoginSchema,
} from "@/features/backoffice-auth/validators";
import { api } from "@/lib/api";

import { BaseApiResponse } from "../shared/types";
import { BackOfficeLoginEntity } from "./types";

export class BackOfficeLoginApi {
  login = async (
    dto: BackOfficeLoginSchema,
  ): Promise<BaseApiResponse<BackOfficeLoginEntity>> => {
    const parsed = backOfficeLoginSchema.parse(dto);

    const data = await api.post<BaseApiResponse<BackOfficeLoginEntity>>(
      `/backoffice/auth/login`,
      parsed,
    );

    return data.data;
  };
}
