type BackOfficeRouteAlias = {
  name: string;
  route: string;
};

export const backOfficeRouteAliases: BackOfficeRouteAlias[] = [
  {
    name: "Dashboard",
    route: "/home",
  },
  {
    name: "Bookings",
    route: "/orders",
  },
  {
    name: "Positions",
    route: "/positions",
  },
  {
    name: "Companies",
    route: "/companies",
  },
  {
    name: "Classes",
    route: "/classes",
  },
  {
    name: "MasterCategories",
    route: "/classifications",
  },
  {
    name: "Invoices",
    route: "/invoices",
  },
  {
    name: "Users",
    route: "/users",
  },
  {
    name: "Roles",
    route: "/user-roles",
  },
  {
    name: "Roles Accesses",
    route: "/modules",
  },
  {
    name: "Accesses",
    route: "/access-modules",
  },
];
