import type { IconProps } from "../ui/icon";
import { Icon } from "../ui/icon";

export function ClassificationBoldIcon({
  className,
  size,
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size}>
      <g clipPath="url(#clip0_186_62)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.586 2.7572C15.9611 2.38226 16.4697 2.17163 17 2.17163C17.5303 2.17163 18.0389 2.38226 18.414 2.7572L21.243 5.5862C21.6179 5.96126 21.8286 6.46988 21.8286 7.0002C21.8286 7.53053 21.6179 8.03915 21.243 8.4142L18.414 11.2432C18.0389 11.6181 17.5303 11.8288 17 11.8288C16.4697 11.8288 15.9611 11.6181 15.586 11.2432L12.757 8.4142C12.3821 8.03915 12.1714 7.53053 12.1714 7.0002C12.1714 6.46988 12.3821 5.96126 12.757 5.5862L15.586 2.7572ZM9 3.0002C9.53043 3.0002 10.0391 3.21092 10.4142 3.58599C10.7893 3.96106 11 4.46977 11 5.0002V9.0002C11 9.53064 10.7893 10.0393 10.4142 10.4144C10.0391 10.7895 9.53043 11.0002 9 11.0002H5C4.46957 11.0002 3.96086 10.7895 3.58579 10.4144C3.21071 10.0393 3 9.53064 3 9.0002V5.0002C3 4.46977 3.21071 3.96106 3.58579 3.58599C3.96086 3.21092 4.46957 3.0002 5 3.0002H9ZM21 15.0002C21 14.4698 20.7893 13.9611 20.4142 13.586C20.0391 13.2109 19.5304 13.0002 19 13.0002H15C14.4696 13.0002 13.9609 13.2109 13.5858 13.586C13.2107 13.9611 13 14.4698 13 15.0002V19.0002C13 19.5306 13.2107 20.0393 13.5858 20.4144C13.9609 20.7895 14.4696 21.0002 15 21.0002H19C19.5304 21.0002 20.0391 20.7895 20.4142 20.4144C20.7893 20.0393 21 19.5306 21 19.0002V15.0002ZM9 13.0002C9.53043 13.0002 10.0391 13.2109 10.4142 13.586C10.7893 13.9611 11 14.4698 11 15.0002V19.0002C11 19.5306 10.7893 20.0393 10.4142 20.4144C10.0391 20.7895 9.53043 21.0002 9 21.0002H5C4.46957 21.0002 3.96086 20.7895 3.58579 20.4144C3.21071 20.0393 3 19.5306 3 19.0002V15.0002C3 14.4698 3.21071 13.9611 3.58579 13.586C3.96086 13.2109 4.46957 13.0002 5 13.0002H9Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_186_62">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </Icon>
  );
}
