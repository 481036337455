import { QueryKey } from "@lezzcode/query";
import { stringify } from "qs";

import { QueryKeyFactory } from "@/services/shared/query-key.factory";

export class BookingQueryKeyFactory extends QueryKeyFactory {
  constructor() {
    super("bookings");
  }

  tickets(): QueryKey {
    return [...this.all(), "tickets"];
  }

  previews(): QueryKey {
    return [...this.all(), "previews"];
  }

  preview(id: string, filters?: object): QueryKey {
    if (filters)
      return [
        ...this.previews(),
        id,
        { filters: stringify(filters, { encode: false }) },
      ];

    return [...this.previews(), id];
  }

  approvers() {
    return [...this.all(), "approvers"];
  }
}
