"use client";

import type { IconProps } from "../ui/icon";
import { Icon } from "../ui/icon";

export function PlaneIcon({ className, size }: IconProps): React.JSX.Element {
  return (
    <Icon viewBox="0 0 40 40" className={className} size={size}>
      <path d="M22.8081 24.3084L17.0747 29.4084L17.9547 33.4467L13.8081 37.5934L9.62973 31.0584L3.09473 26.88L7.24139 22.7334L11.4997 23.8334L16.3781 17.8784L6.09306 11.0217L10.8064 6.30671L23.6664 10.595L29.9881 4.27338C31.9397 2.32004 34.9631 2.17671 36.7381 3.95171C38.5131 5.72671 38.3681 8.74838 36.4164 10.7017L30.0947 17.0234L34.3797 29.8784L29.6664 34.5934L22.8081 24.3084Z" />
    </Icon>
  );
}
