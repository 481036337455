import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { SubmitHandler, useForm } from "react-hook-form";

import { BaseFooter } from "@/components/layout/footer/base-footer";
import { BaseNavHeader } from "@/components/layout/header/base-nav";
import { SuccessInfo } from "@/components/success-info";
import { Button } from "@/components/ui/button";
import { Form, FormField } from "@/components/ui/form";
import { TicketDetail } from "@/features/flight-booking-ticket/components/ticket-detail";
import { ApproverInput } from "@/features/flight-submit-ticket/components/approver-input";
import { UnverifiedPassenger } from "@/features/flight-submit-ticket/components/unverified-passenger";
import { VerifiedPassenger } from "@/features/flight-submit-ticket/components/verified-passenger";
import {
  FlightBookingSchema,
  flightBookingSchema,
} from "@/features/flight-submit-ticket/validator";
import { useDisclose } from "@/hooks/use-disclose";
import { Table as DetailTicket } from "@/lezztable/_generated/detail-ticket";
import { BookingFlightSchema } from "@/services/booking/dto";
import { useBookFlight } from "@/services/booking/hooks/use-book-flight";
import { useFlightStore } from "@/stores/use-flight-store";

export const Route = createFileRoute("/book/plane/list/$listId/submit/")({
  component: SubmitTicket,
});

function SubmitTicket() {
  const navigate = useNavigate();

  const { ticket, passengers, paymentType, reset } = useFlightStore();
  const { isOpen: isTicketOpen, setIsOpen: setIsTicketOpen } = useDisclose();

  const form = useForm<FlightBookingSchema>({
    resolver: zodResolver(flightBookingSchema),
    defaultValues: {
      Passengers: passengers,
      payer: paymentType,
    },
  });

  const { mutateAsync, isSuccess, isPending } = useBookFlight();

  const Passengers = form.watch("Passengers");

  const isSpdMissing = Passengers.reduce(
    (_res, item) => !item.requestNumber,
    false,
  );

  const onSubmit: SubmitHandler<FlightBookingSchema> = async (values) => {
    const cabinClass = ticket?.ClassObjects[0];

    await mutateAsync({
      approverId: values.approverId,
      Passengers: values.Passengers,
      flight: ticket as unknown as BookingFlightSchema["flight"],
      classId: cabinClass?.Id as string,
      payer: values.payer,
    });

    setTimeout(() => {
      navigate({ params: {}, search: {}, to: "/" });
    }, 1000);

    reset();
  };

  if (isSuccess)
    return (
      <SuccessInfo
        title="Pengajuan Sukses!"
        description="Mohon menunggu, kamu akan segera dialihkan menuju halaman selanjutnya"
        onRedirect={() => navigate({ to: "/" })}
      />
    );

  return (
    <div className="flex flex-col h-screen bg-white">
      <BaseNavHeader title="Form Ajukan Tiket" />
      <div className="flex-grow overflow-auto w-full flex flex-col items-center">
        <TicketDetail open={isTicketOpen} onOpenChange={setIsTicketOpen} />

        <div className="h-[200px] w-full px-4 py-5">
          <DetailTicket
            data={[ticket as object]}
            onClick={() => setIsTicketOpen(true)}
          />
        </div>

        <div className="w-full h-full flex flex-col px-4">
          <div className="flex flex-col h-full w-full ">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex flex-col gap-5 mb-5"
              >
                <div className="flex flex-col gap-3">
                  {Passengers.map((passenger, index) => {
                    return (
                      <div
                        key={passenger.IdNumber}
                        className="flex flex-col gap-1"
                      >
                        <p className="text-foreground">
                          Detail Penumpang {index + 1}
                        </p>
                        {!passenger.requestNumber && (
                          <UnverifiedPassenger
                            passenger={passenger}
                            onVerified={(requestNumber) =>
                              form.setValue(
                                `Passengers`,
                                Passengers.map((item) => {
                                  if (item.IdNumber !== passenger.IdNumber)
                                    return item;

                                  return { ...item, requestNumber };
                                }),
                                { shouldTouch: true, shouldValidate: true },
                              )
                            }
                          />
                        )}
                        {passenger.requestNumber && (
                          <VerifiedPassenger
                            passenger={passenger}
                            onDelete={() =>
                              form.setValue(
                                `Passengers`,
                                Passengers.map((item) => {
                                  if (item.IdNumber !== passenger.IdNumber)
                                    return item;

                                  return { ...item, requestNumber: "" };
                                }),
                                { shouldTouch: true, shouldValidate: true },
                              )
                            }
                          />
                        )}
                      </div>
                    );
                  })}
                  <FormField
                    control={form.control}
                    name="approverId"
                    render={({ field }) => (
                      <ApproverInput
                        value={field.value}
                        onSelect={field.onChange}
                        placeholder="Pilih Approver"
                      />
                    )}
                  />
                </div>
              </form>
            </Form>
          </div>
        </div>
      </div>
      <BaseFooter className="h-[8.25rem]">
        <Button
          type="button"
          onClick={form.handleSubmit(onSubmit)}
          className="w-full text-lg h-12 text-white font-bold"
          disabled={!form.formState.isValid || isPending || isSpdMissing}
          isLoading={isPending}
        >
          Ajukan Tiket
        </Button>

        <Button
          className="w-full text-lg h-12 text-white font-bold"
          variant="secondary"
          onClick={() =>
            navigate({
              to: "/book/plane/list/$listId",
              params: true,
              search: true,
            })
          }
        >
          Kembali
        </Button>
      </BaseFooter>
    </div>
  );
}
