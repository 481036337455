import { DialogClose, DialogProps } from "@radix-ui/react-dialog";
import { PlusIcon } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { BackofficeDialog } from "@/features/backoffice/components/backoffice-dialog";

import { useAddDialog } from "../hooks/use-add-dialog";

interface AddDialogProps extends DialogProps {}

export function AddDialog({ ...props }: AddDialogProps) {
  const {
    methods,
    handleSubmit,
    onSubmit,
    dialogOpen,
    setDialogOpen,
    isPending,
  } = useAddDialog();

  return (
    <BackofficeDialog
      title="Tambah User Role"
      button={
        <Button className="gap-2.5 h-11 px-[15px] py-2.5 text-base">
          <PlusIcon className="w-[22px] fill-white" />
          Tambah
        </Button>
      }
      triggerClassName="w-fit"
      open={dialogOpen}
      onOpenChange={setDialogOpen}
      {...props}
    >
      <div className="space-y-4">
        <Form {...methods}>
          <form
            className="flex flex-col gap-4 max-h-[50vh] overflow-auto"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-thumb-slate-500/40 scrollbar-track-slate-200 overflow-auto">
              <FormField
                control={methods.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="mb-4">
                    <FormLabel className="font-bold text-md">
                      Nama Role
                    </FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Masukkan nama role..." />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={methods.control}
                name="code"
                render={({ field }) => (
                  <FormItem className="mb-4">
                    <FormLabel className="font-bold text-md">Kode</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Masukkan kode..." />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex justify-between items-center space-x-2 mt-2">
              <DialogClose asChild>
                <Button
                  type="button"
                  variant="destructive"
                  className="w-full rounded-2xl text-white"
                >
                  Kembali
                </Button>
              </DialogClose>
              <Button
                type="submit"
                isLoading={isPending}
                disabled={!methods.formState.isValid || isPending}
                variant="default"
                className="w-full rounded-2xl"
              >
                Tambah
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </BackofficeDialog>
  );
}
