import * as u from "react";
import {
  Table as m,
  CustomLayout as a,
  Text as i,
  Image as f,
} from "@lezztable-demo/react";
import { ChevronDownCircle as x } from "lucide-react";
import { Fragment as D, jsx as e, jsxs as o } from "react/jsx-runtime";
var O = "1eb59725-ca1f-4e39-bc95-5a0082bb25bf",
  L = ({
    filters: d,
    sorts: p,
    groups: y,
    pagination: b,
    headers: l,
    queryBuilder: g,
    onClick: r,
    data: C,
    onAction: c,
    url: T,
  }) => {
    let h = u.useMemo(() => {
        if (l) return Object.entries(l).map(([n, s]) => ({ key: n, value: s }));
      }, [l]),
      t = u.useMemo(
        () => ({
          type: "static",
          columns: [
            {
              id: "02af20ec-bfde-4762-bfbf-08ab0b52f490",
              key: "Id",
              name: "Id",
              type: "SingleLineText",
              attributes: { isVisible: !0 },
            },
            {
              id: "81656f21-6664-4e19-8159-13f9b02b4129",
              key: "DepartDate",
              name: "DepartDates",
              type: "Date",
              attributes: {
                format: "ddd, DD MMM YYYY",
                isVisible: !0,
                dateFormat: "CUSTOM",
              },
            },
          ],
          attributes: {
            data: [
              {
                Id: "f725ff9c-03e5-4676-8517-f7eaa79fbb74~6389fdfa-67a4-48c0-8157-1b9832581f9f",
                Fare: 1477950,
                Note: null,
                Number: "JT 213",
                Origin: "KNO",
                Airline: 2,
                Aircraft: null,
                Duration: "02:20",
                FareType: null,
                ExtraData: null,
                PriceFare: null,
                ArriveDate: "2024-05-02",
                ArriveTime: "06:50",
                DepartDate: "2024-05-02",
                DepartTime: "04:30",
                Facilities: null,
                FlightType: "NonGds",
                GroupingId: null,
                AirlineName: "Lion",
                Destination: "CGK",
                IsAvailable: !0,
                ClassObjects: [
                  {
                    Id: "82db4012-92b2-4e1e-b325-4f63fda14004",
                    Tax: 0,
                    Code: "H",
                    Fare: 1477950,
                    Seat: 9,
                    Variant: 0,
                    Category: "Economy",
                    FlightId:
                      "f725ff9c-03e5-4676-8517-f7eaa79fbb74~6389fdfa-67a4-48c0-8157-1b9832581f9f",
                    ExtraData:
                      '{"Currency":"IDR","Pax":[{"Base":{"Code":"BASE_FARE","Currency":"IDR","Amount":1059000.0,"Total":1059000.0},"Currency":"IDR","Equivalent":null,"FareBasis":"HOW","NumberOfPax":1,"PassengerType":"1","PassengerTypeDisplay":"Adult","Taxes":[{"Code":"P4","Currency":"IDR","Amount":5000.0,"Total":5000.0},{"Code":"YQ","Currency":"IDR","Amount":152900.0,"Total":152900.0},{"Code":"D5","Currency":"IDR","Amount":127650.0,"Total":127650.0},{"Code":"ID","Currency":"IDR","Amount":133400.0,"Total":133400.0}],"Total":1477950.0,"PaxId":null,"OfferItemId":null}],"Total":1477950.0,"PenaltyAmount":0.0}|KNO~CGK~JT 213~04:30~2024-05-02~06:50~2024-05-02',
                    TransitTime: null,
                    ClassGroupId: null,
                    FareRuleKeys: null,
                    FareBasisCode: null,
                    SegmentSellKey: null,
                  },
                ],
                IsConnecting: !1,
                IsMultiClass: !1,
                TotalTransit: 0,
                FareBreakdowns: null,
                OriginTerminal: null,
                AirlineImageUrl:
                  "https://portalvhds11000v9mfhk0k.blob.core.windows.net/airline/JT-mail.png",
                OperatingNumber: null,
                AirportV2Details: [
                  {
                    City: "Jakarta",
                    Iata: "CGK",
                    Name: "Soekarno-Hatta International Airport",
                    IsGds: !1,
                    Country: "ID",
                    IsNonGds: !0,
                    Latitude: -6.1255698204,
                    Longitude: 106.65599823,
                    MaxOffset: "09:00:00",
                    MinOffset: "07:00:00",
                    CountryName: "Indonesia",
                    TimeZoneZdb: "Asia/Jakarta",
                  },
                  {
                    City: "Medan",
                    Iata: "KNO",
                    Name: "Kualanamu International Airport",
                    IsGds: !0,
                    Country: "ID",
                    IsNonGds: !0,
                    Latitude: 3.64177,
                    Longitude: 98.885307,
                    MaxOffset: "09:00:00",
                    MinOffset: "07:00:00",
                    CountryName: "Indonesia",
                    TimeZoneZdb: "Asia/Jakarta",
                  },
                  {
                    City: "Pekanbaru",
                    Iata: "PKU",
                    Name: "Sultan Syarif Kasim II International Airport",
                    IsGds: !0,
                    Country: "ID",
                    IsNonGds: !0,
                    Latitude: 0.460786014795303,
                    Longitude: 101.444999694824,
                    MaxOffset: "09:00:00",
                    MinOffset: "07:00:00",
                    CountryName: "Indonesia",
                    TimeZoneZdb: "Asia/Jakarta",
                  },
                ],
                IsOpenJawTransit: !1,
                ConnectingFlights: [],
                DestinationTerminal: null,
                OperatingAirlineName: null,
                OperatingAirlineImageUrl: null,
              },
            ],
          },
        }),
        [],
      );
    return e(D, {
      children: e(m, {
        filters: d ?? [],
        sorts: p ?? [],
        groups: y ?? [],
        pagination: b ?? {
          skip: 0,
          limit: 50,
          source: { key: "Limit", type: "header" },
        },
        queryBuilder: g,
        children: e(m.CustomList, {
          id: "e3b9881c-fcee-4063-9fb0-93ab7046ce91",
          columns: t.columns,
          type: t.type,
          attributes: { ...t.attributes, data: C ?? t.attributes.data },
          orientation: { size: 124, type: "Vertical" },
          children: ({ row: n, style: s }) =>
            o("div", {
              style: {
                cursor: r ? "pointer" : "default",
                ...s,
                border: "1px solid rgba(0,0,0,.1)",
                height: "124px",
                display: "flex",
                padding: "0 1rem",
                borderRadius: "0.75rem",
                flexDirection: "column",
                justifyContent: "center",
                backgroundColor: "#EAF5FE",
              },
              onClick: () => {
                r && r({ record: n });
              },
              children: [
                o(a, {
                  style: {
                    width: "100%",
                    display: "flex",
                    borderBottom: "1px solid #EBEBF0",
                    paddingBottom: "16px",
                    justifyContent: "space-between",
                  },
                  children: [
                    o(a, {
                      style: {
                        gap: "13px",
                        height: "100%",
                        display: "flex",
                        flexGrow: "1",
                        alignItems: "center",
                      },
                      children: [
                        o(a, {
                          style: {
                            width: "fit-content",
                            height: "fit-content",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          },
                          children: [
                            e(i, {
                              style: { fontSize: "18px" },
                              value: "{{ Origin }}",
                              record: n,
                              columns: t.columns,
                            }),
                            e(i, {
                              value: "{{ DepartTime }}",
                              record: n,
                              columns: t.columns,
                            }),
                          ],
                        }),
                        o(a, {
                          style: {
                            width: "fit-content",
                            height: "fit-content",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center",
                          },
                          children: [
                            e(i, {
                              style: { color: "#939393", fontSize: "12px" },
                              value: "{{ Duration }}",
                              record: n,
                              columns: t.columns,
                            }),
                            e(f.Avatar, {
                              style: { width: "70px", height: "8px" },
                              url: "https://i.imgur.com/JneqCxK.png",
                              record: n,
                              columns: t.columns,
                            }),
                            e(i, {
                              style: { color: "#939393", fontSize: "12px" },
                              value: "Direct",
                              record: n,
                              columns: t.columns,
                            }),
                          ],
                        }),
                        o(a, {
                          style: {
                            width: "fit-content",
                            height: "fit-content",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          },
                          children: [
                            e(i, {
                              style: { fontSize: "18px" },
                              value: "{{ Destination }}",
                              record: n,
                              columns: t.columns,
                            }),
                            e(i, {
                              value: "{{ ArriveTime }}",
                              record: n,
                              columns: t.columns,
                            }),
                          ],
                        }),
                      ],
                    }),
                    e(a, {
                      style: {
                        height: "fit-content",
                        display: "flex",
                        flexGrow: "1",
                        justifyContent: "end",
                      },
                      children: e(i, {
                        style: { color: "#0065B3", fontSize: "20px" },
                        value: "{{ DepartDate }}",
                        record: n,
                        columns: t.columns,
                      }),
                    }),
                  ],
                }),
                o(a, {
                  style: {
                    width: "100%",
                    height: "fit-content",
                    display: "flex",
                    padding: "4px 0px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  },
                  children: [
                    o(a, {
                      style: {
                        gap: "10px",
                        width: "100%",
                        height: "fit-content",
                        display: "flex",
                        flexGrow: "1",
                        alignItems: "center",
                      },
                      children: [
                        e(f.Avatar, {
                          style: { width: "24px", height: "24px" },
                          url: "{{ AirlineImageUrl }}",
                          record: n,
                          columns: t.columns,
                        }),
                        e(i, {
                          value:
                            "{{ AirlineName }} - {{ ClassObjects.[0].Category }}",
                          record: n,
                          columns: t.columns,
                        }),
                      ],
                    }),
                    o(a, {
                      style: {
                        gap: "8px",
                        width: "fit-content",
                        height: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      },
                      children: [
                        e(i, {
                          value: "Detail",
                          record: n,
                          columns: t.columns,
                        }),
                        e(x, {
                          size: 24,
                          color: "#cacaca",
                          onClick: (I) => {
                            c?.["Icon 1"] && c["Icon 1"](I, { record: n });
                          },
                        }),
                      ],
                    }),
                  ],
                }),
                " ",
              ],
            }),
        }),
      }),
    });
  };
export { O as TABLE_ID, L as Table };
