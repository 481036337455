import { useMutation } from "@lezzcode/query";

import { api } from "@/lib/api";

import { PatchApprovalStatusDto } from "../types/dto";
import { ApprovalEntity } from "../types/entity";

const patchApprovalStatus = async (id: string, dto: PatchApprovalStatusDto) => {
  const { data } = await api.patch(`/approval/${id}`, dto);
  return data;
};

export const usePatchApprovalStatus = (id: string) => {
  return useMutation<ApprovalEntity, Error, PatchApprovalStatusDto>({
    mutationFn: (dto) => patchApprovalStatus(id, dto),
  });
};
