import React, { useEffect, useMemo } from "react";

import { formatToRupiah } from "@/lib/utils";
import { Route } from "@/routes/_backoffice-other-layout/backoffice/invoices/$invoiceId";
import { useGetBackOfficeInvoiceDetail } from "@/services/backoffice-invoice/hooks/use-get-backoffice-invoice-detail";
import { decodeFromBinary } from "@/utils/function";

export function InvoiceDetail(): React.JSX.Element {
  const { invoiceId } = Route.useParams();

  const decodedInvoiceId = useMemo(() => {
    return [decodeFromBinary(invoiceId)];
  }, [invoiceId]);

  const { mutateAsync, data } = useGetBackOfficeInvoiceDetail(decodedInvoiceId);

  useEffect(() => {
    mutateAsync({
      no: decodedInvoiceId,
    });
  }, [decodedInvoiceId, invoiceId, mutateAsync]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "50px",
        padding: "20px",
        fontSize: "12px",
        color: "#3f3f3f",
        border: "1px solid #e5e7eb",
        borderRadius: "12px",
      }}
    >
      {/* Header */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img src="/pnm-travel-logo.webp" alt="PNM Travel Logo" />
        <div>
          <p style={{ fontSize: "32px", fontWeight: 600, marginBottom: "5px" }}>
            INVOICE
          </p>
        </div>
      </div>

      {/* Infos */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ minWidth: "275px" }}>
          <p style={{ fontSize: "14px", fontWeight: 700, marginBottom: "5px" }}>
            Kepada Yth
          </p>
          <p style={{ fontSize: "14px", fontWeight: 700, marginBottom: "5px" }}>
            {data?.data.company}
          </p>
          <p style={{ fontSize: "14px", fontWeight: 700, marginBottom: "5px" }}>
            Menara PNM,
          </p>
          <p style={{ fontSize: "14px", fontWeight: 700, marginBottom: "5px" }}>
            Kuningan Center, Jl. Kuningan Mulia, Karet, Setiabudi, Jakarta
            Selatan. 12920
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            minWidth: "325px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "6px",
              height: "15px",
            }}
          >
            <p style={{ flex: 1 }}>Tanggal</p>
            <p>:</p>
            <p style={{ flex: 2, fontWeight: 700, textAlign: "end" }}>
              {data?.data.createdAt}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "6px",
              height: "15px",
            }}
          >
            <p style={{ flex: 1 }}>No Invoice</p>
            <p>:</p>
            <p
              style={{
                flex: 2,
                fontWeight: 700,
                textAlign: "end",
                whiteSpace: "nowrap",
              }}
            >
              {decodeFromBinary(invoiceId)}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "6px",
              height: "15px",
            }}
          >
            <p style={{ flex: 1 }}>Account</p>
            <p>:</p>
            <p style={{ flex: 2, fontWeight: 700, textAlign: "end" }}>
              PT MITRA TEKNO MADANI
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          width: "233px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "6px",
            height: "15px",
          }}
        >
          <p style={{ flex: 1 }}>Telp</p>
          <p>:</p>
          <p style={{ flex: 2, fontWeight: 700, textAlign: "end" }}>
            021-2511404/021-2511405
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "6px",
            height: "15px",
          }}
        >
          <p style={{ flex: 1 }}>Fax</p>
          <p>:</p>
          <p
            style={{
              flex: 2,
              fontWeight: 700,
              textAlign: "end",
              whiteSpace: "nowrap",
            }}
          >
            -
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "6px",
            height: "15px",
          }}
        >
          <p style={{ flex: 1 }}>Email</p>
          <p>:</p>
          <p style={{ flex: 2, fontWeight: 700, textAlign: "end" }}>-</p>
        </div>
      </div>

      {/* Detail Penumpang */}
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <table
          style={{
            fontSize: "14px",
            width: "100%",
            textAlign: "left",
            borderCollapse: "collapse",
          }}
        >
          <tr style={{ fontWeight: 600, borderBottom: "1px solid #3f3f3f" }}>
            <th style={{ paddingBottom: "10px" }}>No</th>
            <th style={{ paddingBottom: "10px" }}>Nama</th>
            <th style={{ paddingBottom: "10px" }}>Kode Booking</th>
            <th style={{ paddingBottom: "10px" }}>Asal - Tujuan</th>
            <th style={{ paddingBottom: "10px" }}>Tanggal Pergi</th>
            <th style={{ paddingBottom: "10px" }}>Nomor ID</th>
            <th style={{ paddingBottom: "10px", textAlign: "end" }}>
              Harga Tiket
            </th>
          </tr>
          {data?.data.passengerDetails?.map((passenger, index) => (
            <tr
              key={index}
              style={{
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              <td
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  width: "40px",
                }}
              >
                {index + 1}
              </td>
              <td style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                {passenger.name}
              </td>
              <td style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                {passenger.code}
              </td>
              <td style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                {passenger.origin} - {passenger.destination}
              </td>
              <td style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                {passenger.departTime}
              </td>
              <td style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                {passenger.noId}
              </td>
              <td
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  textAlign: "end",
                }}
              >
                {formatToRupiah(passenger.price!)}
              </td>
            </tr>
          ))}
          <tr
            style={{
              borderTop: "1px solid #3f3f3f",
            }}
          >
            <td
              style={{
                fontWeight: 600,
                paddingTop: "10px",
                whiteSpace: "nowrap",
              }}
            >
              TOTAL HARGA
            </td>
            <td style={{ paddingTop: "10px" }}></td>
            <td style={{ paddingTop: "10px" }}></td>
            <td style={{ paddingTop: "10px" }}></td>
            <td style={{ paddingTop: "10px" }}></td>
            <td style={{ paddingTop: "10px" }}></td>
            <td
              style={{ fontWeight: 600, paddingTop: "10px", textAlign: "end" }}
            >
              {formatToRupiah(data?.data.totalPrice ?? 0)}
            </td>
          </tr>
        </table>
      </div>

      {/* More Infos */}
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <p style={{ fontSize: "14px", fontWeight: 600, paddingTop: "10px" }}>
            Keterangan
          </p>
          <p style={{ fontSize: "14px", paddingTop: "10px" }}>
            Bukti transfer dikirim melalui email wajdi@pnmdigi.co.id atau
            dewi@pnmdigi.co.id UP Bagian Operasional & Keuangan dan WAJIB
            menuliskan NOMOR INVOICE dan NAMA ACCOUNT di bukti transfer tersebut
            seperti tertera dalam INVOICE PT MITRA TEKNO MADANI Pembayaran
            dianggap sah apabila cek/giro dapat diklliringkan atau diterima di
            rekening tsb.
          </p>
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            width: "100%",
            justifyContent: "space-between",
            height: "175px",
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{ fontSize: "12px", fontWeight: 600, paddingTop: "10px" }}
            >
              Tanda Tangan
            </p>
            <p
              style={{ fontSize: "14px", fontWeight: 600, paddingTop: "10px" }}
            >
              Asri Joni Hutasuhut
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
