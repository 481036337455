import {
  createFileRoute,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";

import { LiveAreaIcon } from "@/components/icons/live-area";
import { BaseNavHeader } from "@/components/layout/header/base-nav";
import { Button } from "@/components/ui/button";
import { FlightBookingFilters } from "@/features/flight-booking/components/filters";
import { UseFlightBookingPage } from "@/features/flight-booking/hooks/use-flight-booking-page";
import { DateRange } from "@/features/flight-booking-ticket/components/date-range";
import { SubmitTicket } from "@/features/flight-booking-ticket/components/submit-ticket";
import { flightSearchSchema } from "@/features/flight-booking-ticket/validators";
import { Table as AvailableFlightList } from "@/lezztable/_generated/available-flights";
import { FlightTicketEntity } from "@/services/flight-ticket/types";
import { useFlightStore } from "@/stores/use-flight-store";
import { getLezztableURL } from "@/utils/function";

export const Route = createFileRoute("/book/plane/list/")({
  component: Index,
  validateSearch: (search) => flightSearchSchema.parse(search),
});

function Index() {
  const navigate = useNavigate({ from: "/book/plane/list/" });
  const search = useSearch({ from: "/book/plane/list/" });
  const { setTicket } = useFlightStore();

  const { form, isDateChanged, setIsDateChanged, title, tokens } =
    UseFlightBookingPage();

  const preferredAirlines = form.watch("airlines");
  const priceSort = form.watch("price");

  useEffect(() => {
    navigate({
      to: "/book/plane/list",
      search: { ...search, PreferredAirlines: preferredAirlines },
    });
  }, [navigate, preferredAirlines, search]);

  return (
    <div className="flex flex-col h-screen bg-white">
      <BaseNavHeader title={`${search.Origin} - ${search.Destination}`} />
      <div className="h-12 w-full bg-primary flex items-center justify-center gap-2">
        <LiveAreaIcon className="fill-white" />
        <p className="text-white">{title}</p>
      </div>
      <div className="flex-grow flex flex-col gap-4 px-3 overflow-x-hidden">
        <DateRange onDateChange={setIsDateChanged} />
        <FormProvider {...form}>
          <FlightBookingFilters />
        </FormProvider>
        <p className="text-xs text-muted-foreground text-center">
          Sudah termasuk pajak dan biaya admin
        </p>
        <AvailableFlightList
          sorts={[{ key: "price", value: priceSort }]}
          queryBuilder={{
            pagination: () => ({ type: "query", value: "" }),
            sorts: (currentSorts) => {
              return {
                type: "query",
                value: `price=${currentSorts[0].value}`,
              };
            },
          }}
          url={getLezztableURL() + "/airports/flights"}
          headers={{
            "Flight-Query": JSON.stringify({
              Routes: [
                {
                  Origin: search.Origin,
                  Destination: search.Destination,
                  DepartDate: search.DepartDate,
                },
              ],
              Adult: search.Adult,
              Child: search.Child,
              Infant: search.Infant,
              FareType: "Default",
              PreferredAirlines: search.PreferredAirlines,
              CabinClasses: [search.CabinClass],
            }),
            "save-search": String(!isDateChanged),
            Authorization: `Bearer ${tokens.access_token}`,
          }}
          onClick={({ record }) => {
            const ticket = record as FlightTicketEntity;
            setTicket(ticket);
          }}
        />
        <SubmitTicket />
      </div>
      <div className="h-[4.5rem] w-full bg-white border-t border-border bottom-0 flex items-center justify-center p-4">
        <Button
          className="w-full text-lg h-12 text-white font-bold"
          variant="secondary"
          onClick={() => navigate({ to: "/book/plane" })}
        >
          Kembali
        </Button>
      </div>
    </div>
  );
}
