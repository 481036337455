"use client";

import { Icon, type IconProps } from "../ui/icon";

export function MingcuteFilterFillIcon({
  className,
  size,
  ...props
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 4.125C2.75 3.76033 2.89487 3.41059 3.15273 3.15273C3.41059 2.89487 3.76033 2.75 4.125 2.75H17.875C18.2397 2.75 18.5894 2.89487 18.8473 3.15273C19.1051 3.41059 19.25 3.76033 19.25 4.125V6.03717C19.2499 6.52336 19.0567 6.9896 18.7128 7.33333L13.75 12.2962V19.1015C13.75 19.2734 13.7061 19.4424 13.6224 19.5926C13.5387 19.7427 13.418 19.869 13.2718 19.9594C13.1256 20.0497 12.9587 20.1012 12.787 20.109C12.6153 20.1167 12.4444 20.0804 12.2907 20.0035L8.88342 18.3003C8.69309 18.2052 8.53302 18.0589 8.42114 17.8779C8.30927 17.6969 8.25001 17.4883 8.25 17.2755V12.2962L3.28717 7.33333C2.94333 6.9896 2.7501 6.52336 2.75 6.03717V4.125Z"
      />
    </Icon>
  );
}
