"use client";

import type { IconProps } from "../ui/icon";
import { Icon } from "../ui/icon";

export function LiveAreaIcon({
  className,
  size,
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size}>
      <path d="M6.37464 17.0635L3.08746 11.8812C2.90621 11.5955 3.0552 11.2159 3.38244 11.1298L4.17458 10.9214C4.47745 10.8417 4.80015 10.9085 5.0465 11.1019L6.62192 12.3385L9.5197 11.562L5.21304 5.80549C4.97953 5.49336 5.17715 5.04592 5.56514 5.0083L7.2669 4.8433C7.5478 4.81606 7.82712 4.90878 8.03597 5.09858L13.8664 10.3973L18.4544 9.16795C19.6547 8.84633 20.8885 9.55865 21.2101 10.759C21.5317 11.9593 20.8194 13.193 19.6191 13.5147L6.37464 17.0635Z" />
      <path d="M3 19.9999C2.44772 19.9999 2 20.4476 2 20.9999C2 21.5522 2.44772 21.9999 3 21.9999H21C21.5523 21.9999 22 21.5522 22 20.9999C22 20.4476 21.5523 19.9999 21 19.9999H3Z" />
    </Icon>
  );
}
