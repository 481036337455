import { ReloadIcon } from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";

export interface LoadingComponentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  isLoading: boolean;
}

export function LoadingComponent({
  className,
  isLoading,
  children,
  ...props
}: LoadingComponentProps) {
  if (isLoading)
    return (
      <div
        className={cn(
          className,
          "flex justify-center items-center h-full w-full",
        )}
        {...props}
      >
        <ReloadIcon className="h-4 w-10 animate-spin" />
      </div>
    );

  return children;
}
