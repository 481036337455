import dayjs from "dayjs";
import Cookies from "js-cookie";

import { BackOfficeApi } from "../backoffice/api";
import { DigiServiceApi } from "../digi/api";
import { SaveTokenSchema, saveTokenSchema } from "./dto";

export class UserAuthServiceApi {
  private digiService: DigiServiceApi = new DigiServiceApi();
  private backOfficeService: BackOfficeApi = new BackOfficeApi();

  authenticate = this.digiService.authenticate;
  refreshToken = this.digiService.refreshToken;
  profile = this.digiService.profile;

  saveToken = async (dto: SaveTokenSchema) => {
    const parsed = await saveTokenSchema.parse(dto);
    const extendedExpToken = dayjs(parsed.exp_token);

    Cookies.set("access_token", parsed.access_token, {
      expires: extendedExpToken.toDate(),
    });
    Cookies.set("refresh_token", parsed.refresh_token);
    Cookies.set("exp_token", parsed.exp_token, {
      expires: extendedExpToken.toDate(),
    });
  };

  getToken = () => {
    const access_token = Cookies.get("access_token") ?? "";
    const refresh_token = Cookies.get("refresh_token") ?? "";
    const exp_token = Cookies.get("exp_token") ?? "";

    return { access_token, refresh_token, exp_token };
  };

  logout = () => {
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
  };

  //backoffice
  backOfficeProfile = this.backOfficeService.profile;
}
