import { z } from "zod";

export const backOfficeInvoiceExportSchema = z.object({
  from: z.union([z.string(), z.date()]).optional(),
  to: z.union([z.string(), z.date()]).optional(),
  company: z.string().optional(),
});

export type BackOfficeInvoiceExportSchema = z.infer<
  typeof backOfficeInvoiceExportSchema
>;
