import { cn } from "@/lib/utils";

type SectionContainerProps = React.HTMLAttributes<HTMLDivElement>;

export function SectionContainer({
  className,
  children,
  ...rest
}: SectionContainerProps): React.JSX.Element {
  return (
    <div className={cn("px-4 py-3 bg-white", className)} {...rest}>
      {children}
    </div>
  );
}
