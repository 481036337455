import {
  createFileRoute,
  Link,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";
import { useCallback, useState } from "react";

import { BaseFooter } from "@/components/layout/footer/base-footer";
import { BaseNavHeader } from "@/components/layout/header/base-nav";
import { SuccessInfo } from "@/components/success-info";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { OrderSummary } from "@/features/flight-ticket-checkout/components/order-summary";
import { PassengerInformations } from "@/features/flight-ticket-checkout/components/passenger-informations";
import { PaymentMethods } from "@/features/flight-ticket-checkout/components/payment-methods";
import { PriceDetail } from "@/features/flight-ticket-checkout/components/price-detail";
import { PaymentMethodTypeEnum } from "@/features/flight-ticket-checkout/types";
import { useGetBooking } from "@/services/booking/hooks/use-get-booking";
import { useGetDigiPayTransaction } from "@/services/booking/hooks/use-get-digi-pay-transaction";
import { BookingStatusEnum } from "@/services/booking/types/entity";
// import { useGetDigiBalance } from "@/services/digi/hooks/use-get-digi-balance";
import { UserAuthServiceApi } from "@/services/user-auth/api";

type SearchParams = {
  isSuccess?: boolean;
  bookingId: string;
};

export const Route = createFileRoute("/checkout/")({
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    return {
      isSuccess: Boolean(search.isSuccess),
      bookingId: String(search.bookingId),
    };
  },
  component: Index,
});

function Index() {
  const navigate = useNavigate();
  const search = useSearch({ from: "/checkout/" });

  const { data: bookingData } = useGetBooking(search.bookingId);
  // const { data: digiBalanceData } = useGetDigiBalance();
  const { mutateAsync: mutateGetDigiPayReqId } = useGetDigiPayTransaction();

  const [paymentMethod, setPaymentMethod] = useState<
    PaymentMethodTypeEnum | undefined
  >(PaymentMethodTypeEnum.SALDO_DIGI);

  // const isBalanceNotEnough =
  //   Number(bookingData?.booking.transactions[0].amount) >
  //   Number(digiBalanceData?.balance);
  const isTransactionNotReady = !bookingData?.booking?.price;
  const isDisablePayBooking =
    !paymentMethod ||
    isTransactionNotReady ||
    bookingData?.booking?.status !== BookingStatusEnum.Booking_RESERVED;
  const isTransactionError =
    bookingData?.booking?.status === BookingStatusEnum.ERROR;

  const onPayBooking = useCallback(async () => {
    const userAuthService = new UserAuthServiceApi();
    const initTokens = userAuthService.getToken();

    const { data } = await mutateGetDigiPayReqId(
      String(bookingData?.booking.id),
    );

    const reqId = data.externalId;
    const redirectUrl = `${import.meta.env.VITE_PNM_DIGI_CONNECT_URL}/transaction?access_token=${initTokens.access_token}&req_id=${reqId}`;

    window.open(redirectUrl);
  }, [bookingData?.booking.id, mutateGetDigiPayReqId]);

  if (search.isSuccess)
    return (
      <SuccessInfo
        title="Sukses Pembayaran"
        description="Mohon menunggu, kamu akan segera dialihkan menuju halaman beranda"
        onRedirect={() => navigate({ to: "/" })}
      />
    );

  return (
    <div className="flex flex-col h-screen bg-muted overflow-auto">
      <BaseNavHeader title="Checkout" />
      <div className="flex-grow flex flex-col gap-2 mt-2">
        <OrderSummary />
        <PassengerInformations />
        <PriceDetail />
        <PaymentMethods
          paymentMethod={paymentMethod}
          onChange={setPaymentMethod}
        />
        <div className="flex-1 bg-white" />
      </div>
      <BaseFooter className="h-[8.25rem]">
        <Link params search={{ ...search, success: true }} className="w-full">
          <Button
            disabled={isDisablePayBooking}
            className="w-full text-lg h-12 text-white font-bold"
            onClick={onPayBooking}
          >
            Bayar
          </Button>
        </Link>
        <Link to="/" className="w-full">
          <Button
            className="w-full text-lg h-12 text-white font-bold"
            variant="secondary"
          >
            Kembali
          </Button>
        </Link>
      </BaseFooter>
      <Dialog open={isTransactionError}>
        <DialogContent isClosable={false}>
          <DialogHeader>
            <DialogTitle>Gagal Mendapatkan Harga Dari Maskapai</DialogTitle>
            <DialogDescription>
              Pemesanan tidak dapat dilanjutkan, silahkan kembali ke halaman
              utama.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Link to="/">
              <Button>Kembali ke halaman utama</Button>
            </Link>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
