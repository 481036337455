import dayjs from "dayjs";

import { DownloadBoldIcon } from "@/components/icons/download-bold";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { BookingCodeDetail } from "@/features/flight-ticket/components/detail/booking-code-detail";
import { DurationDetail } from "@/features/flight-ticket/components/detail/duration-detail";
import { FlightDetail } from "@/features/flight-ticket/components/detail/flight-detail";
import { PassengerDetails } from "@/features/flight-ticket/components/detail/passenger-detail";
import { ETicket } from "@/features/flight-ticket/components/e-ticket";
import { useDisclose } from "@/hooks/use-disclose";
import { useToast } from "@/hooks/use-toast";
import { useDownloadBookingTicket } from "@/services/booking/hooks/use-download-booking-ticket";
import {
  BookingEntity,
  BookingStatusEnum,
} from "@/services/booking/types/entity";
import { FlightTicketEntity } from "@/services/flight-ticket/types";

interface MyTicketDetailProps {
  booking?: BookingEntity;
}

export function MyTicketDetail({
  booking,
}: MyTicketDetailProps): React.JSX.Element {
  const { toast } = useToast();

  const {
    isOpen: isDrawerOpen,
    onClose: onDrawerClose,
    onOpen: onDrawerOpen,
    setIsOpen: setIsDrawerOpen,
  } = useDisclose();

  const {
    isOpen: isModalOpen,
    onClose: onModalClose,
    onOpen: onModalOpen,
    setIsOpen: setIsModalOpen,
  } = useDisclose();

  const { mutateAsync: downloadBookingTicket, isPending } =
    useDownloadBookingTicket(booking?.booking.id as string, {
      onError: () => {
        toast({
          title: "E-Ticket gagal diunduh!",
          variant: "destructive",
        });
      },
      onSuccess: () => {
        toast({
          title: "E-Ticket berhasil diunduh!",
          variant: "success",
        });
        onModalClose();
        onDrawerOpen();
      },
    });

  const status = booking?.reservation.Status;
  const flightDetails = booking?.reservation.FlightDetails;
  const segments = booking?.reservation.Segments;
  const passengers = booking?.reservation.Passengers;
  const payments = booking?.reservation.Payments;
  const price = booking?.booking?.price ?? 0;
  const isDisabled = status !== BookingStatusEnum.TICKETED;

  return (
    <div className="flex-1 h-12">
      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
          <DrawerTrigger disabled={isDisabled} className="w-full h-full">
            <Button
              disabled={isDisabled}
              className="w-full h-full text-lg font-bold"
              onClick={onDrawerOpen}
            >
              Lihat Detail Tiket
            </Button>
          </DrawerTrigger>
          <DrawerContent>
            <DrawerHeader className="flex flex-col items-center border-b gap-[5px] p-[14px]">
              <DrawerTitle className="text-xl text-primary">
                Detail Penerbangan
              </DrawerTitle>
              <DrawerDescription className="text-sm text-muted-foreground-secondary">
                Ticket Issued :{" "}
                {booking?.reservation?.Ticketed
                  ? dayjs(booking?.reservation.Ticketed).format(
                      "ddd, DD MMM YYYY",
                    )
                  : "-"}
              </DrawerDescription>
            </DrawerHeader>
            <div className="h-[42rem] overflow-auto">
              <DurationDetail
                origin={`${segments?.[0].Origin} (${passengers?.[0].Ssrs[0]?.OriginCode})`}
                destination={`${segments?.[0].Destination} (${passengers?.[0].Ssrs[0]?.DestinationCode})`}
                duration={flightDetails?.[0].Duration as string}
              />
              <FlightDetail
                className="border-b h-min"
                ticket={flightDetails?.[0] as FlightTicketEntity}
              />
              <BookingCodeDetail
                value={booking?.reservation.BookingCode as string}
              />
              <PassengerDetails passengers={passengers} />
              <div className="flex flex-row justify-between items-center px-2.5 py-3 border-b">
                <p className="text-base">Total Harga</p>
                <p className="text-primary text-2xl font-semibold">
                  {payments?.[0].Currency}{" "}
                  {price.toLocaleString("id", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </p>
              </div>
            </div>
            <DrawerFooter className="gap-3 px-4 py-3">
              <DialogTrigger onClick={onModalOpen}>
                <Button
                  variant="default"
                  className="items-center gap-2.5 h-12 w-full text-lg text-white"
                  onClick={onModalOpen}
                >
                  Download E-Tiket
                  <DownloadBoldIcon className="fill-white" />
                </Button>
              </DialogTrigger>

              <Button
                variant="secondary"
                className="h-12 w-full text-lg text-white"
                onClick={onDrawerClose}
              >
                Kembali
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
        <DialogContent className="max-w-[465px] gap-0 p-0 rounded-lg">
          <DialogHeader className="p-6 border-b">
            <DialogTitle className="text-primary text-2xl font-medium">
              E-Ticket
            </DialogTitle>
          </DialogHeader>
          <div className="max-h-[600px] overflow-auto border-b">
            <ETicket bookingId={booking?.booking.id} />
          </div>
          <DialogFooter className="flex-row gap-3 px-4 py-3">
            <Button
              variant="secondary"
              className="h-12 flex-1 text-lg text-white"
              onClick={onModalClose}
            >
              Kembali
            </Button>
            <Button
              isLoading={isPending}
              variant="default"
              className="h-12 flex-1 items-center gap-2.5 text-lg text-white"
              onClick={() => downloadBookingTicket()}
            >
              Download E-Tiket
              <DownloadBoldIcon className="fill-white" />
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
