import { BookingEntity } from "@/services/booking/types/entity";
import { API_WHATSAPP_URL, WHATSAPP_CONTACT_NUMBER } from "@/utils/constant";

export function getRefundRescheduleWhatsappUrl(
  booking?: BookingEntity,
): string {
  const bookingCode = booking?.reservation.BookingCode;
  const fullName = `${booking?.reservation.Passengers[0].FirstName} ${booking?.reservation.Passengers[0].LastName}`;

  const text = encodeURI(
    `Permisi Kak, saya ingin bertanya mengenai prosedur refund/reschedule nih. \n\n Kode Booking: ${bookingCode} \n Nama: ${fullName}`,
  );

  return `${API_WHATSAPP_URL}?phone=${WHATSAPP_CONTACT_NUMBER}&text=${text}`;
}
