import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from "./drawer";
import { Button } from "./button";
import { CheckCircle, X } from "lucide-react";
import { cn } from "@/lib/utils";
import { createStore, useStore } from "zustand";

interface State {
  value: string;
  displayValue: string;
  setValue: (value: State["value"]) => void;
  setDisplayValue: (displayValue: string) => void;
}

const selectDrawerStore = (defaultValues: Partial<State>) =>
  createStore<State>((set) => ({
    value: "",
    setValue(value) {
      set({ value });
    },
    displayValue: "",
    setDisplayValue(displayValue) {
      set({ displayValue });
    },
    ...defaultValues,
  }));

const SelectDrawerContext = createContext<ReturnType<
  typeof selectDrawerStore
> | null>(null);

const useSelectDrawerStore = () => {
  const store = useContext(SelectDrawerContext);

  if (store === null) {
    throw new Error("no select drawer context provider");
  }

  return useStore(store);
};

interface RootProps extends PropsWithChildren {
  placeholder?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => unknown;
  value?: string;
  onChange?: (value: string) => unknown;
  classNames?: Record<string, string>;
  isClosable?: boolean;
}

export function SelectDrawer({
  children,
  value,
  ...rest
}: RootProps): React.JSX.Element {
  const store = useMemo(() => {
    return selectDrawerStore({ value });
  }, [value]);

  return (
    <SelectDrawerContext.Provider value={store}>
      <Root {...rest}>{children}</Root>
    </SelectDrawerContext.Provider>
  );
}

function Root({
  children,
  placeholder,
  onOpenChange,
  open,
  onChange,
  classNames,
  isClosable,
}: RootProps): React.JSX.Element {
  const { value } = useSelectDrawerStore();
  const onChangeRef = useRef<RootProps["onChange"]>();

  useEffect(() => {
    if (!onChange) return;

    onChangeRef.current = onChange;
  }, [value, onChange]);

  useEffect(() => {
    if (!onChangeRef.current) return;

    onChangeRef.current(value);
  }, [value]);

  return (
    <Drawer open={open} onOpenChange={onOpenChange} dismissible={!isClosable}>
      <DrawerContent className={cn("h-[566px]", classNames?.drawerContent)}>
        <div className="mx-auto w-full">
          {Boolean(placeholder) && (
            <>
              <DrawerHeader className="p-2.5 border-b border-b-muted relative">
                <DrawerTitle className="text-xl font-semibold text-primary">
                  {placeholder}
                </DrawerTitle>
              </DrawerHeader>
              {isClosable && (
                <X
                  className="absolute m-2.5 top-0 right-0"
                  onClick={() => onOpenChange && onOpenChange(false)}
                />
              )}
            </>
          )}
          <div className="bg-muted">{children}</div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

interface SelectDrawerGroupProps extends PropsWithChildren {
  title: string;
  onClear?: React.MouseEventHandler<HTMLButtonElement>;
}

export function SelectDrawerGroup({
  title,
  children,
  onClear,
}: SelectDrawerGroupProps): React.JSX.Element {
  return (
    <div className="flex flex-col w-full py-1.5 bg-white">
      <div className="flex items-center justify-between px-3">
        <h3 className="text-foreground text-xl font-bold mb-3">{title}</h3>
        {onClear && (
          <Button
            size="sm"
            variant="ghost"
            className="text-destructive-foreground font-semibold -mr-3"
            onClick={onClear}
          >
            Hapus
          </Button>
        )}
      </div>
      <ul className="last:border-none">{children}</ul>
    </div>
  );
}

interface SelectDrawerItemProps
  extends Omit<React.HTMLProps<HTMLLIElement>, "onClick"> {
  onClear?: React.MouseEventHandler<HTMLButtonElement>;
  value: State["value"];
}

export function SelectDrawerItem({
  children,
  onClear,
  className,
  value,
  ...rest
}: SelectDrawerItemProps): React.JSX.Element {
  const {
    setValue,
    setDisplayValue,
    value: storeValue,
  } = useSelectDrawerStore();

  const isActive = storeValue === value;

  return (
    <li
      className={cn(
        "h-12 w-full cursor-pointer hover:bg-primary/5 flex items-center justify-between border-b border-b-muted last-of-type:border-none px-3",
        isActive && "bg-primary/5",
        className,
      )}
      {...rest}
      onClick={() => {
        if (storeValue === value) {
          setDisplayValue("");
          return setValue("");
        }

        let displayValue = "";
        if (Array.isArray(children)) {
          displayValue = children
            .filter((child) => typeof child === "string")
            .join("");
        }
        setDisplayValue(displayValue);
        setValue(value);
      }}
    >
      <div>{children}</div>
      <div className="flex items-center gap-2">
        {isActive && <CheckCircle className="h-4 w-4 text-green-500" />}
        {onClear && (
          <Button
            size="icon"
            className="h-4 w-4"
            variant="ghost"
            onClick={onClear}
          >
            <X />
          </Button>
        )}
      </div>
    </li>
  );
}
