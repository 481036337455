import { useMutation, useQueryClient } from "@lezzcode/query";

import { EditClassificationSchema } from "@/features/backoffice-classification/validators";
import { toast } from "@/hooks/use-toast";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeClassificationApi } from "../api";
import { BackOfficeClassificationQueryKeyFactory } from "../query-key.factory";

export const usePatchBackOfficeClassification = () => {
  const api = new BackOfficeClassificationApi();
  const queryClient = useQueryClient();
  const queryKeyFactory = new BackOfficeClassificationQueryKeyFactory();

  return useMutation<BaseApiResponse<boolean>, Error, EditClassificationSchema>(
    {
      mutationFn: api.update,
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: queryKeyFactory.lists(),
        });
      },
      onSuccess: async () => {
        toast({
          title: "Update klasifikasi berhasil!",
          variant: "success",
        });
      },
      onError: async () => {
        await queryClient.cancelQueries({
          queryKey: queryKeyFactory.lists(),
        });
        toast({
          title: `Update klasifikasi gagal!`,
          variant: "destructive",
        });
      },
    },
  );
};
