import { BackOfficeOrderStatus } from "../types";

export const backOfficeOrderStatus: BackOfficeOrderStatus[] = [
  "TICKETED",
  "REFUNDED",
  "RESCHEDULED",
  "RESERVED",
  "SAVED",
  "COMPLETED",
  "REJECTED",
];
