import { useMutation } from "@lezzcode/query";

import { BackOfficeOrderExportSchema } from "@/features/backoffice-order/validators/export-order";
import { toast } from "@/hooks/use-toast";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeOrderApi } from "../api";

export const useExportBackOfficeOrder = () => {
  const api = new BackOfficeOrderApi();

  return useMutation<
    BaseApiResponse<boolean>,
    Error,
    BackOfficeOrderExportSchema
  >({
    mutationFn: api.export,
    onError: async ({ message }) => {
      toast({
        title: `Export pemesanan gagal: ${message}`,
        variant: "destructive",
      });
    },
  });
};
