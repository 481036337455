import { useMutation, useQueryClient } from "@lezzcode/query";

import { BackOfficeInvoiceExportPreviewSchema } from "@/features/backoffice-invoice/validators/export-invoice-preview";
import { toast } from "@/hooks/use-toast";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeInvoiceApi } from "../api";
import { BackOfficeInvoiceQueryKeyFactory } from "../query-key.factory";
import { BackOfficeInvoiceExportPreview } from "../types";

export const useGetBackOfficeInvoiceDetail = (no: string[]) => {
  const api = new BackOfficeInvoiceApi();
  const queryKeyFactory = new BackOfficeInvoiceQueryKeyFactory();
  const queryClient = useQueryClient();

  return useMutation<
    BaseApiResponse<BackOfficeInvoiceExportPreview>,
    Error,
    BackOfficeInvoiceExportPreviewSchema
  >({
    mutationFn: () => api.preview(no),
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeyFactory.list(),
      });
    },
    onError: async () => {
      await queryClient.cancelQueries({
        queryKey: queryKeyFactory.list(),
      });
      toast({
        title: `Export invoice gagal!`,
        variant: "destructive",
      });
    },
  });
};
