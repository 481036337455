interface EmptyStateProps {
  renderEmpty?: React.JSX.Element | string;
}

export function EmptyState({
  renderEmpty,
}: EmptyStateProps): React.JSX.Element {
  if (typeof renderEmpty === "object") return renderEmpty;

  return <p className="text-muted-foreground text-sm">{renderEmpty}</p>;
}
