import { useQuery } from "@lezzcode/query";
import { AxiosError } from "axios";

import { BackOfficeCompanyQueryFilters } from "@/features/backoffice-company/validators/query-filters";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeCompanyApi } from "../api";
import { BackOfficeCompanyQueryKeyFactory } from "../query-key.factory";
import { BackOfficeCompanyDetail } from "../types";

export const useFindBackOfficeCompany = (
  filters?: BackOfficeCompanyQueryFilters,
) => {
  const api = new BackOfficeCompanyApi();
  const queryKeyFactory = new BackOfficeCompanyQueryKeyFactory();

  return useQuery<BaseApiResponse<BackOfficeCompanyDetail[]>, AxiosError>({
    queryKey: queryKeyFactory.list(filters),
    queryFn: () => {
      return api.list({
        search: filters?.search,
      });
    },
  });
};
