import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

export function BaseFooter({
  className,
  children,
  ...rest
}: HTMLAttributes<HTMLDivElement>): React.JSX.Element {
  return (
    <div
      className={cn(
        "h-[4.5rem] w-full bg-white border-t border-border sticky bottom-0 flex flex-col items-center justify-center gap-3 py-3 px-4",
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
}
