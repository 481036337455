import * as u from "react";
import {
  Table as m,
  CustomLayout as n,
  Text as a,
  Image as d,
} from "@lezztable-demo/react";
import { ChevronDownCircle as T } from "lucide-react";
import { Fragment as w, jsx as t, jsxs as s } from "react/jsx-runtime";
var I = "34656fef-ca23-4171-b71f-f670fb6ba1a2",
  A = ({
    filters: p,
    sorts: f,
    groups: y,
    pagination: b,
    headers: o,
    queryBuilder: g,
    onClick: r,
    data: D,
    onAction: c,
    url: h,
  }) => {
    let x = u.useMemo(() => {
        if (o) return Object.entries(o).map(([i, l]) => ({ key: i, value: l }));
      }, [o]),
      e = u.useMemo(
        () => ({
          type: "api",
          columns: [
            {
              id: "827203a0-b69c-47d8-9516-0e90e3ddfc34",
              key: "Id",
              name: "Id",
              type: "SingleLineText",
              attributes: { isVisible: !0 },
            },
            {
              id: "dd02f04a-7481-4e93-8075-4eedd0eb3cb3",
              key: "Fare",
              name: "Fare",
              type: "Number",
              attributes: { format: "Integer", isVisible: !0 },
            },
            {
              id: "582fc29b-3ddc-47bd-aa81-aa007668a8fa",
              key: "Duration",
              name: "Duration",
              type: "SingleLineText",
              attributes: { isVisible: !0 },
            },
            {
              id: "ce911dd1-8d13-4dca-9289-62476ccbf25c",
              key: "Origin",
              name: "Origin",
              type: "SingleLineText",
              attributes: { isVisible: !0 },
            },
            {
              id: "988b3df1-7f15-425a-ba3c-101f109a4836",
              key: "Destination",
              name: "Destination",
              type: "SingleLineText",
              attributes: { isVisible: !0 },
            },
            {
              id: "c151ea11-4dd4-4f79-b4aa-8c7c18298a4a",
              key: "DepartTime",
              name: "DepartTime",
              type: "SingleLineText",
              attributes: { isVisible: !0 },
            },
            {
              id: "0ea6e96f-428a-4b80-ab1c-0300512e1eee",
              key: "ArriveTime",
              name: "ArriveTime",
              type: "SingleLineText",
              attributes: { isVisible: !0 },
            },
            {
              id: "15bb3c7b-ad48-4c61-b6ef-a741668df98c",
              key: "AirlineName",
              name: "AirlineName",
              type: "SingleLineText",
              attributes: { isVisible: !0 },
            },
          ],
          attributes: {
            url: "https://api-travel.lezzserver.com/airports/flights",
            path: "data.Schedules.[0].Flights",
            method: "get",
            headers: [
              {
                id: "f90bc87c-ce34-4191-a4ae-d091282accd4",
                key: "flight-query",
                value:
                  '{"Routes":[{"Origin":"KNO","Destination":"CGK","DepartDate":"2024-4-2"}],"Adult":1,"Child":0,"Infant":0,"FareType":"Default","PreferredAirlines":[2],"CabinClass":"Economy","CabinClasses":["Economy","Business","First"]}',
              },
            ],
          },
        }),
        [],
      );
    return t(w, {
      children: t(m, {
        filters: p ?? [],
        sorts: f ?? [],
        groups: y ?? [],
        pagination: b ?? {
          skip: 0,
          limit: 50,
          source: { key: "Limit", type: "header" },
        },
        queryBuilder: g,
        children: t(m.CustomList, {
          id: "e3b9881c-fcee-4063-9fb0-93ab7046ce91",
          columns: e.columns,
          type: e.type,
          attributes: {
            ...e.attributes,
            headers: x ?? e.attributes.headers,
            url: h ?? e.attributes.url,
          },
          orientation: { size: 140, type: "Vertical" },
          children: ({ row: i, style: l }) =>
            s("div", {
              style: {
                cursor: r ? "pointer" : "default",
                ...l,
                border: "1px solid rgba(0,0,0,.1)",
                height: "124px",
                display: "flex",
                padding: "0 1rem",
                borderRadius: "0.75rem",
                flexDirection: "column",
                justifyContent: "center",
              },
              onClick: () => {
                r && r({ record: i });
              },
              children: [
                s(n, {
                  style: {
                    width: "100%",
                    display: "flex",
                    borderBottom: "1px solid #EBEBF0",
                    paddingBottom: "16px",
                    justifyContent: "space-between",
                  },
                  children: [
                    s(n, {
                      style: {
                        gap: "13px",
                        height: "100%",
                        display: "flex",
                        flexGrow: "1",
                        alignItems: "center",
                      },
                      children: [
                        s(n, {
                          style: {
                            width: "fit-content",
                            height: "fit-content",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          },
                          children: [
                            t(a, {
                              style: { fontSize: "18px" },
                              value: "{{ Origin }}",
                              record: i,
                              columns: e.columns,
                            }),
                            t(a, {
                              value: "{{ DepartTime }}",
                              record: i,
                              columns: e.columns,
                            }),
                          ],
                        }),
                        s(n, {
                          style: {
                            width: "fit-content",
                            height: "fit-content",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center",
                          },
                          children: [
                            t(a, {
                              style: { color: "#939393", fontSize: "12px" },
                              value: "{{ Duration }}",
                              record: i,
                              columns: e.columns,
                            }),
                            t(d.Avatar, {
                              style: { width: "70px", height: "8px" },
                              url: "https://i.imgur.com/JneqCxK.png",
                              record: i,
                              columns: e.columns,
                            }),
                            t(a, {
                              style: { color: "#939393", fontSize: "12px" },
                              value: "Direct",
                              record: i,
                              columns: e.columns,
                            }),
                          ],
                        }),
                        s(n, {
                          style: {
                            width: "fit-content",
                            height: "fit-content",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          },
                          children: [
                            t(a, {
                              style: { fontSize: "18px" },
                              value: "{{ Destination }}",
                              record: i,
                              columns: e.columns,
                            }),
                            t(a, {
                              value: "{{ ArriveTime }}",
                              record: i,
                              columns: e.columns,
                            }),
                          ],
                        }),
                      ],
                    }),
                    t(n, {
                      style: {
                        height: "fit-content",
                        display: "flex",
                        flexGrow: "1",
                        justifyContent: "end",
                      },
                      children: t(a, {
                        style: { color: "#0065B3", fontSize: "20px" },
                        value: "{{ Fare }}",
                        record: i,
                        columns: e.columns,
                      }),
                    }),
                  ],
                }),
                s(n, {
                  style: {
                    width: "100%",
                    height: "fit-content",
                    display: "flex",
                    padding: "4px 0px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  },
                  children: [
                    s(n, {
                      style: {
                        gap: "10px",
                        width: "100%",
                        height: "fit-content",
                        display: "flex",
                        flexGrow: "1",
                        alignItems: "center",
                      },
                      children: [
                        t(d.Avatar, {
                          style: { width: "24px", height: "24px" },
                          url: "{{ AirlineImageUrl }}",
                          record: i,
                          columns: e.columns,
                        }),
                        t(a, {
                          value:
                            "{{ AirlineName }} - {{ ClassObjects.[0].Category }}",
                          record: i,
                          columns: e.columns,
                        }),
                      ],
                    }),
                    s(n, {
                      style: {
                        gap: "8px",
                        width: "fit-content",
                        height: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      },
                      children: [
                        t(a, {
                          value: "Detail",
                          record: i,
                          columns: e.columns,
                        }),
                        t(T, {
                          size: 24,
                          color: "#cacaca",
                          onClick: (C) => {
                            c?.["Icon 1"] && c["Icon 1"](C, { record: i });
                          },
                        }),
                      ],
                    }),
                  ],
                }),
                " ",
              ],
            }),
        }),
      }),
    });
  };
export { I as TABLE_ID, A as Table };
