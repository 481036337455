import { useQuery } from "@lezzcode/query";
import { AxiosError } from "axios";

import { BackOfficePositionQueryFilters } from "@/features/backoffice-position/validators/query-filters";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficePositionApi } from "../api";
import { BackOfficePositionQueryKeyFactory } from "../query-key.factory";
import { BackOfficePositionDetail } from "../types";

export const useFindBackOfficePosition = (
  filters?: BackOfficePositionQueryFilters,
) => {
  const api = new BackOfficePositionApi();
  const queryKeyFactory = new BackOfficePositionQueryKeyFactory();

  return useQuery<BaseApiResponse<BackOfficePositionDetail[]>, AxiosError>({
    queryKey: queryKeyFactory.list(filters),
    queryFn: () => {
      return api.list({
        search: filters?.search,
      });
    },
  });
};
