import { DialogClose } from "@radix-ui/react-dialog";
import { CellContext } from "@tanstack/react-table";
import dayjs from "dayjs";

import { FileSearchOutlineIcon } from "@/components/icons/file-search-outline";
import { StatusBadge } from "@/components/shared/table/badges/status-badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { BackofficeDialog } from "@/features/backoffice/components/backoffice-dialog";

import { BackOfficeOrderColumn } from "../constants/table-column";
import { useDetailDialog } from "../hooks/use-detail-dialog";
import { StatusBadgeOrder } from "./status-badge";

export function DetailDialog({
  row,
}: Readonly<CellContext<BackOfficeOrderColumn, unknown>>) {
  const { onClick, dialogOpen, setDialogOpen, selectedValue } =
    useDetailDialog();

  return (
    <BackofficeDialog
      title="Detail Pemesanan"
      button={
        <StatusBadge
          status="DETAIL"
          onClick={() => onClick(row.original)}
          icon={FileSearchOutlineIcon}
        ></StatusBadge>
      }
      triggerClassName="w-full"
      open={dialogOpen}
      onOpenChange={setDialogOpen}
    >
      <div className="space-y-4">
        <div className="flex flex-col gap-4 max-h-[50vh] overflow-auto">
          <div className="scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-thumb-slate-500/40 scrollbar-track-slate-200 overflow-auto">
            <div className="mb-4 space-y-2">
              <Label className="font-bold text-md">Nama Penumpang</Label>
              <Input
                disabled={true}
                value={selectedValue?.Passengers.map(
                  (passenger) => `${passenger.FirstName} ${passenger.LastName}`,
                ).join(",")}
              />
            </div>

            <div className="flex items-end space-x-2">
              <div className="mb-4 space-y-2 flex-1">
                <Label className="font-bold text-md">Tanggal</Label>
                <Input
                  disabled={true}
                  value={dayjs(selectedValue?.departTime).format("DD-MM-YYYY")}
                />
              </div>
              <div className="mb-4 space-y-2 flex-1">
                <Input
                  disabled={true}
                  value={dayjs(selectedValue?.arriveTime).format("DD-MM-YYYY")}
                />
              </div>
            </div>

            <div className="flex items-end space-x-2">
              <div className="mb-4 space-y-2 flex-1">
                <Label className="font-bold text-md">Kode Booking</Label>
                <Input disabled={true} value={selectedValue?.code} />
              </div>

              <div className="mb-4 space-y-2 flex-1">
                <Label className="font-bold text-md">Maskapai</Label>
                <Input disabled={true} value={selectedValue?.airline} />
              </div>
            </div>

            <div className="flex items-end space-x-2">
              <div className="mb-4 space-y-2 flex-1">
                <Label className="font-bold text-md">Berangkat</Label>
                <Input
                  disabled={true}
                  value={dayjs(selectedValue?.departTime).format("HH:mm")}
                />
              </div>

              <div className="mb-4 space-y-2 flex-1">
                <Label className="font-bold text-md">Sampai</Label>
                <Input
                  disabled={true}
                  value={dayjs(selectedValue?.arriveTime).format("HH:mm")}
                />
              </div>
            </div>

            <div className="flex items-end space-x-2">
              <div className="mb-4 space-y-2 flex-1">
                <Label className="font-bold text-md">Asal</Label>
                <Input disabled={true} value={selectedValue?.origin} />
              </div>

              <div className="mb-4 space-y-2 flex-1">
                <Label className="font-bold text-md">Tujuan</Label>
                <Input disabled={true} value={selectedValue?.destination} />
              </div>
            </div>

            <div className="flex items-end space-x-2">
              <div className="mb-4 space-y-2 flex-1">
                <Label className="font-bold text-md">Harga Tiket</Label>
                <Input
                  type="number"
                  disabled={true}
                  value={selectedValue?.price}
                />
              </div>

              <div className="mb-4 space-y-2 flex-1">
                <Label className="font-bold text-md">Biaya Reschedule</Label>
                <Input
                  type="number"
                  disabled={true}
                  value={selectedValue?.rescheduleCharge}
                />
              </div>
            </div>

            <div className="flex items-end space-x-2">
              <div className="mb-4 space-y-2 flex-1">
                <Label className="font-bold text-md">Jumlah Refund</Label>
                <Input
                  type="number"
                  disabled={true}
                  value={selectedValue?.refundAmount}
                />
              </div>

              <div className="mb-4 space-y-2 flex-1">
                <Label className="font-bold text-md">Status</Label>
                <StatusBadgeOrder status={selectedValue?.status} />
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center space-x-2 mt-2">
            <DialogClose asChild>
              <Button
                variant="destructive"
                className="w-full rounded-2xl text-white"
              >
                Kembali
              </Button>
            </DialogClose>
          </div>
        </div>
      </div>
    </BackofficeDialog>
  );
}
