import { useQuery } from "@lezzcode/query";

import { FlightPassengerEntity } from "@/services/flight-passenger/types";
import { BaseApiResponse } from "@/services/shared/types";

import { BookingServiceApi } from "../api";
import { ApproverEntity } from "../types/entity";
import { BookingQueryKeyFactory } from "../utils/query-key.factory";

export const useFindBookingApprover = (passengers: FlightPassengerEntity[]) => {
  const api = new BookingServiceApi();
  const queryKeyFactory = new BookingQueryKeyFactory();

  return useQuery<BaseApiResponse<ApproverEntity[]>, Error>({
    queryKey: queryKeyFactory.approvers(),
    queryFn: () => api.findBookingApprover(passengers),
  });
};
