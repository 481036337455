"use client";

import { Icon, type IconProps } from "../ui/icon";

export function SearchOutlineIcon({
  className,
  size,
  ...props
}: IconProps): React.JSX.Element {
  return (
    <Icon className={className} size={size} {...props}>
      <path d="M10.2001 17.4C8.77605 17.4 7.38398 16.9777 6.19993 16.1866C5.01589 15.3954 4.09303 14.2709 3.54808 12.9553C3.00312 11.6397 2.86053 10.192 3.13835 8.79535C3.41617 7.39869 4.10191 6.11577 5.10886 5.10883C6.11581 4.1019 7.39874 3.41616 8.79542 3.13835C10.1921 2.86054 11.6398 3.00312 12.9554 3.54807C14.2711 4.09302 15.3956 5.01586 16.1867 6.1999C16.9779 7.38393 17.4002 8.77597 17.4002 10.2C17.398 12.1089 16.6388 13.939 15.2889 15.2888C13.9391 16.6386 12.109 17.3979 10.2001 17.4ZM10.2001 4.8C9.13206 4.8 8.08801 5.11671 7.19997 5.71007C6.31194 6.30343 5.6198 7.14679 5.21108 8.13351C4.80236 9.12023 4.69542 10.206 4.90378 11.2535C5.11215 12.301 5.62645 13.2632 6.38167 14.0184C7.13688 14.7736 8.09908 15.2879 9.14659 15.4962C10.1941 15.7046 11.2799 15.5977 12.2666 15.1889C13.2533 14.7802 14.0967 14.0881 14.6901 13.2001C15.2834 12.3121 15.6002 11.268 15.6002 10.2C15.5987 8.76827 15.0293 7.39559 14.0169 6.3832C13.0045 5.37082 11.6318 4.80143 10.2001 4.8Z" />
      <path d="M20.1002 21C19.8615 20.9999 19.6326 20.9051 19.4639 20.7363L15.8638 17.1363C15.6999 16.9666 15.6092 16.7392 15.6112 16.5032C15.6133 16.2673 15.7079 16.0415 15.8748 15.8747C16.0417 15.7078 16.2674 15.6131 16.5034 15.6111C16.7394 15.609 16.9667 15.6998 17.1364 15.8637L20.7365 19.4637C20.8623 19.5896 20.948 19.7499 20.9827 19.9245C21.0174 20.099 20.9996 20.28 20.9315 20.4444C20.8634 20.6088 20.7481 20.7494 20.6001 20.8483C20.4521 20.9472 20.2782 21 20.1002 21Z" />
    </Icon>
  );
}
