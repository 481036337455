import { api } from "@/lib/api";

import { BaseApiResponse } from "../shared/types";
import { AuthenticateEntity, ProfileEntity } from "./types";
import {
  AuthenticateDtoSchema,
  authenticateDtoSchema,
  RefreshTokenSchema,
  refreshTokenSchema,
  VerifySpdSchema,
  verifySpdSchema,
} from "./types/dto";

export class DigiServiceApi {
  private baseUrl: string = "/digi";

  getBalance = async () => {
    const { data } = await api.get(`${this.baseUrl}/balance`);

    return data.data;
  };

  verifySpd = async (
    dto: VerifySpdSchema,
  ): Promise<BaseApiResponse<boolean>> => {
    const parsed = await verifySpdSchema.parse(dto);

    const data = await api.post<BaseApiResponse<boolean>>(
      `${this.baseUrl}/verify-spd`,
      parsed,
    );

    return data.data;
  };

  authenticate = async (
    dto: AuthenticateDtoSchema,
  ): Promise<BaseApiResponse<AuthenticateEntity>> => {
    const parsed = await authenticateDtoSchema.parse(dto);

    const data = await api.post<BaseApiResponse<AuthenticateEntity>>(
      `${this.baseUrl}/authenticate`,
      parsed,
      { headers: { Authorization: undefined } },
    );

    return data.data;
  };

  refreshToken = async (
    dto: RefreshTokenSchema,
  ): Promise<BaseApiResponse<AuthenticateEntity>> => {
    const parsed = await refreshTokenSchema.parse(dto);

    const data = await api.post<BaseApiResponse<AuthenticateEntity>>(
      `${this.baseUrl}/refresh-token`,
      parsed,
    );

    return data.data;
  };

  profile = async (): Promise<BaseApiResponse<ProfileEntity>> => {
    const data = await api.get<BaseApiResponse<ProfileEntity>>(
      `${this.baseUrl}/profile`,
    );

    return data.data;
  };
}
