import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import { useAddBackOfficeModule } from "@/services/backoffice-module/hooks/use-add-backoffice-module";

import { AddModuleSchema, addModuleSchema } from "../validators";

export const useAddDialog = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const methods = useForm<AddModuleSchema>({
    defaultValues: {},
    mode: "onChange",
    resolver: zodResolver(addModuleSchema),
  });

  const { handleSubmit } = methods;

  const { mutateAsync, isPending } = useAddBackOfficeModule();

  const onSubmit = useCallback(
    async (data: AddModuleSchema) => {
      await mutateAsync(data);
      setDialogOpen(false);
    },
    [mutateAsync],
  );

  return {
    methods,
    handleSubmit,
    onSubmit,
    dialogOpen,
    setDialogOpen,
    isPending,
  };
};
