import { useMutation } from "@lezzcode/query";

import { BaseApiResponse } from "@/services/shared/types";

import { BookingServiceApi } from "../api";
import { BookingFlightSchema } from "../dto";
import { BookingEntity } from "../types/entity";

export const useBookFlight = () => {
  const api = new BookingServiceApi();

  return useMutation<
    BaseApiResponse<BookingEntity>,
    Error,
    BookingFlightSchema
  >({
    mutationFn: api.bookingFlight,
  });
};
