import qs from "qs";

import {
  AddClassificationSchema,
  addClassificationSchema,
  EditClassificationSchema,
  editClassificationSchema,
} from "@/features/backoffice-classification/validators";
import { api } from "@/lib/api";
import { filterNonNull } from "@/utils/function";

import { BaseApiResponse } from "../shared/types";
import { BackOfficeClassificationDetail } from "./types";
import { GetBackOfficeClassificationListDto } from "./types/dto";

export class BackOfficeClassificationApi {
  async list(
    dto?: GetBackOfficeClassificationListDto,
  ): Promise<BaseApiResponse<BackOfficeClassificationDetail[]>> {
    const strDto = qs.stringify(filterNonNull(dto), {
      encode: false,
    });
    const { data } = await api.get<
      BaseApiResponse<BackOfficeClassificationDetail[]>
    >(`/backoffice/master-category?${strDto}`);

    return data;
  }

  update = async (
    dto: EditClassificationSchema,
  ): Promise<BaseApiResponse<boolean>> => {
    const parsed = editClassificationSchema.parse(dto);

    const mappedData = {
      ...parsed,
      airlines: parsed.airlines.map((item) => item.value),
    };

    const data = await api.patch<BaseApiResponse<boolean>>(
      `/backoffice/master-category/${dto.id}`,
      mappedData,
    );

    return data.data;
  };

  add = async (
    dto: AddClassificationSchema,
  ): Promise<BaseApiResponse<boolean>> => {
    const parsed = addClassificationSchema.parse(dto);

    const mappedData = {
      ...parsed,
      airlines: parsed.airlines.map((item) => item.value),
    };

    const data = await api.post<BaseApiResponse<boolean>>(
      `/backoffice/master-category`,
      mappedData,
    );

    return data.data;
  };
}
