import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import { useAddBackOfficeClassification } from "@/services/backoffice-classification/hooks/use-add-backoffice-classification";

import {
  AddClassificationSchema,
  addClassificationSchema,
} from "../validators";

export const useAddDialog = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const methods = useForm<AddClassificationSchema>({
    defaultValues: {
      airlines: [],
    },
    mode: "onChange",
    resolver: zodResolver(addClassificationSchema),
  });

  const { handleSubmit } = methods;

  const { mutateAsync, isPending } = useAddBackOfficeClassification();

  const onSubmit = useCallback(
    async (data: AddClassificationSchema) => {
      await mutateAsync(data);
      setDialogOpen(false);
    },
    [mutateAsync],
  );

  return {
    methods,
    handleSubmit,
    onSubmit,
    dialogOpen,
    setDialogOpen,
    isPending,
  };
};
