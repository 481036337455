import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";

import { formatToRupiah } from "@/lib/utils";
import { BackOfficeInvoiceDetail } from "@/services/backoffice-invoice/types";

import { StatusBadgeInvoice } from "../components/status-badge";

export type BackOfficeInvoiceColumn = BackOfficeInvoiceDetail;

export const columns: ColumnDef<BackOfficeInvoiceColumn>[] = [
  {
    id: "no",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">NO</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">{row.index + 1}</div>
    ),
  },
  {
    accessorKey: "company",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">PERUSAHAAN</p>
      );
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.company ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "name",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">NAMA</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.name ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "no",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">NOMOR ID</p>
      );
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.no ?? row.original.booking.number ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "createdAt",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">TANGGAL</p>;
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.createdAt
          ? dayjs(row.original.createdAt).format("DD-MM-YYYY, HH:mm:ss")
          : "-"}
      </div>
    ),
  },
  {
    accessorKey: "payer",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">
          JENIS PEMESANAN
        </p>
      );
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {row.original.payer ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "price",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">HARGA TIKET</p>
      );
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {formatToRupiah(row.original.booking.ticketPrice)}
      </div>
    ),
  },
  {
    accessorKey: "price",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">SERVICE FEE</p>
      );
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {formatToRupiah(row.original.booking.serviceFee)}
      </div>
    ),
  },
  {
    accessorKey: "price",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">PLATFORM FEE</p>
      );
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {formatToRupiah(row.original.booking.platformFee)}
      </div>
    ),
  },
  {
    accessorKey: "price",
    header: () => {
      return (
        <p className="font-bold text-center whitespace-nowrap">TOTAL HARGA</p>
      );
    },
    cell: ({ row }) => (
      <div className="text-center whitespace-nowrap">
        {formatToRupiah(row.original.booking.price)}
      </div>
    ),
  },
  {
    accessorKey: "status",
    header: () => {
      return <p className="font-bold text-center whitespace-nowrap">STATUS</p>;
    },
    cell: ({ row }) => <StatusBadgeInvoice status={row.original.status} />,
  },
];
