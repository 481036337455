import { useQuery } from "@lezzcode/query";
import { AxiosError } from "axios";

import { BackOfficeUserQueryFilters } from "@/features/backoffice-user/validators/query-filters";
import { BaseApiResponse } from "@/services/shared/types";

import { BackOfficeUserApi } from "../api";
import { BackOfficeUserQueryKeyFactory } from "../query-key.factory";
import { BackOfficeUserDetail } from "../types";

export const useFindBackOfficeUser = (filters?: BackOfficeUserQueryFilters) => {
  const api = new BackOfficeUserApi();
  const queryKeyFactory = new BackOfficeUserQueryKeyFactory();

  return useQuery<BaseApiResponse<BackOfficeUserDetail[]>, AxiosError>({
    queryKey: queryKeyFactory.list(filters),
    queryFn: () => {
      return api.list({
        search: filters?.search,
      });
    },
  });
};
