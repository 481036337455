import { HeadlessDialog } from "@/components/headless-ui/dialog";
import { Form, FormSchema } from "@/lezzform/_generated/add-passenger";

interface AddPassengerDialogProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => unknown;
  defaultValues?: FormSchema;
  onSubmit?: (values: FormSchema) => unknown;
}

export function AddPassengerDialog({
  onOpenChange,
  open,
  defaultValues,
  onSubmit,
}: AddPassengerDialogProps) {
  return (
    <HeadlessDialog
      open={open}
      onClose={() => onOpenChange && onOpenChange(false)}
      title="Tambah Penumpang"
    >
      <Form
        onAction={{
          cancelButton: () => onOpenChange && onOpenChange(false),
        }}
        defaultValues={{ Title: "Mr", ...(defaultValues ?? {}) }}
        onSubmit={(form, values) => {
          form.reset({ Title: "Mr" });
          onSubmit && onSubmit(values);
          onOpenChange && onOpenChange(false);
        }}
      />
    </HeadlessDialog>
  );
}
