import qs from "qs";

import {
  AddClassSchema,
  addClassSchema,
  EditClassSchema,
  editClassSchema,
} from "@/features/backoffice-class/validators";
import { api } from "@/lib/api";
import { filterNonNull } from "@/utils/function";

import { BaseApiResponse } from "../shared/types";
import { BackOfficeClassDetail } from "./types";
import { GetBackOfficeClassListDto } from "./types/dto";

export class BackOfficeClassApi {
  async list(
    dto?: GetBackOfficeClassListDto,
  ): Promise<BaseApiResponse<BackOfficeClassDetail[]>> {
    const strDto = qs.stringify(filterNonNull(dto), {
      encode: false,
    });
    const { data } = await api.get<BaseApiResponse<BackOfficeClassDetail[]>>(
      `/backoffice/classes?${strDto}`,
    );

    return data;
  }

  update = async (dto: EditClassSchema): Promise<BaseApiResponse<boolean>> => {
    const parsed = editClassSchema.parse(dto);

    const data = await api.patch<BaseApiResponse<boolean>>(
      `/backoffice/classes/${dto.id}`,
      parsed,
    );

    return data.data;
  };

  add = async (dto: AddClassSchema): Promise<BaseApiResponse<boolean>> => {
    const parsed = addClassSchema.parse(dto);

    const data = await api.post<BaseApiResponse<boolean>>(
      `/backoffice/classes`,
      parsed,
    );

    return data.data;
  };
}
