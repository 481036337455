import { z } from "zod";

export const verifySpdSchema = z.object({
  spdNumber: z.coerce.string(),
  nip: z.string().min(9, "Masukkan 9 digit nomor NIP").optional(),
});

export type VerifySpdSchema = z.infer<typeof verifySpdSchema>;

export const authenticateDtoSchema = z.object({
  webviewToken: z.string(),
});
export type AuthenticateDtoSchema = z.infer<typeof authenticateDtoSchema>;

export const refreshTokenSchema = z.object({
  refreshToken: z.string(),
});
export type RefreshTokenSchema = z.infer<typeof refreshTokenSchema>;
