import { useQueryClient } from "@lezzcode/query";
import {
  createFileRoute,
  useNavigate,
  useParams,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { useCallback, useMemo } from "react";

import { BaseFooter } from "@/components/layout/footer/base-footer";
import { BaseNavHeader } from "@/components/layout/header/base-nav";
import { StatusWrapper } from "@/components/shared/status-wrapper";
import { SuccessInfo } from "@/components/success-info";
import { Button } from "@/components/ui/button";
import { ApprovalPassengerList } from "@/features/approval/components/approval-passenger-list";
import { AirlinesOverview } from "@/features/flight-ticket/components/overview/airlines-overview";
import { DurationOverview } from "@/features/flight-ticket/components/overview/duration-overview";
import { DateOverview } from "@/features/flight-ticket/components/overview/time-overview";
import { useToast } from "@/hooks/use-toast";
import { cn } from "@/lib/utils";
import { usePatchApprovalStatus } from "@/services/approval/hooks/use-patch-approval-status";
import { ApprovalStatusEnum } from "@/services/approval/types/dto";
import { useGetBooking } from "@/services/booking/hooks/use-get-booking";
import { BookingStatusEnum } from "@/services/booking/types/entity";
import { UserQueryKeyFactory } from "@/services/user/utils/query-key.factory";
import { formatDuration } from "@/utils/function";

type SearchParams = {
  success?: boolean;
  bookingId?: string;
};

export const Route = createFileRoute("/approvals/$approvalId/")({
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    return {
      success: Boolean(search.success),
      bookingId: String(search.bookingId),
    };
  },
  component: Index,
});

function Index() {
  const navigate = useNavigate();
  const { history } = useRouter();
  const params = useParams({ from: "/approvals/$approvalId/" });
  const search = useSearch({ from: "/approvals/$approvalId/" });
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const userQueryKeyFactory = useMemo(() => new UserQueryKeyFactory(), []);

  const { data: bookingData, isLoading } = useGetBooking(
    search.bookingId as string,
  );

  const flightDetails = bookingData?.reservation.FlightDetails;
  const segments = bookingData?.reservation.Segments;
  const passengers = bookingData?.reservation.Passengers;
  let status = bookingData?.reservation.Status;

  if (bookingData?.booking?.status === "EXPIRED") {
    status = BookingStatusEnum.EXPIRED;
  }

  const { mutateAsync: acceptApproval, isPending: isAccepting } =
    usePatchApprovalStatus(params.approvalId);

  const { mutateAsync: rejectApproval, isPending: isRejecting } =
    usePatchApprovalStatus(params.approvalId);

  const onAccept = useCallback(async () => {
    await acceptApproval({ status: ApprovalStatusEnum.APPROVED });
    await queryClient.invalidateQueries({
      queryKey: userQueryKeyFactory.counter(),
    });
    navigate({
      params,
      search: { success: true, bookingId: search.bookingId },
    });
  }, [
    acceptApproval,
    navigate,
    params,
    queryClient,
    search.bookingId,
    userQueryKeyFactory,
  ]);

  const onReject = useCallback(async () => {
    await rejectApproval({ status: ApprovalStatusEnum.REJECTED });
    await queryClient.invalidateQueries({
      queryKey: userQueryKeyFactory.counter(),
    });
    toast({
      title: "Sukses Reject Pengajuan",
      description:
        "Mohon menunggu, kamu akan segera dialihkan menuju halaman selanjutnya",
      variant: "destructive",
    });
    navigate({ to: "/notification", search: { activeTab: "Approval" } });
  }, [navigate, queryClient, rejectApproval, toast, userQueryKeyFactory]);

  if (search.success)
    return (
      <SuccessInfo
        title="Sukses Approve Pengajuan"
        description="Mohon menunggu, kamu akan segera dialihkan menuju halaman selanjutnya"
        onRedirect={() =>
          navigate({ to: "/notification", search: { activeTab: "Approval" } })
        }
      />
    );

  return (
    <div className="flex flex-col h-screen">
      <BaseNavHeader title="Approval" />

      <div className="flex-1">
        <StatusWrapper.Container
          className="h-20 flex flex-row justify-between p-4 pt-3 border-b"
          isLoading={isLoading}
        >
          <DurationOverview
            origin={flightDetails?.[0].Origin as string}
            destination={flightDetails?.[0].Destination as string}
            departTime={flightDetails?.[0].DepartTime as string}
            arriveTime={flightDetails?.[0].ArriveTime as string}
            duration={formatDuration(flightDetails?.[0].Duration)}
          />
          <DateOverview date={flightDetails?.[0].DepartDate as string} />
        </StatusWrapper.Container>

        <StatusWrapper.Container
          isLoading={isLoading}
          className={cn("px-4 pt-2.5 pb-3 border-b", isLoading && "h-20")}
        >
          <AirlinesOverview
            className="p-0 border-b-0"
            airlineLogo={
              <img className="w-6" src={flightDetails?.[0].AirlineImageUrl} />
            }
            airlineName={`${segments?.[0].AirlineName} - ${flightDetails?.[0].Category}`}
          />
        </StatusWrapper.Container>

        <StatusWrapper.Container
          isLoading={isLoading}
          className={cn(isLoading && "px-4 pt-2.5 pb-3 h-16 border-b")}
        >
          <ApprovalPassengerList passengers={passengers} />
        </StatusWrapper.Container>
      </div>

      <BaseFooter className="h-[8rem]">
        <div className="w-full flex flex-row gap-3">
          <Button
            isLoading={isRejecting}
            disabled={status !== BookingStatusEnum.RESERVED}
            variant="default"
            className="flex-1 text-lg h-12 bg-[#E1944D] hover:bg-[#E1944D]/90 text-white font-bold"
            onClick={onReject}
          >
            Reject
          </Button>
          <Button
            isLoading={isAccepting}
            disabled={status !== BookingStatusEnum.RESERVED}
            variant="default"
            className="flex-1 text-lg h-12 text-white font-bold"
            onClick={onAccept}
          >
            Approve
          </Button>
        </div>
        <Button
          variant="secondary"
          className="w-full text-lg h-12 text-white font-bold"
          onClick={() => history.back()}
        >
          Kembali
        </Button>
      </BaseFooter>
    </div>
  );
}
