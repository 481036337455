import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute } from "@tanstack/react-router";
import debounce from "debounce";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { ClockIcon } from "@/components/icons/clock";
import { ExportBoldIcon } from "@/components/icons/export-bold";
import { MingcuteFilterFillIcon } from "@/components/icons/mingcute-filter-fill";
import { SearchOutlineIcon } from "@/components/icons/search-outline";
import { LoadingComponent } from "@/components/shared/loading/loading-component";
import { BaseTable } from "@/components/shared/table/base-table";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { backOfficePayer } from "@/features/backoffice-invoice/constants";
import { columns } from "@/features/backoffice-invoice/constants/table-column";
import {
  BackOfficeInvoiceQuery,
  backOfficeInvoiceQuerySchema,
} from "@/features/backoffice-invoice/validators";
import { BackOfficeInvoiceQueryFilters } from "@/features/backoffice-invoice/validators/query-filters";
import { useSearchQuery } from "@/hooks/use-search-query";
import { useFindBackOfficeCompany } from "@/services/backoffice-company/hooks/use-find-backoffice-company";
import { useExportBackOfficeInvoice } from "@/services/backoffice-invoice/hooks/use-export-backoffice-invoice";
import { useExportBackOfficeInvoiceSPD } from "@/services/backoffice-invoice/hooks/use-export-backoffice-invoice-spd";
import { useFindBackOfficeInvoice } from "@/services/backoffice-invoice/hooks/use-find-backoffice-invoice";
import { encodeToBinary } from "@/utils/function";

const validateSearch = z.object({
  company: z.string().optional(),
  payer: z.string().optional(),
  search: z.string().optional(),
  from: z.string().optional(),
  to: z.string().optional(),
});

export const Route = createFileRoute(
  "/_backoffice-layout/backoffice/invoices/",
)({
  component: Index,
  validateSearch,
});

function Index() {
  const {
    searchData,
    setSearch,
    isLoading: isSearchLoading,
  } = useSearchQuery<BackOfficeInvoiceQueryFilters>({
    from: "/_backoffice-layout/backoffice/invoices/",
  });

  const { data: dataInvoices } = useFindBackOfficeInvoice({
    from: searchData.from,
    to: searchData.to,
    search: searchData.search,
    company: searchData.company,
    payer: searchData.payer,
  });

  const { data: dataCompanies } = useFindBackOfficeCompany();

  const { mutateAsync: mutateAsyncExportInvoice } =
    useExportBackOfficeInvoice();

  const { mutateAsync: mutateAsyncExportSPD } = useExportBackOfficeInvoiceSPD();

  const onExportInvoice = useCallback(async () => {
    const data = await mutateAsyncExportInvoice({
      from: searchData.from,
      to: searchData.to,
      company: searchData.company,
    });

    const invoiceId = encodeToBinary(data.data.no);

    window.open(`/backoffice/invoices/${invoiceId}`, "_blank");
  }, [
    mutateAsyncExportInvoice,
    searchData.company,
    searchData.from,
    searchData.to,
  ]);

  const onExportSPD = useCallback(
    async () =>
      mutateAsyncExportSPD({
        from: searchData.from,
        to: searchData.to,
        company: searchData.company,
      }),
    [mutateAsyncExportSPD, searchData.company, searchData.from, searchData.to],
  );

  const defaultValues = useMemo(() => {
    return {
      search: searchData.search,
      company: searchData.company,
      payer: searchData.payer,
      dateRange: {
        from: searchData.from ? new Date(searchData.from) : undefined,
        to: searchData.to ? new Date(searchData.to) : undefined,
      },
    };
  }, [
    searchData.from,
    searchData.to,
    searchData.search,
    searchData.company,
    searchData.payer,
  ]);

  const methods = useForm<BackOfficeInvoiceQuery>({
    mode: "onBlur",
    resolver: zodResolver(backOfficeInvoiceQuerySchema),
    defaultValues,
  });

  const { handleSubmit, watch, getValues, reset } = methods;

  const dateRange = watch("dateRange");

  const onFilterSubmit = useCallback(() => {
    const { dateRange } = getValues();
    if (!dateRange?.from || !dateRange?.to)
      return setSearch({ ...getValues(), from: undefined, to: undefined });

    const from = new Date(dateRange.from);
    const to = new Date(dateRange.to);

    setSearch({ ...getValues(), from, to });
  }, [getValues, setSearch]);

  const selectedDateRange = useMemo(() => {
    if (!dateRange?.from && !dateRange?.to) return "Rentang Waktu";
    if (!dateRange.to) return dateRange?.from?.toLocaleDateString();

    return `${dateRange?.from?.toLocaleDateString()} - ${dateRange?.to?.toLocaleDateString()}`;
  }, [dateRange]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const isExportDisabled =
    !searchData["company"] || !dataInvoices?.data?.length;

  const isDataCompaniesAvailable =
    dataCompanies?.data && dataCompanies.data.length > 0;

  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-between items-center">
        <p className="text-[28px] font-bold">Invoice</p>
        <div className="space-x-2">
          <Button
            disabled={isExportDisabled}
            onClick={onExportSPD}
            className="gap-2.5 h-11 px-[15px] py-2.5 text-base"
          >
            <ExportBoldIcon className="fill-white" />
            Export SPD
          </Button>
          <Button
            disabled={isExportDisabled}
            onClick={onExportInvoice}
            className="gap-2.5 h-11 px-[15px] py-2.5 text-base"
          >
            <ExportBoldIcon className="fill-white" />
            Export Invoice
          </Button>
        </div>
      </div>

      <Form {...methods}>
        <form
          className="flex w-full space-x-2"
          onSubmit={handleSubmit(onFilterSubmit)}
          onBlur={handleSubmit(onFilterSubmit)}
          onChange={debounce(handleSubmit(onFilterSubmit), 500)}
        >
          <FormField
            control={methods.control}
            name="dateRange"
            render={({ field }) => {
              const from = field?.value?.from
                ? new Date(field?.value?.from)
                : undefined;

              const to = field?.value?.to
                ? new Date(field?.value?.to)
                : undefined;

              const selected = { from, to };

              return (
                <FormItem className="flex-1">
                  <FormControl>
                    <LoadingComponent isLoading={isSearchLoading}>
                      <Select>
                        <SelectTrigger>
                          <ClockIcon className="fill-[#939393]" />
                          <SelectValue placeholder={selectedDateRange} />
                        </SelectTrigger>
                        <SelectContent>
                          <Calendar
                            {...field}
                            initialFocus
                            mode="range"
                            defaultMonth={from}
                            selected={selected}
                            onSelect={field.onChange}
                            numberOfMonths={1}
                          />
                        </SelectContent>
                      </Select>
                    </LoadingComponent>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={methods.control}
            name="company"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormControl>
                  <LoadingComponent isLoading={!isDataCompaniesAvailable}>
                    <Select
                      {...field}
                      value={field.value}
                      onValueChange={(value) => {
                        if (value === "ALL") return field.onChange("");
                        field.onChange(value);
                      }}
                    >
                      <SelectTrigger>
                        <MingcuteFilterFillIcon className="fill-[#939393]" />
                        <SelectValue placeholder="Pilih Perusahaan" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value={"ALL"}>ALL</SelectItem>
                        {dataCompanies?.data.map((company) => (
                          <SelectItem key={company.name} value={company.name}>
                            {company.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </LoadingComponent>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={methods.control}
            name="payer"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormControl>
                  <Select
                    {...field}
                    value={field.value}
                    onValueChange={(value) => {
                      if (value === "ALL") return field.onChange("");
                      field.onChange(value);
                    }}
                  >
                    <SelectTrigger>
                      <MingcuteFilterFillIcon className="fill-[#939393]" />
                      <SelectValue placeholder="Jenis Pemesanan" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value={"ALL"}>ALL</SelectItem>
                      {backOfficePayer.map((payer) => (
                        <SelectItem key={payer} value={payer}>
                          {payer}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={methods.control}
            name="search"
            render={({ field }) => (
              <FormItem className="flex-[4]">
                <FormControl>
                  <div className="relative flex items-center">
                    <div>
                      <SearchOutlineIcon className="absolute left-3.5 top-1/2 transform -translate-y-1/2 fill-[#939393]" />
                    </div>
                    <Input
                      {...field}
                      value={field.value}
                      placeholder="Cari"
                      className="px-12"
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>

      <BaseTable data={dataInvoices?.data ?? []} columns={columns} />
    </div>
  );
}
