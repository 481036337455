import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute } from "@tanstack/react-router";
import debounce from "debounce";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { ClockIcon } from "@/components/icons/clock";
import { ExportBoldIcon } from "@/components/icons/export-bold";
import { MingcuteFilterFillIcon } from "@/components/icons/mingcute-filter-fill";
import { SearchOutlineIcon } from "@/components/icons/search-outline";
import { BaseTable } from "@/components/shared/table/base-table";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { backOfficeOrderStatus } from "@/features/backoffice-order/constants";
import { columns } from "@/features/backoffice-order/constants/table-column";
import {
  BackOfficeOrderQuery,
  backOfficeOrderQuerySchema,
} from "@/features/backoffice-order/validators";
import { BackOfficeOrderQueryFilters } from "@/features/backoffice-order/validators/query-filters";
import { useSearchQuery } from "@/hooks/use-search-query";
import { useExportBackOfficeOrder } from "@/services/backoffice-order/hooks/use-export-backoffice-order";
import { useFindBackOfficeOrder } from "@/services/backoffice-order/hooks/use-find-backoffice-order";

const validateSearch = z.object({
  status: z.string().optional(),
  search: z.string().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
});

export const Route = createFileRoute("/_backoffice-layout/backoffice/orders/")({
  component: Index,
  validateSearch,
});

function Index() {
  const { searchData, setSearch } = useSearchQuery<BackOfficeOrderQueryFilters>(
    {
      from: "/_backoffice-layout/backoffice/orders/",
    },
  );

  const { data } = useFindBackOfficeOrder({
    from: searchData?.from,
    to: searchData?.to,
    search: searchData.search,
    status: searchData.status,
  });

  const { mutateAsync: mutateAsyncExportOrder } = useExportBackOfficeOrder();

  async function onExport(filters?: BackOfficeOrderQuery) {
    await mutateAsyncExportOrder({
      from: filters?.dateRange?.from,
      to: filters?.dateRange?.to,
      status: filters?.status,
    });
  }

  const defaultValues = useMemo(() => {
    return {
      search: searchData.search,
      status: searchData.status,
      dateRange: {
        from: searchData.from ? new Date(searchData.from) : undefined,
        to: searchData.to ? new Date(searchData.to) : undefined,
      },
    };
  }, [searchData?.from, searchData?.to, searchData.search, searchData.status]);

  const methods = useForm<BackOfficeOrderQuery>({
    mode: "onBlur",
    resolver: zodResolver(backOfficeOrderQuerySchema),
    defaultValues,
  });

  const { handleSubmit, watch, getValues, reset } = methods;

  const dateRange = watch("dateRange");

  const onFilterSubmit = useCallback(() => {
    const { dateRange } = getValues();
    if (!dateRange?.from || !dateRange?.to)
      return setSearch({ ...getValues(), from: undefined, to: undefined });

    const from = new Date(dateRange.from);
    const to = new Date(dateRange.to);

    setSearch({ ...getValues(), from, to });
  }, [getValues, setSearch]);

  const selectedDateRange = useMemo(() => {
    if (!dateRange?.from && !dateRange?.to) return "Rentang Waktu";
    if (!dateRange.to) return dateRange?.from?.toLocaleDateString();

    return `${dateRange?.from?.toLocaleDateString()} - ${dateRange?.to?.toLocaleDateString()}`;
  }, [dateRange]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-between items-center">
        <p className="text-[28px] font-bold">Pemesanan</p>
        <Button
          disabled={!data?.data.length}
          onClick={() => onExport(getValues())}
          className="gap-2.5 h-11 px-[15px] py-2.5 text-base"
        >
          <ExportBoldIcon className="fill-white" />
          Export
        </Button>
      </div>

      <Form {...methods}>
        <form
          className="flex w-full space-x-2"
          onSubmit={handleSubmit(onFilterSubmit)}
          onBlur={handleSubmit(onFilterSubmit)}
          onChange={debounce(handleSubmit(onFilterSubmit), 500)}
        >
          <FormField
            control={methods.control}
            name="dateRange"
            render={({ field }) => {
              const from = field?.value?.from
                ? new Date(field?.value?.from)
                : undefined;

              const to = field?.value?.to
                ? new Date(field?.value?.to)
                : undefined;

              const selected = { from, to };

              return (
                <FormItem className="flex-1">
                  <FormControl>
                    <Select>
                      <SelectTrigger>
                        <ClockIcon className="fill-[#939393]" />
                        <SelectValue placeholder={selectedDateRange} />
                      </SelectTrigger>
                      <SelectContent>
                        <Calendar
                          {...field}
                          initialFocus
                          mode="range"
                          defaultMonth={from}
                          selected={selected}
                          onSelect={field.onChange}
                          numberOfMonths={1}
                        />
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={methods.control}
            name="status"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormControl>
                  <Select
                    {...field}
                    value={field.value}
                    onValueChange={(value) => {
                      if (value === "ALL") return field.onChange("");
                      field.onChange(value);
                    }}
                  >
                    <SelectTrigger>
                      <MingcuteFilterFillIcon className="fill-[#939393]" />
                      <SelectValue placeholder="Pilih Status" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value={"ALL"}>ALL</SelectItem>
                      {backOfficeOrderStatus.map((status) => (
                        <SelectItem key={status} value={status}>
                          {status}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={methods.control}
            name="search"
            render={({ field }) => (
              <FormItem className="flex-[4]">
                <FormControl>
                  <div className="relative flex items-center">
                    <div>
                      <SearchOutlineIcon className="absolute left-3.5 top-1/2 transform -translate-y-1/2 fill-[#939393]" />
                    </div>
                    <Input
                      {...field}
                      value={field.value}
                      placeholder="Cari"
                      className="px-12"
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>

      <BaseTable data={data?.data ?? []} columns={columns} />
    </div>
  );
}
