import { PassengerOverview } from "@/features/flight-ticket/components/overview/passenger-overview";
import { PassengerEntity } from "@/services/flight-passenger/types";

interface MyTicketsPassengers {
  isCorporate: boolean;
  passengers?: PassengerEntity[];
}

export function MyTicketsPassengers({
  isCorporate,
  passengers,
}: MyTicketsPassengers): React.JSX.Element {
  return (
    <div>
      <p className="text-lg px-5 pt-2.5 font-semibold">Penumpang</p>
      {passengers?.map((item) => (
        <PassengerOverview
          isCorporate={isCorporate}
          key={item.IdNumber}
          {...item}
        />
      ))}
    </div>
  );
}
