import { z } from "zod";

import { flightPassengerSchema } from "@/services/flight-passenger/types";

export const flightBookingSchema = z.object({
  Passengers: z.array(flightPassengerSchema),
  approverId: z.string().min(1),
  payer: z.union([z.literal("USER"), z.literal("CORPORATE")]),
});

export type FlightBookingSchema = z.infer<typeof flightBookingSchema>;
