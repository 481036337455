import { z } from "zod";

export const baseUserRoleSchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1).max(50),
  code: z.string().min(1).max(50).optional(),
  isActive: z.boolean().default(false).optional(),
});

export const editUserRoleSchema = baseUserRoleSchema;
export const addUserRoleSchema = editUserRoleSchema.omit({ id: true });

export type EditUserRoleSchema = z.infer<typeof editUserRoleSchema>;
export type AddUserRoleSchema = z.infer<typeof addUserRoleSchema>;
