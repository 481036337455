import { create } from "zustand";

import { FlightPassengerEntity } from "@/services/flight-passenger/types";
import { FlightTicketEntity } from "@/services/flight-ticket/types";

export type FlightState = {
  ticket: FlightTicketEntity | null;
  passengers: FlightPassengerEntity[];
  paymentType: "USER" | "CORPORATE";
  setTicket: (ticket: FlightState["ticket"]) => unknown;
  setPassengers: (passengers: FlightState["passengers"]) => unknown;
  addPassenger: (passenger: FlightPassengerEntity) => unknown;
  deletePassenger: (IdNumber: FlightPassengerEntity["IdNumber"]) => unknown;
  updatePassenger: (
    IdNumber: FlightPassengerEntity["IdNumber"],
    data: Partial<FlightPassengerEntity>,
  ) => unknown;
  setPaymentType: (paymentType: FlightState["paymentType"]) => unknown;
  reset: () => unknown;
};

export const useFlightStore = create<FlightState>((set) => ({
  passengers: [],
  ticket: null,
  paymentType: "USER",
  setTicket(ticket) {
    set({ ticket });
  },
  addPassenger(passenger) {
    set((state) => ({ passengers: [...state.passengers, passenger] }));
  },
  setPassengers(passengers) {
    set({ passengers });
  },
  deletePassenger(IdNumber) {
    set((state) => ({
      passengers: state.passengers.filter(
        (passenger) => passenger.IdNumber === IdNumber,
      ),
    }));
  },
  setPaymentType(paymentType) {
    set({ paymentType });
  },
  updatePassenger(IdNumber, data) {
    set((state) => ({
      passengers: state.passengers.map((passenger) => {
        if (IdNumber !== passenger.IdNumber) return passenger;

        return { ...passenger, ...data };
      }),
    }));
  },
  reset() {
    set({ passengers: [], ticket: null, paymentType: "USER" });
  },
}));
