import { HTMLAttributes } from "react";

import { WhatsappOutline } from "@/components/icons/whatsapp-outline";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import {
  BookingEntity,
  BookingStatusEnum,
} from "@/services/booking/types/entity";

import { getRefundRescheduleWhatsappUrl } from "../utils/function";

interface RefundRescheduleInfoProps extends HTMLAttributes<HTMLDivElement> {
  booking?: BookingEntity;
}

export function RefundRescheduleInfo({
  booking,
  className,
  ...rest
}: RefundRescheduleInfoProps): React.JSX.Element {
  const status = booking?.reservation.Status;
  const isDisabled = status !== BookingStatusEnum.TICKETED;

  return (
    <div className={cn("px-4 py-3", className)} {...rest}>
      <Dialog>
        <DialogTrigger disabled={isDisabled} className="w-full">
          <Button
            disabled={isDisabled}
            variant="outline"
            className="w-full justify-start items-center gap-2.5 text-primary hover:text-primary border-primary rounded-lg"
          >
            <WhatsappOutline className="fill-primary" />
            Informasi Refund/Reschedule
          </Button>
        </DialogTrigger>
        <DialogContent className="max-w-[390px] gap-[15px] p-2.5 pt-[30px] rounded-md">
          <DialogHeader className="space-y-[15px]">
            <DialogTitle>Informasi Refund/Reschedule</DialogTitle>
            <DialogDescription>
              Jika anda memiliki pertanyaan atau memerlukan bantuan seputar
              Informasi Refund atau Reschedule, silahkan menghubungi kami dengan
              klik tombol dibawah ini
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              className="w-full items-center gap-1 text-primary hover:text-primary border-primary rounded-md"
              onClick={() =>
                window.open(getRefundRescheduleWhatsappUrl(booking), "_blank")
              }
            >
              <WhatsappOutline className="fill-primary" />
              082199141452
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
