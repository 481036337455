import qs from "qs";

import { BackOfficeInvoiceDownloadSchema } from "@/features/backoffice-invoice/validators/download-invoice";
import {
  BackOfficeInvoiceExportSchema,
  backOfficeInvoiceExportSchema,
} from "@/features/backoffice-invoice/validators/export-invoice";
import { backOfficeInvoiceExportPreviewSchema } from "@/features/backoffice-invoice/validators/export-invoice-preview";
import {
  BackOfficeInvoiceExportSPDSchema,
  backOfficeInvoiceExportSPDSchema,
} from "@/features/backoffice-invoice/validators/export-spd";
import { api } from "@/lib/api";
import { downloadFile, filterNonNull } from "@/utils/function";

import { BaseApiResponse } from "../shared/types";
import {
  BackOfficeInvoiceDetail,
  BackOfficeInvoiceExportPreview,
} from "./types";
import { GetBackOfficeInvoiceListDto } from "./types/dto";

export class BackOfficeInvoiceApi {
  async list(
    dto?: GetBackOfficeInvoiceListDto,
  ): Promise<BaseApiResponse<BackOfficeInvoiceDetail[]>> {
    const strDto = qs.stringify(filterNonNull(dto), {
      encode: false,
    });

    const { data } = await api.get<BaseApiResponse<BackOfficeInvoiceDetail[]>>(
      `/backoffice/invoices?${strDto}`,
    );

    return data;
  }

  exportInvoice = async (
    dto?: BackOfficeInvoiceExportSchema,
  ): Promise<BaseApiResponse<{ no: string }>> => {
    const parsed = backOfficeInvoiceExportSchema.parse(dto);

    const strDto = qs.stringify(filterNonNull(parsed), {
      encode: false,
    });

    const data = await api.get<BaseApiResponse<{ no: string }>>(
      `/backoffice/invoices/generate?${strDto}`,
    );

    return data.data;
  };

  exportSPD = async (
    dto?: BackOfficeInvoiceExportSPDSchema,
  ): Promise<BaseApiResponse<{ no: string }>> => {
    const parsed = backOfficeInvoiceExportSPDSchema.parse(dto);

    const strDto = qs.stringify(filterNonNull(parsed), {
      encode: false,
    });

    const data = await api.get(`/backoffice/invoices/export/spd?${strDto}`, {
      responseType: "blob",
    });

    downloadFile(data.data, `invoice-spd-${new Date().toISOString()}.zip`);

    return data.data;
  };

  async preview(
    no: string[],
  ): Promise<BaseApiResponse<BackOfficeInvoiceExportPreview>> {
    const parsed = backOfficeInvoiceExportPreviewSchema.parse({ no });

    const { data } = await api.post<
      BaseApiResponse<BackOfficeInvoiceExportPreview>
    >(`/backoffice/invoices/preview`, parsed);

    return data;
  }

  async downloadInvoice(
    dto: BackOfficeInvoiceDownloadSchema,
  ): Promise<BaseApiResponse<boolean>> {
    const strDto = qs.stringify(filterNonNull(dto.filters), {
      encode: false,
    });

    const data = await api.post(
      `/backoffice/invoices/download?${strDto}`,
      {
        no: dto.no,
      },
      {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    const fileName = `invoice-${new Date().toISOString()}.pdf`;
    downloadFile(data.data, fileName);

    return data.data;
  }
}
