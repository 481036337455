import { useMutation, UseMutationOptions } from "@lezzcode/query";

import { BookingServiceApi } from "../api";

export const useDownloadBookingTicket = (
  id: string,
  options?: UseMutationOptions<void, Error>,
) => {
  const api = new BookingServiceApi();

  return useMutation<void, Error>({
    mutationFn: () => api.downloadBookingTicket(id),
    ...options,
  });
};
